import { compact } from 'lodash'

import documentsFetcher from './documents'

export default {
	async getLocal(documentsType) {
		if (documentsType) {
			return await documentsFetcher.getLocal(documentsType)
		} else {
			const { statuses, orders, manifests } = await documentsFetcher.getLocal()
			return this.getDocs(statuses, orders, manifests)
		}
	},

	getDocs(statuses, orders, manifests) {
		const docs = {
			orders: statuses
				.filter(
					(docStatus) =>
						docStatus.type === 'order' && docStatus.status === 'Завершен'
				)
				.map((docStatus) =>
					orders.find(
						(order) =>
							(order._id &&
								docStatus.documentId &&
								order._id === docStatus.documentId) ||
							(order.uid &&
								docStatus.documentUid &&
								order.uid === docStatus.documentUid)
					)
				),
			manifests: statuses
				.filter(
					(docStatus) =>
						docStatus.type === 'manifest' && docStatus.status === 'Завершен'
				)
				.map((docStatus) =>
					manifests.find(
						(manifest) =>
							(manifest._id &&
								docStatus.documentId &&
								manifest._id === docStatus.documentId) ||
							(manifest.uid &&
								docStatus.documentUid &&
								manifest.uid === docStatus.documentUid)

						// &&
						// (docStatus.status === 'Завершен' &&
						// 	documentsFetcher.hasTransportDocs(manifest))
					)
				),
		}

		docs.orders = compact(docs.orders)
		docs.manifests = compact(docs.manifests)

		return docs
	},

	async getFromServer() {
		const dataFromServer = await documentsFetcher.getFromServer(
			'/api/docs/completed'
		)
		if (!dataFromServer) return
		if (dataFromServer && dataFromServer.redirectTo) return dataFromServer

		const { statuses, orders, manifests } = dataFromServer
		return this.getDocs(statuses, orders, manifests)
	},

	async getCompletedOrderFromServer(id) {
		const dataFromServer = await documentsFetcher.getFromServer(
			`/api/docs/completed/order?id=${id}`
		)
		if (!dataFromServer) return
		if (dataFromServer && dataFromServer.redirectTo) return dataFromServer

		return dataFromServer
	},

	async getCompletedManifestFromServer(id) {
		const dataFromServer = await documentsFetcher.getFromServer(
			`/api/docs/completed/manifest?id=${id}`
		)
		if (!dataFromServer) return
		if (dataFromServer && dataFromServer.redirectTo) return dataFromServer

		return dataFromServer
	},
	// async getCompletedFromServer() {
	// 	const dataFromServer = await documentsFetcher.getFromServer(
	// 		'/api/docs/completed'
	// 	)
	// 	if (!dataFromServer) return
	// 	if (dataFromServer && dataFromServer.redirectTo) return dataFromServer

	// 	const { statuses, orders, manifests } = dataFromServer
	// 	return this.getDocs(statuses, orders, manifests)
	// },
}
