import uuidv1 from 'uuid/v1'
import { SET_ALERT, REMOVE_ALERT } from '../actions/types'

const initialState = { messages: [] }

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_ALERT:
			const alert = { ...action.payload, open: true, id: uuidv1() }

			return { ...state, messages: [...state.messages, alert] }

		case REMOVE_ALERT:
			return {
				...state,
				messages: state.messages.filter(alert => alert.id !== action.payload),
			}

		default:
			return state
	}
}
