import React from "react"
import compose from 'recompose/compose'

import { Route, Link, NavLink, Switch, withRouter } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'

import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
// import DashboardIcon from "@material-ui/icons/Dashboard"
import CompletedIcon from "@material-ui/icons/Check"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import PeopleIcon from "@material-ui/icons/People"
import BillingIcon from "@material-ui/icons/AttachMoney"

const styles = theme => ({

  
})

const MainListItems = ({ location, classes }) => {
  return (
    <div>
      <NavLink to='/profile' style={{ textDecoration: "none" }}>
        <ListItem button selected={location.pathname === "/profile"}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary='Профиль' />
        </ListItem>
      </NavLink>

      <NavLink to='/pickups' style={{ textDecoration: "none" }}>
        <ListItem button selected={/\/pickups/.test(location.pathname)}>
          <ListItemIcon >
            <>
            <LocalShippingIcon />
            <ArrowBackIcon />
            </>
          </ListItemIcon>
          <ListItemText primary='Заборы' />
        </ListItem>
      </NavLink>

      <NavLink to='/deliveries' style={{ textDecoration: "none" }}>
        <ListItem button selected={/\/deliveries/.test(location.pathname)}>
          <ListItemIcon >
            <>
            <LocalShippingIcon />
            <ArrowForwardIcon />
            </>
          </ListItemIcon>
          <ListItemText primary='Доставки' />
        </ListItem>
      </NavLink>

      <NavLink to='/completed' style={{ textDecoration: "none" }}>
        <ListItem button selected={/\/completed/.test(location.pathname)}>
          <ListItemIcon>
            <CompletedIcon />
          </ListItemIcon>
          <ListItemText primary='История' />
        </ListItem>
      </NavLink>
      
      <NavLink to='/billing' style={{ textDecoration: "none" }}>
        <ListItem button selected={/\/billing/.test(location.pathname)}>
          <ListItemIcon>
            <BillingIcon />
          </ListItemIcon>
          <ListItemText primary='Биллинг' />
        </ListItem>
      </NavLink>

      {/* <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Integrations" />
      </ListItem> */}
    </div>
  )
}

export default compose(
  withStyles(styles),
  withRouter
)(MainListItems)
