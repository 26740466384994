import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import format from 'date-fns/format'
import { orderBy as _orderBy, pick as _pick } from 'lodash'

import deliveries from '../fetchers/deliveries'
import SortableCardList from '../components/SortableCardList virt'
import ListHeader from '../components/ListHeader'
import {
	filterDocs,
	getDeliveryOrderRepresentation,
	getDeliveryManifestsRepresentation,
	sortDocs,
} from '../components/utils/transform'
import DeliveryOrderCard from '../components/DeliveryOrderCard'
import DeliveryManifestCard from '../components/DeliveryManifestCard'
import { Fab } from '@material-ui/core'
import CreateManifestDialog from './CreateManifestDialog'
import AddIcon from '@material-ui/icons/Add'
import { isDocMatch } from '../components/utils/docSearch'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		overflowX: 'auto',
		marginTop: 20,
	},
	// table: {
	// 	minWidth: 700,
	// },
	pointerRow: {
		cursor: 'pointer',
	},
	tableTitle: {
		paddingLeft: 20,
		paddingTop: 20,
	},
	muted: {
		color: 'rgba(0, 0, 0, 0.5)',
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	h1: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	hideOnXsDown: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	hideOnSmUp: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
}))

function Deliveries(props) {
	const classes = useStyles()
	let history = useHistory()

	const [arrayForVitrualList, setArrayForVitrualList] = useState([])

	const [orderBy, setOrderBy] = useState('number')
	const [direction, setDirection] = useState('desc')
	const [ordersFilter, setOrdersFilter] = useState('')
	const [manifestsFilter, setManifestsFilter] = useState('')
	const [docs, setDocs] = useState({})
	const [checkedDocs, setCheckedDocs] = useState([])
	const [manifestWindowIsOpened, setManifestWindowVisibility] = useState(false)
	const [manifest, setManifest] = useState(undefined)
	const [checkedOrders, setCheckedOrders] = useState([])

	const handleOrderClick = (event, orderRepresentation) => {
		history.push(`/deliveries/order/${orderRepresentation._id}`)
	}
	const handleManifestClick = (event, manifestRepresentation) => {
		history.push(`/deliveries/manifest/${manifestRepresentation._id}`)
	}

	const handleFilterChange = (event, type) => {
		console.log(type, event.target.value)
		if (type === 'order') setOrdersFilter(event.target.value)
		if (type === 'manifest') setManifestsFilter(event.target.value)
		// event.target.value
	}

	const isCheckboxVisible = (element) => {
		return (
			(element.checkpointCurrent === 'ГС' ||
				element.checkpointCurrent === 'ЧС') &&
			element.nextIsTerminal
		)
	}

	const ordersHeaderRows = [
		[
			{
				label: '',
				padding: 'checkbox',
				isCheckbox: true,
				isCheckboxVisible,
			},
			{
				id: 'deliveryDate',
				label: 'Доставка',
			},
			{
				id: 'number',
				label: 'Номер',
			},
			{
				id: 'invoiceNumber',
				label: 'Накладная',
			},
			{
				id: 'totalPlaces',
				label: 'мест',
			},
			{
				id: 'totalWeight',
				label: 'вес',
			},
			{
				id: 'totalVolume',
				label: 'объём',
			},
			{
				id: 'specialConditions',
				label: 'Спецусловия',
			},
			{
				id: 'checkpointCurrent',
				label: 'Статус',
			},
		],
		[
			{
				id: 'consigneeName',
				label: 'Получатель',
				colSpan: '3',
			},
			{
				id: 'consigneeAddress',
				label: 'Адрес',
				colSpan: '6',
			},
		],
	]

	const manifestsHeaderRows = [
		[
			{
				id: 'arrivalToCarrierDate',
				label: 'Прибытие',
			},
			{
				id: 'number',
				label: 'Номер',
			},
			{
				id: 'invoiceNumber',
				label: 'MAWB',
			},
			{
				id: 'totalPlaces',
				label: 'мест',
			},
			{
				id: 'totalWeight',
				label: 'вес',
			},
			{
				id: 'totalVolume',
				label: 'объём',
			},
			{
				id: 'consignorTerminalCode',
				label: 'Откуда',
			},
			{
				id: 'consigneeTerminalCode',
				label: 'Куда',
			},
			{
				id: 'specialConditions',
				label: 'Спецусловия',
			},
			{
				id: 'checkpointCurrent',
				label: 'Статус',
			},
		],
	]

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		let docs = await deliveries.getLocal()
		setDocs(docs)

		docs = await deliveries.getFromServer()
		if (docs.redirectTo) return history.push(docs.redirectTo)
		setDocs(docs)
	}

	useEffect(() => {
		const _sortedOrders = docs.orders
			? sortDocs(
					docs.orders
						.map((order) => getDeliveryOrderRepresentation(order, classes))
						.map((order) => {
							order.component = (
								<DeliveryOrderCard
									doc={order}
									handleClick={handleOrderClick}
									classes={classes}
								/>
							)

							return order
						})
						.map(setDocIsChecked)
						.filter((order) => filterDocs(order, ordersFilter)),
					orderBy,
					direction
			  )
			: []

		const _sortedManifests = docs.manifests
			? sortDocs(
					docs.manifests
						.map((manifest) =>
							getDeliveryManifestsRepresentation(manifest, classes)
						)
						.map((manifest) => {
							manifest.component = (
								<DeliveryManifestCard
									doc={manifest}
									handleClick={handleManifestClick}
									classes={classes}
								/>
							)
							return manifest
						})
						.map(setDocIsChecked)

						.filter((manifest) => filterDocs(manifest, manifestsFilter)),
					orderBy,
					direction
			  )
			: []

		let _arrayForVitrualList = getArrayForVirtualList(
			ordersHeaderRows,
			_sortedOrders,
			manifestsHeaderRows,
			_sortedManifests
		)
		setArrayForVitrualList(_arrayForVitrualList)
	}, [docs, ordersFilter, manifestsFilter, checkedDocs])

	function getArrayForVirtualList(
		orderHeaderRows,
		ordersArrayRepresentation,
		manifestsHeaderRows,
		manifestsArrayRepresentation
	) {
		return [
			{
				type: 'orderListHeader',
				caption: 'Заказы на доставку',
				orderField: 0,
				headerRows: orderHeaderRows,
				component: (
					<ListHeader
						caption={'Заказы на доставку'}
						headerRows={orderHeaderRows}
						filter={ordersFilter}
						handleFilterChange={handleFilterChange}
						type={'order'}
					/>
				),
			},
			...ordersArrayRepresentation,
			{
				type: 'manifestListHeader',
				caption: 'манифесты исходящие',
				orderField: 2,
				headerRows: manifestsHeaderRows,
				component: (
					<ListHeader
						caption={'манифесты исходящие'}
						headerRows={manifestsHeaderRows}
						filter={manifestsFilter}
						handleFilterChange={handleFilterChange}
						type={'manifest'}
					/>
				),
			},
			...manifestsArrayRepresentation,
		]
	}

	const setDocIsChecked = (doc) => {
		const { type, uid, tempUid, _id } = doc
		const matchObject = { type, uid, tempUid, _id }
		doc.isChecked = !!checkedDocs.find((checkedDoc) =>
			isDocMatch(checkedDoc, matchObject)
		)

		return doc
	}

	const handleCheckboxClick = (event, doc) => {
		// if (order.checkpointCurrent !== 'ГС') return
		const { type, uid, tempUid, _id } = doc
		const matchObject = { type, uid, tempUid, _id }

		if (event.target.checked) {
			setCheckedDocs([...checkedDocs, { type, uid, tempUid, _id }])
		} else {
			// удаляем из массива документ, если с него сняли пометку
			const _checkedDocs = checkedDocs.filter(
				(checkedDoc) => !isDocMatch(checkedDoc, matchObject)
			)
			setCheckedDocs(_checkedDocs)
		}
	}

	const handleClickOpenManifestWindow = () => {
		// const selectedOrders = this.state.orders.filter(order => order.isChecked)
		// const selectedOrders = this.state.selected.map(number =>
		// 	this.state.orders.find(order => order.number === number)
		// )
		// this.setState({ manifestWindowIsOpened: true, selectedOrders })
		const _checkedOrders = checkedDocs.map((checkedDoc) => {
			const { type, uid, tempUid, _id } = checkedDoc
			const matchObject = { type, uid, tempUid, _id }

			const allDocs = [...docs.orders, ...docs.manifests]

			return allDocs.find((doc) => isDocMatch(doc, matchObject))
		})
		setCheckedOrders(_checkedOrders)
		setManifestWindowVisibility(true)
	}
	const handleCloseManifestWindow = () => {
		// this.setState({
		// 	manifestWindowIsOpened: false,
		// 	manifest: undefined,
		// 	selected: [],
		// })
		setManifestWindowVisibility(false)
		setManifest(undefined)
		// setCheckedDocs([])
		// setOrdersArrayRepresentation(
		// 	ordersArrayRepresentation.map(element => ({
		// 		...element,
		// 		isChecked: false,
		// 	}))
		// )

		fetchData()
	}

	return (
		<>
			{/* <h1 className={classes.h1}>Доставки</h1> */}
			<SortableCardList
				docs={arrayForVitrualList}
				classes={classes}
				isCheckboxVisible={isCheckboxVisible}
				handleCheckboxClick={handleCheckboxClick}
			/>

			{checkedDocs.length > 0 ? (
				<Fab
					variant='extended'
					aria-label='delete'
					color='primary'
					className={classes.fab}
					onClick={handleClickOpenManifestWindow}
				>
					<AddIcon className={classes.extendedIcon} />
					Создать манифест
				</Fab>
			) : null}

			<CreateManifestDialog
				open={manifestWindowIsOpened}
				close={handleCloseManifestWindow}
				orders={checkedOrders}
				openedManifest={manifest}
				classes={classes}
			/>
		</>
	)
}

export default Deliveries
