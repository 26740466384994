import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import format from 'date-fns/format'
import classNames from 'classnames/bind'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
// import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
// import Checkbox from '@material-ui/core/Checkbox'

import { withRouter } from 'react-router-dom'

import BillDialog from './BillDialog'

import billingFetcher from '../fetchers/billing'
import SortableTable from '../components/SortableTable'

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    // minWidth: 700,
  },
  pointerRow: {
    cursor: 'pointer'
  },
  tableTitle: {
    paddingLeft: 20,
    paddingTop: 20
  },
  compactTableCell: {
    padding: '4px 20px 4px 20px'
  },
  h1: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
})

function Billing(props) {
  const { classes, history, onSelectAllClick } = props

  const [bills, setBills] = useState([])
  const [bill, setBill] = useState(undefined)
  const [billWindowIsVisible, setBillWindowVisibility] = useState(false)
  const [billsArrayRepresentation, setBillsArrayRepresentation] = useState([])
  // const [files, setfiles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let billsdData = await billingFetcher.getLocal()
      setBills(billsdData)

      billsdData = await billingFetcher.getFromServer()
      if (billsdData.redirectTo) return history.push(billsdData.redirectTo)
      setBills(billsdData)

      const _billsArrayRepresentation = billsdData.map(bill => ({
        date: format(bill.date, 'dd.MM.yyyy HH:mm'),
        number: bill.number,
        sum: bill.sum,
        _id: bill._id,
        uid: bill.uid
      }))

      setBillsArrayRepresentation(_billsArrayRepresentation)
    }
    fetchData()
  }, [])

  const openBill = bill => {
    setBill(bill)
    setBillWindowVisibility(true)
  }

  const closeBill = () => {
    setBill(undefined)
    setBillWindowVisibility(false)
  }

  const handleBillClick = (event, billRepresentation) => {
    const _bill = bills.find(
      bill =>
        (billRepresentation._id &&
          bill._id &&
          billRepresentation._id === bill._id) ||
        (billRepresentation.uid &&
          bill.uid &&
          billRepresentation.uid === bill.uid)
    )
    openBill(_bill)
  }

  const headerRows = [
    [
      {
        id: 'date',
        label: 'Дата выставления'
      },
      {
        id: 'number',
        label: 'Номер'
      },
      {
        id: 'sum',
        label: 'Сумма'
      }
    ]
  ]

  return (
    <>
      <h1 className={classes.h1}>Биллинг</h1>

      <SortableTable
        caption={'Выставленные счета'}
        headerRows={headerRows}
        array={billsArrayRepresentation}
        // handleCheckboxClick={handleCheckboxClick}
        handleRowClick={handleBillClick}
        classes={classes}
        totalsIds={['sum']}
      />

      {/* <Paper className={classes.root}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell className={classes.compactTableCell}>
								Дата выставления
							</TableCell>
							<TableCell className={classes.compactTableCell}>Номер</TableCell>
							<TableCell className={classes.compactTableCell}>Сумма</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{bills.map((bill, index) => {
							return (
								<TableRow
									hover
									className={classNames(
										classes.pointerRow,
										classes.compactTableCell
									)}
									onClick={() => openBill(bill)}
									key={index}
								>
									<TableCell
										component='th'
										scope='row'
										className={classes.compactTableCell}
									>
										{format(bill.date, 'dd.MM.yyyy HH:mm')}
									</TableCell>
									<TableCell className={classes.compactTableCell}>
										{bill.number}
									</TableCell>
									<TableCell className={classes.compactTableCell}>
										{bill.sum}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Paper> */}

      <BillDialog open={billWindowIsVisible} close={closeBill} bill={bill} />
    </>
  )
}

History.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(withStyles(styles), withRouter)(Billing)
