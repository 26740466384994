import React from 'react'
import {
	Card,
	CardContent,
	CardActionArea,
	Box,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import OrderNumber from './OrderNumber'
import WaitingForNumber from './WaitingForNumber'

const useStyles = makeStyles(theme => ({
	gridFullWidth: {
		width: '100%',
	},
	card: {
		flex: 1,
	},
	hideOnMdDown: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	hideOnLgUp: {
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
}))

export default function PickupOrderCard({
	classes: parentClasses,
	handleClick,
	doc: order,
	style,
}) {
	const classes = { ...parentClasses, ...useStyles() }

	return (
		// <Grid item key={order.uid} style={style} classname={classes.card}>
		<Card className={classes.card}>
			<CardActionArea onClick={event => handleClick(event, order)}>
				<CardContent>
					<div className={classes.hideOnLgUp}>
						<Typography variant='body1'>
							{order.number ? (
								<OrderNumber number={order.number} />
							) : (
								<WaitingForNumber doc={order} />
							)}
							{` (${order.invoiceNumber})`}
							<small> забрать {order.pickupDate}</small>
						</Typography>
						<strong>{`${order.consignor} `}</strong>
						<Typography
							className={classes.title}
							color='textSecondary'
							variant='body2'
							component='span'
							gutterBottom
						>
							{order.consignorAddress}
						</Typography>
						{order.nextTerminalName && (
							<Typography
								variant='body1'
								component='span'
							>{` (получатель ${order.nextTerminalName})`}</Typography>
						)}

						{/* <Typography variant='body2'> */}
						<Box display='flex' justifyContent='space-between'>
							<span>{`мест: ${order.totalPlaces}, вес: ${order.totalWeight}, объём: ${order.totalVolume} `}</span>
							<span>{order.specialConditions}</span>
							<span>{`статус: ${order.checkpointCurrent}`}</span>
						</Box>
					</div>

					<div className={classes.hideOnMdDown}>
						<Box display='flex' justifyContent='space-between'>
							<Typography variant='body1'>
								<OrderNumber number={order.number} />
								{` (${order.invoiceNumber})`}
								<small> забрать {order.pickupDate}</small>
							</Typography>
							<span>{`мест: ${order.totalPlaces}, вес: ${order.totalWeight}, объём: ${order.totalVolume} `}</span>
							<span>{order.specialConditions}</span>
							<span>{`статус: ${order.checkpointCurrent}`}</span>
						</Box>

						<strong>{`${order.consignor} `}</strong>
						<Typography
							className={classes.title}
							color='textSecondary'
							variant='body2'
							component='span'
							gutterBottom
						>
							{order.consignorAddress}
						</Typography>
						{order.nextTerminalName && (
							<Typography
								variant='body1'
								component='span'
							>{` (получатель ${order.nextTerminalName})`}</Typography>
						)}
					</div>
				</CardContent>
			</CardActionArea>
		</Card>
		// </Grid>
	)
}
