import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import deliveries from '../fetchers/deliveries'

function ManifestReceptionDialog({ open, close, classes, manifest }) {
	const [manifestReception, setManifestReception] = useState({
		pickupFromCarrierDateFact: new Date(),
	})

	const [formInPending, setFormInPending] = useState(false)

	useEffect(() => {
		if (!open) return

		setManifestReception({
			pickupFromCarrierDateFact: new Date(),
			recievedBy: '',
			totalPlaces: manifest.totalPlaces,
			damageReport: false,
		})
	}, [open])

	const handleReceivedButtonClick = async () => {
		setFormInPending(true)
		await close(manifestReception)
		setFormInPending(false)
	}

	const onDialogFieldChange = event => {
		const newObj = deliveries.onDialogFieldChange(event, manifestReception)
		setManifestReception({ ...manifestReception, ...newObj })
	}

	const sumbmitIsDisabled = () => {
		return formInPending
	}

	return (
		// <Dialog
		// 	open={open}
		// 	onClose={() => close()}
		// 	aria-labelledby='form-dialog-title'>
		// 	<DialogTitle id='form-dialog-title'>{`Забор заказа ${
		// 		order.number
		// 	}`}</DialogTitle>
		// 	<DialogContent>
		// 		{/* <DialogContentText>
		// 		To subscribe to this website, please enter your email address here. We will send
		// 		updates occasionally.
		// 	</DialogContentText> */}

		// 		<TextField
		// 			autoFocus
		// margin='dense'
		// 			label='Дата'
		// 			type='date'
		// 			name='date'
		// 			value={format(manifestReception.date, 'yyyy-MM-dd')}
		// 			onChange={event => onDialogFieldChange(event)}
		// 		/>

		// 		<TextField
		// 			autoFocus
		// margin='dense'
		// 			label='Время'
		// 			type='time'
		// 			name='date'
		// 			value={format(manifestReception.date, 'HH:mm')}
		// 			onChange={event => onDialogFieldChange(event)}
		// 		/>

		// 		<TextField
		// 			autoFocus
		// margin='dense'
		// 			label='Получил ФИО'
		// 			type='text'
		// 			name='recievedBy'
		// 			fullWidth
		// 			value={manifestReception.recievedBy}
		// 			onChange={event => onDialogFieldChange(event)}
		// 		/>

		// 		<TextField
		// margin='dense'
		// 			label='Количество мест'
		// 			type='number'
		// 			name='places'
		// 			fullWidth
		// 			value={manifestReception.places}
		// 			onChange={event => onDialogFieldChange(event)}
		// 		/>

		// 		<TextField
		// margin='dense'
		// 			label='Ориентировочный вес, кг.'
		// 			type='number'
		// 			name='weight'
		// 			fullWidth
		// 			value={manifestReception.weight}
		// 			onChange={event => onDialogFieldChange(event)}
		// 		/>

		// 		{/* <FormControlLabel
		// 		control={
		// 			<Checkbox
		// 				// checked={this.state.checkedB}
		// 				// onChange={this.handleChange('checkedB')}
		// 				// value="checkedB"
		// 				color="primary"
		// 			/>
		// 		}
		// 		label="Груз получен с актом о повреждении"
		// 	/>						 */}
		// 	</DialogContent>
		// 	<DialogActions>
		// 		<Button onClick={() => close()} color='primary'>
		// 			Отмена
		// 		</Button>
		// 		<Button
		// 			onClick={handleReceivedButtonClick}
		// 			variant='contained'
		// 			color='primary'>
		// 			Получил (ГЗ+ТО+ГС)
		// 		</Button>
		// 	</DialogActions>
		// </Dialog>

		<Dialog
			open={open}
			onClose={() => close()}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title'>{`Получение манифеста ${manifest.number}`}</DialogTitle>
			<DialogContent>
				{/* <DialogContentText>
		To subscribe to this website, please enter your email address here. We will send
		updates occasionally.
	</DialogContentText> */}

				<TextField
					autoFocus
					// margin='dense'
					id='name'
					label='Дата'
					type='date'
					name='pickupFromCarrierDateFact'
					value={format(
						manifestReception.pickupFromCarrierDateFact,
						'yyyy-MM-dd'
					)}
					onChange={event => onDialogFieldChange(event)}
				/>

				<TextField
					autoFocus
					// margin='dense'
					id='name'
					label='Время'
					type='time'
					name='pickupFromCarrierDateFact'
					value={format(manifestReception.pickupFromCarrierDateFact, 'HH:mm')}
					onChange={event => onDialogFieldChange(event)}
				/>

				<TextField
					autoComplete='off'
					autoFocus
					// margin='dense'
					id='name'
					label='Получил ФИО'
					type='text'
					name='recievedBy'
					fullWidth
					value={manifestReception.recievedBy}
					onChange={event => onDialogFieldChange(event)}
				/>

				<TextField
					// margin='dense'
					id='name'
					label='Количество мест'
					type='number'
					name='totalPlaces'
					fullWidth
					value={manifestReception.totalPlaces}
					onChange={event => onDialogFieldChange(event)}
				/>

				<FormControlLabel
					control={
						<Checkbox
							color='primary'
							name='damageReport'
							checked={manifestReception.damageReport}
							onChange={event => onDialogFieldChange(event)}
						/>
					}
					label='Груз получен с актом о повреждении'
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => close()} color='primary'>
					Отмена
				</Button>
				<Button
					onClick={handleReceivedButtonClick}
					variant='contained'
					color='primary'
					disabled={sumbmitIsDisabled()}
				>
					Получил (ПП+ММ+ЗМ+МТ+ОК)
				</Button>
			</DialogActions>
		</Dialog>
	)
}

ManifestReceptionDialog.propTypes = {}

export default ManifestReceptionDialog
