import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames/bind'
import { get } from 'lodash'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
// import Button from '@material-ui/core/Button'

import Fab from '@material-ui/core/Fab'
import CreateIcon from '@material-ui/icons/Create'

import profiles from '../fetchers/profiles'
import DescriptionLine from '../components/DescriptionLine'

import { getProfile } from '../actions/profile'

const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
))

const styles = theme => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  h1: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
})

function Profile({ classes, history, profile: profileData, getProfile }) {
  // const [profileData, setProfileData] = useState({})

  const profileSections = [
    [
      { key: 'name', caption: 'Краткое наименование' },
      { key: 'fullName', caption: 'Полное наименование' },
      { key: 'INN', caption: 'ИНН' },
      { key: 'legalAddress', caption: 'Юридический адрес' },
      { key: 'phoneNumber', caption: 'Телефон' }
    ],
    [
      { key: 'bankAccountNumber', caption: 'Расчетный счет' },
      { key: 'bankName', caption: 'Банк' },
      { key: 'correspondentAccountNumber', caption: 'Корреспондентский счет' },
      { key: 'bankBic', caption: 'БИК' }
    ],
    [
      { key: 'terminal.address', caption: 'Адрес терминала' },
      { key: 'terminal.contact', caption: 'Контакт' },
      { key: 'terminal.email', caption: 'Email' },
      { key: 'terminal.mark', caption: 'Маркировка' }
    ]
  ]

  useEffect(() => {
    // async function fetchData() {
    // 	let profile = await profiles.getLocal()
    // 	setProfileData(profile)
    // 	// debugger
    // 	profile = await profiles.getFromServer()
    // 	if (!profile) return history.push('/login')
    // 	if (profile.redirectTo) return history.push(profile.redirectTo)
    // 	setProfileData(profile)

    // 	profiles.updateLocal(profile)
    // }
    // fetchData()
    getProfile()
  }, [])

  return (
    <>
      <h1 className={classes.h1}>Профиль</h1>

      {profileSections.map((profileSection, sectionIndex) => {
        return (
          <Paper
            style={{ padding: 20, marginTop: 20, mairginBottom: 20 }}
            key={sectionIndex}
          >
            {profileSection.map((profileLine, lineIndex) => {
              return (
                <Grid
                  container
                  spacing={1}
                  key={'' + sectionIndex + '-' + lineIndex}
                >
                  {/* <Grid item xs={2}>
										<strong>{profileLine.caption}</strong>
									</Grid>
									<Grid item xs={10}>
										{profileData && profileData[profileLine.key]}
									</Grid> */}
                  <DescriptionLine
                    caption={profileLine.caption}
                    value={profileData && get(profileData, profileLine.key)}
                  />
                </Grid>
              )
            })}
          </Paper>
        )
      })}

      <Fab
        variant='extended'
        aria-label='delete'
        color='default'
        className={classNames(classes.fab)}
        component={AdapterLink}
        to='/profile/edit'
      >
        <CreateIcon className={classes.extendedIcon} />
        Изменить
      </Fab>
    </>
  )
}

const mapStateToProps = state => ({
  profile: state.profile
})

export default connect(mapStateToProps, { getProfile })(
  compose(withStyles(styles), withRouter)(Profile)
)
