import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const line = ({
  caption,
  value,
  screenIsExtraSmall,
  screenIsSmall,
  screenIsMedium
}) => {
  // if (screenIsExtraSmall) {
  // 	return (
  // 		<>
  // 			<Grid item xs={12}>
  // 				<div>
  // 					<strong>{caption}</strong>
  // 				</div>
  // 				<div>{value}</div>
  // 			</Grid>
  // 		</>
  // 	)
  // } else
  if (screenIsSmall) {
    return (
      <Grid item xs={12}>
        <strong>{caption}</strong> {value}
      </Grid>
    )
  } else {
    return (
      <>
        <Grid item xs={3}>
          <strong>{caption}</strong>
        </Grid>
        <Grid item xs={9}>
          {value}
        </Grid>{' '}
      </>
    )
  }
}

function DescriptionLine({ caption, value }) {
  const theme = useTheme()
  const screenIsExtraSmall = useMediaQuery(theme.breakpoints.down('xs'))
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const screenIsMedium = useMediaQuery(theme.breakpoints.down('md'))

  return line({
    caption,
    value,
    screenIsExtraSmall,
    screenIsSmall,
    screenIsMedium
  })
}

export default DescriptionLine
