import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import { Link as RouterLink, withRouter } from 'react-router-dom'
import Link from '@material-ui/core/Link'

// Import React FilePond
import { FilePond } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Checkbox, Box } from '@material-ui/core'
// import ToggleButton from '@material-ui/lab/ToggleButton'
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

// const LinkRouter = props => <Link {...props} component={RouterLink} />

function FilesManipulation({
  doc,
  classes,
  handleProcessFile,
  handleCickCheckboxIsTransportDoc,
  profile
}) {
  const [files, setFiles] = useState([])
  let pondRef = null
  const handleInit = () => {
    console.log('FilePond instance has initialised', pondRef)
  }

  const printForms =
    doc.files &&
    doc.files.filter(
      file => file.groupName && file.groupName === 'Печатные формы'
    )
  const scans =
    doc.files &&
    doc.files.filter(
      file => !file.groupName || file.groupName !== 'Печатные формы'
    )

  const downloadFile = (event, file) => {
    event.preventDefault()

    let query
    if (file._id) {
      query = `?_id=${file._id}`
    } else {
      query = `?uid=${file.uid}`
    }

    fetch(`/api/files${query}`).then(response => {
      if (!response.ok) return
      // const filename = response.headers
      // 	.get('Content-Disposition')
      // 	.split('filename=')[1]
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = file.name
        a.click()
      })
    })
  }

  const fileIsFromOtherTerminal = file => {
    return file.terminalUid !== (profile.terminal && profile.terminal.uid)
  }

  return (
    <>
      <Paper className={classes.root}>
        {printForms && printForms.length > 0 && (
          <>
            <Typography color='inherit' variant='subtitle1'>
              печатные формы
            </Typography>

            <Table className={classes.table} size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Файл</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {printForms.map((file, index) => (
                  <TableRow hover className={classes.pointerRow} key={index}>
                    <TableCell>
                      <Link
                        color='inherit'
                        // target='_blank'
                        // href={`/api/files/${doc.type}/${doc.number}/${file.name}`}
                        href='#'
                        onClick={event => downloadFile(event, file)}
                      >
                        {file.name}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        {scans && scans.length > 0 && (
          <>
            <Typography color='inherit' variant='subtitle1'>
              файлы
            </Typography>

            <Table className={classes.table} size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Файл</TableCell>
                  <TableCell>Это транспортный документ</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {scans.map((file, index) => (
                  <TableRow hover className={classes.pointerRow} key={index}>
                    <TableCell>
                      <Link
                        color='inherit'
                        // target='_blank'
                        // href={`/api/files/${doc.type}/${doc.number}/${file.name}`}
                        href='#'
                        onClick={event => downloadFile(event, file)}
                      >
                        {file.name}
                      </Link>
                      <div>
                        <small>
                          <Box color='text.hint'>{file.description}</Box>
                        </small>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={file.isTransportDoc}
                        onChange={event =>
                          handleCickCheckboxIsTransportDoc(
                            file,
                            event.target.checked
                          )
                        }
                        className='checkboxColumn'
                        disabled={fileIsFromOtherTerminal(file)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </Paper>

      <FilePond
        labelIdle='бросайте сюда файлы или нажмите, чтобы загрузить'
        ref={ref => (pondRef = ref)}
        files={files}
        allowMultiple={true}
        maxFiles={3}
        server='/api/upload'
        oninit={() => handleInit()}
        onupdatefiles={fileItems => {
          // Set currently active file objects to this.state
          setFiles(
            fileItems.map(fileItem => {
              fileItem.setMetadata('doc', {
                type: doc.type,
                number: doc.number,
                _id: doc._id
              })
              // return {
              // 	file:
              return fileItem.file
              // }
            })
          )
        }}
        onaddfilestart={file => {
          console.log(file)
        }}
        onprocessfile={handleProcessFile}
      />
    </>
  )
}

FilesManipulation.propTypes = {
  doc: PropTypes.object.isRequired,
  handleProcessFile: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  profile: state.profile
})

export default connect(mapStateToProps)(FilesManipulation)
