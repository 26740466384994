import { SET_PROFILE, CLEAR_PROFILE } from '../actions/types'

const initialState = {}

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_PROFILE:
			return { ...state, ...action.payload }
		case CLEAR_PROFILE:
			return { ...state, ...action.payload }
		default:
			return state
	}
}
