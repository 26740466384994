import axios from 'axios'
import { Storage } from '../services/storage'

export default {
	getLocal() {
		return Storage.getData('bills')
	},

	async getFromServer() {
		try {
			const currentUser = await Storage.getData('currentUser')
			const res = await axios.get('/api/bills', {
				params: { terminalUid: currentUser.terminalUid },
			})
			const serverData = res.data
			await this.updateLocalBills(serverData)
			return serverData
		} catch (error) {
			if (error.response.status === 404) return
			if (error.response.status === 401) return { redirectTo: error.redirectTo }
		}
	},

	updateLocalBills(bills) {
		return Storage.setData({ bills })
	},

	async createBill(bill) {
		const bills = await this.getLocal()
		const currentUser = await Storage.getData('currentUser')

		// let newBill = {
		// 	number: this.getNewBillNumber(),
		// 	date: new Date(),
		// 	docs: this.getDocsForPayment(checkedTasks),
		// 	comment,
		// 	terminalUid: currentUser.terminalUid
		// }

		let newBill = {
			...bill,
			docs: this.getDocsForPayment(bill.docs),
		}

		newBill = await this.updateServerBill(newBill, currentUser)

		if (newBill) {
			bills.push(newBill)
			this.updateLocalBills(bills)
		}
		return newBill
	},

	async getNewBillNumber() {
		const bills = await this.getLocal()
		if (bills.length === 0) return 1

		let maxBillNumber = Math.max(
			...bills.map(bill => {
				const billNumberWithoutLetters = String(bill.number).replace(/\D/g, '')
				if (!billNumberWithoutLetters) return 0
				else return parseInt(billNumberWithoutLetters)
			})
		)
		return maxBillNumber + 1
	},

	getDocsForPayment(docs) {
		return docs.map(doc => {
			return {
				type: doc.type,
				uid: doc.uid,
				number: doc.number,
				date: doc.date,
				_id: doc._id,
				sum: doc.sum,
				invoiceNumber: doc.invoiceNumber,
				consignee: doc.consignee,
			}
		})
	},

	async updateServerBill(bill, currentUser) {
		try {
			const res = await axios.put('/api/docs/bill', { bill })
			return res.data
		} catch (error) {
			if (error.response.status === 404) return
		}
	},
}
