import React from 'react'
import PropTypes from 'prop-types'

import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

function Message({
	open,
	close,
	classes,
	messageText,
	buttonText,
	buttonAction,
}) {
	let buttonWithAction
	if (buttonText && buttonAction) {
		buttonWithAction = (
			<Button key='undo' color='secondary' size='small' onClick={buttonAction}>
				{buttonText}
			</Button>
		)
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={open}
			autoHideDuration={3000}
			// onClose={handleCloseSnackbar}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			message={<span id='message-id'>{messageText}</span>}
			action={[
				buttonWithAction,
				<IconButton
					key='close'
					aria-label='Close'
					color='inherit'
					className={classes.close}
					onClick={close}>
					<CloseIcon />
				</IconButton>,
			]}
		/>
	)
}

Message.propTypes = {}

export default Message
