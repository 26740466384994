import axios from 'axios'
import { Storage } from '../services/storage'
// import { set } from 'lodash'
import { format, isWithinInterval, startOfDay, endOfDay } from 'date-fns'

export default {
	async getFromServer(url = `/api/docs`) {
		try {
			const currentUser = await Storage.getData('currentUser')
			const res = await axios.get(url, {
				params: { terminalUid: currentUser.terminalUid },
			})
			const serverData = res.data
			if (!/\/api\/docs\/completed/.test(url)) {
				await this.updateLocal('statuses', serverData.statuses)
				await this.updateLocal('orders', serverData.orders)
				await this.updateLocal('manifests', serverData.manifests)
			}
			return serverData
		} catch (error) {
			if (error.response.status === 404) return
			if (error.response.status === 401) return { redirectTo: error.redirectTo }
		}
	},

	updateLocal(documentsType, documents) {
		return Storage.setData({
			[documentsType]: documents,
		})
	},

	getLocalStatuses() {
		return Storage.getData(`statuses`)
	},

	async getLocal(documentsType) {
		if (documentsType) {
			return await Storage.getData(documentsType)
		} else {
			const orders = await Storage.getData(`orders`)
			const manifests = await Storage.getData(`manifests`)
			let statuses = await Storage.getData(`statuses`)

			return { statuses, orders, manifests }
		}
	},

	async updateOrderLocal({ order: newOrder, status: newStatus }) {
		let orders = await Storage.getData('orders')
		const foundedOrderIndex = orders.findIndex(
			order => order.uid === newOrder.uid
		)
		if (foundedOrderIndex > -1) {
			// заказ найден среди заказов - обновляем
			orders = orders.map(order =>
				order.uid === newOrder.uid ? newOrder : order
			)
		} else {
			// заказ не найден - добавляем
			orders.push(newOrder)
		}

		await Storage.setData({ orders })

		if (newStatus) {
			// меняем статус

			// 26/10/2021 Вадим
			//    вообще newStatus должен быть статусом документа, а не массивом статусов
			//    по крайней мере. при развале манифеста возвращается один статус манифеста
			//    да и не нормально устанавливать массив статусов для статуса документа (ниже)
			//    statusLine.documentUid === newOrder.uid ? newStatus (<= массив) : statusLine (<= не массив)
			if (Array.isArray(newStatus)) newStatus = newStatus[0]

			let statuses = await Storage.getData('statuses')
			const foundedStatusIndex = statuses.findIndex(
				statusLine => statusLine.documentUid === newOrder.uid
			)
			if (foundedStatusIndex > -1) {
				// статус найден - обновляем
				statuses = statuses.map(statusLine =>
					statusLine.documentUid === newOrder.uid ? newStatus : statusLine
				)
			} else {
				// заказ не найден - добавляем
				statuses.push(newStatus)
			}

			await Storage.setData({ statuses })
		}
	},

	async updateManifestLocal({
		manifest: newManifest,
		status: newStatus,
		ordersAndStatuses,
	}) {
		let manifests = await Storage.getData('manifests')
		const foundedManifestIndex = manifests.findIndex(
			manifest =>
				(newManifest.tempUid && manifest.tempUid === newManifest.tempUid) ||
				(newManifest.uid && manifest.uid === newManifest.uid) ||
				(newManifest._id && manifest._id === newManifest._id) ||
				(newManifest.number && manifest.number === newManifest.number)
		)
		if (foundedManifestIndex > -1) {
			// манифест найден среди манифестов - обновляем
			manifests = manifests.map((manifest, index) =>
				index === foundedManifestIndex ? newManifest : manifest
			)
		} else {
			// манифест не найден - добавляем
			manifests.push(newManifest)
		}

		await Storage.setData({ manifests })

		if (newStatus) {
			// меняем статус

			// 26/10/2021 Вадим
			//    вообще newStatus должен быть статусом документа, а не массивом статусов
			//    по крайней мере. при развале манифеста возвращается один статус манифеста
			//    да и не нормально устанавливать массив статусов для статуса документа (ниже)
			//    statusLine.documentUid === newOrder.uid ? newStatus (<= массив) : statusLine (<= не массив)
			if (Array.isArray(newStatus)) newStatus = newStatus[0]

			let statuses = await Storage.getData('statuses')
			const foundedStatusIndex = statuses.findIndex(
				statusLine => statusLine.documentUid === newManifest.uid
			)
			if (foundedStatusIndex > -1) {
				// статус найден - обновляем
				statuses = statuses.map(statusLine =>
					statusLine.documentUid === newManifest.uid ? newStatus : statusLine
				)
			} else {
				// заказ не найден - добавляем
				statuses.push(newStatus)
			}

			await Storage.setData({ statuses })
		}

		if (ordersAndStatuses) {
			ordersAndStatuses.forEach(async ({ order, status }) => {
				await this.updateOrderLocal({ order, status })
			})
		}
	},

	onDialogFieldChange(event, obj) {
		let newValue
		let targetName = event.target.name
		if (
			event.target.type === 'date' ||
			event.target.type === 'time' ||
			event.target.type === 'datetime-local'
		) {
			const tzo = -new Date().getTimezoneOffset()
			const dif = tzo >= 0 ? '+' : '-'
			const pad = function (num) {
				const norm = Math.abs(Math.floor(num))
				return (norm < 10 ? '0' : '') + norm
			}
			const date = obj[targetName]
			let dateInt
			if (event.target.type === 'time') {
				const value = event.target.value ? event.target.value : '00:00'
				dateInt = Date.parse(
					`${format(date, 'yyyy-MM-dd')}T${value}:00${dif}${pad(
						tzo / 60
					)}:${pad(tzo % 60)}`
				)
			} else {
				const value = event.target.value ? event.target.value : '1970-01-01'
				dateInt = Date.parse(
					`${value}T${format(date, 'HH:mm')}:00${dif}${pad(tzo / 60)}:${pad(
						tzo % 60
					)}`
				)
			}
			newValue = new Date(dateInt)
		} else if (event.target.type === 'number') {
			newValue = parseFloat(event.target.value)
		} else if (event.target.type === 'checkbox') {
			newValue = event.target.checked
		} else {
			newValue = event.target.value
		}

		return {
			[targetName]: newValue,
		}
		// return set({}, targetName, newValue)
	},

	async putOrder({
		order,
		comment,
		checkpoints,
		event,
		trackingNotes,
		trackingDate,
	}) {
		try {
			// const currentUser = await Storage.getData('currentUser')
			const res = await axios.put('/api/docs/order', {
				order,
				comment,
				checkpoints,
				event: event ? event : 'Изменение чекпоинта',
				trackingNotes,
				trackingDate,
			})
			return res.data
		} catch (error) {
			console.error(error)
			return undefined
		}
	},

	async putManifest(
		manifest,
		comment,
		checkpoints,
		event,
		trackingNotes,
		trackingDate
	) {
		try {
			const currentUser = await Storage.getData('currentUser')
			const res = await axios.put('/api/docs/manifest', {
				manifest,
				comment,
				checkpoints,
				event: event ? event : 'Изменение чекпоинта',
				trackingNotes,
				trackingDate,
			})
			return res.data
		} catch (error) {
			if (error.response) {
				if (error.response.data) {
					console.error(error.response.data.message)
					return { error: error.response.data }
				} else console.error(error.response)
			} else console.error(error)
		}
	},

	async patchFile(doc, file) {
		try {
			// const currentUser = await Storage.getData('currentUser')
			const res = await axios.patch(`/api/files`, {
				doc: {
					type: doc.type,
					_id: doc._id,
				},
				file,
			})
			const updatedManifest = res.data
			const manifests = (await this.getLocal('manifests')).map(
				localManifest => {
					if (localManifest._id === updatedManifest._id) return updatedManifest
					else return localManifest
				}
			)
			this.updateLocal('manifests', manifests)
			return true
		} catch (error) {
			if (error.response.status === 404) return
			if (error.response.status === 401) return { redirectTo: error.redirectTo }
			return false
		}
	},

	hasTransportDocs(doc) {
		return doc.files && doc.files.length > 0
		// && manifest.files.find(file => file.isTransportDoc)
	},
}
