import React from 'react'
import compose from 'recompose/compose'
// import { withCookies, Cookies } from 'react-cookie'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import { connect } from 'react-redux'

import {
	Route,
	// Link,
	// NavLink,
	Switch,
	Redirect,
	withRouter,
} from 'react-router-dom'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import NotificationsIcon from '@material-ui/icons/Notifications'
import PersonIcon from '@material-ui/icons/Person'

import MainListItems from './MainListItems'
// import SimpleLineChart from './SimpleLineChart';
// import SimpleTable from './SimpleTable';
import Profile from '../pages/Profile'
import Deliveries from '../pages/Deliveries.new'
import PickupManifets from '../pages/PickupManifets'
import DeliveryOrder from '../pages/DeliveryOrder'
import DeliveryManifest from '../pages/DeliveryManifest'
import Pickups from '../pages/Pickups.new'
import PickupOrder from '../pages/PickupOrder'
import Completed from '../pages/Completed'
import Billing from '../pages/Billing'
import SetUser from '../pages/SetUser'
import Login from '../pages/Login'
import EditProfile from '../pages/EditProfile'

// ICONS
import CompletedIcon from '@material-ui/icons/Check'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import PeopleIcon from '@material-ui/icons/People'
import BillingIcon from '@material-ui/icons/AttachMoney'

import users from '../fetchers/users'
// import profiles from '../fetchers/profiles'
import CompletedOrder from '../pages/CompletedOrder'
import CompletedManifest from '../pages/CompletedManifest'
import Messages from './Messages'

import { getProfile, clearProfile } from '../actions/profile'
import { setCurrentUser, logout } from '../actions/auth'

const drawerWidth = 240

const styles = theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
		height: '100vh',
		overflow: 'auto',
	},
	chartContainer: {
		marginLeft: -22,
	},
	tableContainer: {
		height: 320,
	},
	h5: {
		marginBottom: theme.spacing(1),
	},

	appBarButton: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	drawerHideOnSmallScreen: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
})

class Dashboard extends React.Component {
	state = {
		open: true,
		currentTerminal: {},
	}

	history = this.props.history

	// theme = useTheme()
	// screenIsExtraSmall = useMediaQuery(this.theme.breakpoints.down('xs'))
	// screenIsSmall = useMediaQuery(this.theme.breakpoints.down('sm'))
	// screenIsMedium = useMediaQuery(this.theme.breakpoints.down('md'))
	// screenIsLarge = useMediaQuery(this.theme.breakpoints.down('lg'))
	// screenIsExctraLarge = useMediaQuery(this.theme.breakpoints.down('xl'))

	// getProfile = this.props.getProfile

	async componentDidMount() {
		// await this.updateProfile()
		this.props.getProfile()
		this.props.setCurrentUser()
	}

	// async updateProfile() {
	// 	const currentProfile = await profiles.getLocal()
	// 	this.setState({ currentProfile })
	// }

	handleDrawerOpen = () => {
		this.setState({ open: true })
	}

	handleDrawerClose = () => {
		this.setState({ open: false })
	}

	handleLogoutСlick = async () => {
		if (window.confirm('Точно хотите выйти?')) {
			await users.clearCurrentUser()
			// const { cookies } = this.props
			// cookies.getAll()
			// cookies.remove('session', { httpOnly: true })
			// browser.cookies.remove({ name: 'session' })
			users.logout()
			this.props.logout()
			this.props.clearProfile()
			this.history.push('/login')
		}
	}

	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	if (nextProps.location.pathname === '/profile') {
	// 		const currentProfile = await profiles.getLocal()
	// 	}
	// }

	render() {
		const { classes, auth, profile, messages, location, history } = this.props
		// const { currentProfile } = this.state
		// if (
		// 	!/set-user/.test(location.pathname) &&
		// 	profile.redirectTo &&
		// 	location.pathname !== profile.redirectTo
		// ) {
		// 	history.push(profile.redirectTo)
		// 	return null
		// }

		if (
			!auth.isAuthenticated &&
			profile &&
			profile.redirectTo &&
			location.pathname !== profile.redirectTo
		) {
			history.push(profile.redirectTo)
			return null
		}

		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar
					position='absolute'
					className={classNames(
						classes.appBar,
						this.state.open && classes.appBarShift
					)}
				>
					<Toolbar
						disableGutters={!this.state.open}
						className={classes.toolbar}
					>
						<IconButton
							color='inherit'
							aria-label='Open drawer'
							onClick={this.handleDrawerOpen}
							className={classNames(
								classes.menuButton,
								this.state.open && classes.menuButtonHidden
							)}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component='h1'
							variant='h6'
							color='inherit'
							noWrap
							className={classes.title}
						>
							{profile &&
								profile.terminal &&
								`${profile.terminal.name} (${profile.terminal.code})`}
						</Typography>
						<IconButton
							color='inherit'
							onClick={() => history.push('/profile')}
							disabled={location.pathname === '/profile'}
							className={classes.appBarButton}
						>
							<Badge color='secondary'>
								<PeopleIcon />
							</Badge>
						</IconButton>
						<IconButton
							color='inherit'
							onClick={() => history.push('/pickups')}
							disabled={/\/pickups/.test(location.pathname)}
							className={classes.appBarButton}
						>
							<Badge color='secondary'>
								<LocalShippingIcon />
								<ArrowBackIcon />
							</Badge>
						</IconButton>
						<IconButton
							color='inherit'
							onClick={() => history.push('/deliveries')}
							disabled={/\/deliveries/.test(location.pathname)}
							className={classes.appBarButton}
						>
							<Badge color='secondary'>
								<LocalShippingIcon />
								<ArrowForwardIcon />
							</Badge>
						</IconButton>
						<IconButton
							color='inherit'
							onClick={() => history.push('/completed')}
							disabled={/\/completed/.test(location.pathname)}
							className={classes.appBarButton}
						>
							<Badge color='secondary'>
								<CompletedIcon />
							</Badge>
						</IconButton>
						<IconButton
							color='inherit'
							onClick={() => history.push('/billing')}
							disabled={/\/billing/.test(location.pathname)}
							className={classes.appBarButton}
						>
							<Badge color='secondary'>
								<BillingIcon />
							</Badge>
						</IconButton>
						{/* <IconButton color='inherit'>
              <Badge badgeContent={4} color='secondary'>
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
						<IconButton color='inherit' onClick={this.handleLogoutСlick}>
							<Badge color='secondary'>
								<PersonIcon />
							</Badge>
						</IconButton>{' '}
					</Toolbar>
				</AppBar>
				<Drawer
					variant='permanent'
					className={classes.drawerHideOnSmallScreen}
					classes={{
						paper: classNames(
							classes.drawerPaper,
							!this.state.open && classes.drawerPaperClose
						),
					}}
					open={this.state.open}
				>
					<div className={classes.toolbarIcon}>
						<Typography variant='h6'>АРМ Партнёра</Typography>
						<IconButton onClick={this.handleDrawerClose}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<Divider />
					<List>
						<MainListItems />
					</List>
					{/* <Divider />
          <List>{secondaryListItems}</List> */}
				</Drawer>

				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Switch>
						<Route path='/profile/edit' component={EditProfile} />
						<Route path='/profile' component={Profile} />
						<Route
							path='/pickups/order/:orderId'
							component={() => <PickupOrder />}
						/>
						<Route
							path='/pickups/manifest/:manifestId/:orderId'
							component={() => <PickupOrder />}
						/>
						<Route
							path='/pickups/manifest/:manifestId'
							component={() => <PickupManifets />}
						/>
						<Route path='/pickups' component={() => <Pickups />} />
						<Route
							path='/deliveries/manifest/:manifestId/:orderId'
							component={() => <DeliveryOrder />}
						/>
						<Route
							path='/deliveries/manifest/:manifestId'
							component={() => <DeliveryManifest />}
						/>
						<Route
							path='/deliveries/order/:orderId'
							component={() => <DeliveryOrder />}
						/>
						<Route path='/deliveries' component={() => <Deliveries />} />
						<Route
							path='/completed/manifest/:manifestId'
							component={() => <CompletedManifest />}
						/>{' '}
						<Route
							path='/completed/order/:orderId'
							component={() => <CompletedOrder />}
						/>{' '}
						<Route path='/completed' component={() => <Completed />} />{' '}
						<Route path='/billing' component={() => <Billing />} />{' '}
						<Route path='/set-user/:userId' component={() => <SetUser />} />{' '}
						<Route path='/login' component={() => <Login />} />{' '}
						<Route exact path='/' render={() => <Redirect to='/profile' />} />
					</Switch>

					{/* <div className={classes.appBarSpacer} />
          <Typography variant="h4" gutterBottom component="h2">
            Orders
          </Typography>
          <Typography variant="h4" gutterBottom component="h2">
            Products
          </Typography>
          <div className={classes.tableContainer}>
            <SimpleTable />
          </div> */}
				</main>
				<Messages messages={messages} classes={classes} />
			</div>
		)
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
	profile: state.profile,
	messages: state.messages,
	auth: state.auth,
})

export default connect(mapStateToProps, {
	getProfile,
	clearProfile,
	setCurrentUser,
	logout,
})(
	compose(
		withStyles(styles),
		withRouter
		// withCookies
	)(Dashboard)
)
