import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

// import format from 'date-fns/format'
// import { orderBy as _orderBy, pick as _pick } from 'lodash'

import pickups from '../fetchers/pickups'
// import SpecialConditions from '../components/SpecialConditions'
import SortableCardList from '../components/SortableCardList virt'
import ListHeader from '../components/ListHeader'
import {
	filterDocs,
	getPickupManifestsRepresentation,
	getPickupOrderRepresentation,
	sortDocs,
} from '../components/utils/transform'
import PickupOrderCard from '../components/PickupOrderCard'
import PickupManifestCard from '../components/PickupManifestCard'
import SortableTable from '../components/SortableTable'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		overflowX: 'auto',
	},
	pointerRow: {
		cursor: 'pointer',
	},
	borderBottomNone: {
		borderBottom: 0,
	},
	lowBottomCell: {
		paddingBottom: 0,
	},
	lowTopCell: {
		paddingTop: 0,
	},
	tableTitle: {
		paddingLeft: 20,
		paddingTop: 20,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	stickyHeader: {
		backgroundColor: '#fff',
		position: 'sticky',
		top: '24px',
	},
	stickyHeader_line2: {
		backgroundColor: '#fff',
		position: 'sticky',
		top: '68px',
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	muted: {
		color: 'rgba(0, 0, 0, 0.5)',
	},
	h1: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	hideOnXsDown: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	hideOnSmUp: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
}))

export default function Pickups() {
	const classes = useStyles()
	let history = useHistory()

	// const [orders, setOrders] = useState([])
	// const [sortedOrders, setSortedOrders] = useState([])
	// const [ordersArrayRepresentation, setOrdersArrayRepresentation] = useState([])

	// const [manifests, setManifests] = useState([])
	// const [sortedManifests, setSortedManifests] = useState([])
	// const [
	// 	manifestsArrayRepresentation,
	// 	setManifestsArrayRepresentation,
	// ] = useState([])

	const [arrayForVitrualList, setArrayForVitrualList] = useState([])

	const [orderBy, setOrderBy] = useState('number')
	const [direction, setDirection] = useState('desc')
	const [ordersFilter, setOrdersFilter] = useState('')
	const [manifestsFilter, setManifestsFilter] = useState('')
	const [docs, setDocs] = useState({})

	const headerRows = [
		[
			{
				id: 'pickupDate',
				label: 'Забор',
			},
			{
				id: 'number',
				label: 'Номер',
			},
			{
				id: 'invoiceNumber',
				label: 'ТН',
			},
			{
				id: 'totalPlaces',
				label: 'Мест',
			},
			{
				id: 'totalWeight',
				label: 'Вес, кг',
			},
			{
				id: 'totalVolume',
				label: 'Объём, м3',
			},
			{
				id: 'specialConditions',
				label: 'Спецусловия',
			},
			{
				id: 'checkpointCurrent',
				label: 'Статус',
			},
		],
		[
			{
				id: 'consignor',
				label: 'Отправитель',
				colSpan: '2',
			},
			{
				id: 'consignorAddress',
				label: 'Отправитель',
				colSpan: '5',
			},
			{
				id: 'nextTerminalName',
				label: 'Получатель',
				colSpan: '2',
			},
		],
	]

	const manifestsHeaderRows = [
		[
			{
				id: 'transferToCarrierDate',
				label: 'Дата отправки',
			},
			{
				id: 'arrivalToCarrierDate',
				label: 'Дата прибытия',
			},
			{
				id: 'number',
				label: 'Номер',
			},
			{
				id: 'invoiceNumber',
				label: 'MAWB',
			},
			{
				id: 'totalPlaces',
				label: 'мест',
			},
			{
				id: 'totalWeight',
				label: 'вес',
			},
			{
				id: 'totalVolume',
				label: 'объём',
			},
			{
				id: 'consignorTerminalCode',
				label: 'Откуда',
			},
			{
				id: 'consigneeTerminalCode',
				label: 'Куда',
			},
			{
				id: 'specialConditions',
				label: 'Спецусловия',
			},
			{
				id: 'checkpointCurrent',
				label: 'Статус',
			},
		],
	]

	useEffect(() => {
		fetchData()
	}, [])

	useEffect(() => {
		// let _ordersArrayRepresentation = getOrdersRepresentation(
		// 	docs.orders,
		// 	classes
		// )
		// let _manifestsArrayRepresentation = getManifestsRepresentation(
		// 	docs.manifests,
		// 	classes
		// )

		// let _sortedOrders = _orderBy(
		// 	getFilteredDocs(_ordersArrayRepresentation, ordersFilter, direction),
		// 	orderBy,
		// 	direction
		// )
		// let _sortedManifests = _orderBy(
		// 	getFilteredDocs(
		// 		_manifestsArrayRepresentation,
		// 		manifestsFilter,
		// 		direction
		// 	),
		// 	orderBy,
		// 	direction
		// )

		const _sortedOrders = docs.orders
			? sortDocs(
					docs.orders
						.map(order => getPickupOrderRepresentation(order, classes))
						.map(order => {
							order.component = (
								<PickupOrderCard
									doc={order}
									handleClick={handleOrderClick}
									classes={classes}
								/>
							)

							return order
						})
						.filter(order => filterDocs(order, ordersFilter)),
					orderBy,
					direction
			  )
			: []

		const _sortedManifests = docs.manifests
			? sortDocs(
					docs.manifests
						.map(manifest =>
							getPickupManifestsRepresentation(manifest, classes)
						)
						.map(manifest => {
							manifest.component = (
								<PickupManifestCard
									doc={manifest}
									handleClick={handleManifestClick}
									classes={classes}
								/>
							)

							return manifest
						})

						.filter(manifest => filterDocs(manifest, ordersFilter)),
					orderBy,
					direction
			  )
			: []

		let _arrayForVitrualList = getArrayForVirtualList(
			headerRows,
			_sortedOrders,
			manifestsHeaderRows,
			_sortedManifests
		)
		setArrayForVitrualList(_arrayForVitrualList)
	}, [docs, ordersFilter, manifestsFilter])

	function getArrayForVirtualList(
		orderHeaderRows,
		ordersArrayRepresentation,
		manifestsHeaderRows,
		manifestsArrayRepresentation
	) {
		return [
			{
				type: 'orderListHeader',
				caption: 'Заказы на забор',
				orderField: 0,
				headerRows: orderHeaderRows,
				component: (
					<ListHeader
						caption={'Заказы на забор'}
						headerRows={orderHeaderRows}
						filter={ordersFilter}
						handleFilterChange={handleFilterChange}
						type={'order'}
					/>
				),
			},
			...ordersArrayRepresentation,
			{
				type: 'manifestListHeader',
				caption: 'манифесты входяшие',
				orderField: 2,
				headerRows: manifestsHeaderRows,
				component: (
					<ListHeader
						caption={'манифесты входяшие'}
						headerRows={manifestsHeaderRows}
						filter={manifestsFilter}
						handleFilterChange={handleFilterChange}
						type={'manifest'}
					/>
				),
			},
			...manifestsArrayRepresentation,
		]
	}

	const fetchData = async () => {
		let docs = await pickups.getLocal()
		setDocs(docs)

		docs = await pickups.getFromServer()
		if (docs.redirectTo) return history.push(docs.redirectTo)
		setDocs(docs)
	}

	const handleFilterChange = (event, type) => {
		console.log(type, event.target.value)
		if (type === 'order') setOrdersFilter(event.target.value)
		if (type === 'manifest') setManifestsFilter(event.target.value)
		// event.target.value
	}

	const handleOrderClick = (event, orderRepresentation) => {
		history.push(`/pickups/order/${orderRepresentation._id}`)
	}

	const handleManifestClick = (event, manifestRepresentation) => {
		history.push(`/pickups/manifest/${manifestRepresentation._id}`)
	}

	const handleOpenManifest = manifest => {
		this.setState({
			manifestWindowIsOpened: true,
			manifest,
		})
	}

	const isCheckboxVisible = element => false

	return (
		<React.Fragment>
			{/* <h1 className={classes.h1}>Заборы</h1> */}

			<SortableCardList
				docs={arrayForVitrualList}
				classes={classes}
				isCheckboxVisible={isCheckboxVisible}
			/>
		</React.Fragment>
	)
}
