import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import PropTypes, { string } from 'prop-types'
import format from 'date-fns/format'
// import classNames from 'classnames/bind'
import { set, max, sortBy, get, isString } from 'lodash'
import { v1 as uuidv1 } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'

// // Import React FilePond
// import { FilePond, registerPlugin } from 'react-filepond'

// // Import FilePond styles
// import 'filepond/dist/filepond.min.css'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
// import CompactTable from './CompactTable'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
// import Select from '@material-ui/core/Select'
import NativeSelect from '@material-ui/core/NativeSelect'
import Input from '@material-ui/core/Input'
import Box from '@material-ui/core/Box'

import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

// import Select from 'react-select'

import pickups from '../fetchers/pickups'
import SortableTable from '../components/SortableTable'
import SpecialConditions from '../components/SpecialConditions'
import { FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import { setAlert } from '../actions/alert'

import { yellow } from '@material-ui/core/colors'

// import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib

const useStyles = makeStyles((theme) => ({
	marginBottom: {
		marginBottom: theme.spacing(2),
	},
	warning: {
		color: yellow[500],
	},
}))

function CreateManifestDialog({
	open,
	close,
	orders,
	classes: parentClasses,
	openedManifest,
	profile,
	setAlert,
}) {
	// const [files, setFiles] = useState([])
	// const [pondRef, setPondRef] = useState(undefined)
	const [manifest, setManifest] = useState(undefined)
	const [terminals, setTerminals] = useState([])
	const [carriers, setCarriers] = useState([])
	const [carrierSelectionType, setCarrierSelectionType] = useState('text')
	const [departurePointSelectionType, setDeparturePointSelectionType] =
		useState('text')
	const [receptionPointSelectionType, setReceptionPointSelectionType] =
		useState('text')
	const [manifestInPending, setManifestInPending] = useState(false)

	const classes = { ...parentClasses, ...useStyles() }

	useEffect(() => {
		if (!open) return

		async function setManifestData() {
			const totalWeight = orders.reduce(
				(sum, order) => sum + order.totalWeight,
				0
			)
			const totalVolume = orders.reduce(
				(sum, order) => sum + order.totalVolume,
				0
			)

			const consigneeTerminal = orders.reduce((consigneeTerminal, order) => {
				if (order.nextTerminals.length === 0) return consigneeTerminal

				if (consigneeTerminal) {
					if (
						consigneeTerminal.uid &&
						consigneeTerminal.uid !== order.nextTerminals[0].uid
					)
						return {}
					else return consigneeTerminal
				} else {
					return order.nextTerminals[0]
				}
			}, null)

			let newManifest
			const now = new Date()

			let number = ''
			if (consigneeTerminal) {
				number = await pickups.getNewManifestNumber(now, consigneeTerminal.code)
			}

			newManifest = {
				type: 'manifest',
				transferToCarrierDateFact: now,
				// time: now,
				consigneeTerminal,
				number,
				totalPlaces: 0,
				totalWeight,
				totalVolume,
				carrier: { name: '' },
				departurePoint: { name: '' },
				receptionPoint: { name: '' },
				invoiceNumber: '',
				comment: '',
				arrivalToCarrierDatePlan: now,
				// arrivalTime: now,
				checkpointCurrent: '', // передан перевозчику
				checkpointPrevious: '',
				orders,
				cargoIsDangerous: max(orders.map((order) => order.cargoIsDangerous)),
				cargoIsHeavyweight: max(
					orders.map((order) => order.cargoIsHeavyweight)
				),
				cargoIsLowTemperature: max(
					orders.map((order) => order.cargoIsLowTemperature)
				),
				cargoIsOversized: max(orders.map((order) => order.cargoIsOversized)),
				returnDocumentsRequired: max(
					orders.map((order) => order.returnDocumentsRequired)
				),
				contentDescription: '',
				packingDescription: '',
				serviceInDeparturePoint: false,
				serviceInReceptionPoint: false,
				attachHAWB: false,
				tempUid: uuidv1(),
			}
			if (openedManifest) {
				newManifest = { ...newManifest, ...openedManifest }
			}

			if (newManifest.carrier.uid) setCarrierSelectionType('list')
			else setCarrierSelectionType('text')

			setManifest(newManifest)
		}
		setManifestData()
	}, [open])

	// загружаем терминалы
	useEffect(() => {
		if (!open) return

		async function setTerminalsData() {
			let newTerminals = await pickups.getTerminalsFromServer()
			if (!newTerminals) {
				setAlert({ messageText: 'Ошибка связи с сервером. Попробуйе позже' })
				return close()
			}
			newTerminals = newTerminals.filter(
				(termnal) => termnal.uid !== profile.terminal.uid
			)
			setTerminals(newTerminals)
		}
		setTerminalsData()
	}, [open])

	// загружаем перевозчиков
	useEffect(() => {
		if (!open) return

		async function setCarriersData() {
			let newCarriers = await pickups.getCarriersFromServer()
			newCarriers = sortBy(newCarriers, ['name'])
			setCarriers(newCarriers)
		}
		setCarriersData()
	}, [open])

	const fieldHasErrors = (name) => {
		if (
			name === 'totalPlaces' ||
			name === 'totalWeight' ||
			name === 'totalVolume'
		) {
			if (manifest[name] <= 0) return true
		} else if (name === 'consigneeTerminal.uid') {
			if (!get(manifest, name)) return true
		}
	}

	const sumbmitIsDisabled = () => {
		return [
			'totalPlaces',
			'totalWeight',
			'totalVolume',
			'consigneeTerminal.uid',
		].some((name) => fieldHasErrors(name) || manifestInPending)
	}

	const handleCreateManifest = async () => {
		setManifestInPending(true)
		const comment =
			// manifest.checkpointCurrent === ''
			// 	? 'Создание манифеста'
			// 	:
			'Передан перевозчику'
		const newManifest = await pickups.shipManifest(manifest, comment, setAlert)
		setManifestInPending(false)
		if (newManifest && !newManifest.error) close()
	}

	const handleCloseWindow = () => {
		close()
	}

	const onChangeManifestField = async (event) => {
		let newValue = pickups.onDialogFieldChange(event, manifest)
		for (const key in newValue) {
			newValue = set({}, key, newValue[key])
		}
		// let newValue
		let targetName = event.target.name
		// if (
		// 	event.target.type === 'date' ||
		// 	event.target.type === 'time' ||
		// 	event.target.type === 'datetime-local'
		// ) {
		// 	const tzo = -new Date().getTimezoneOffset()
		// 	const dif = tzo >= 0 ? '+' : '-'
		// 	const pad = function(num) {
		// 		const norm = Math.abs(Math.floor(num))
		// 		return (norm < 10 ? '0' : '') + norm
		// 	}
		// 	const date = manifest[targetName]
		// 	let dateInt
		// 	if (event.target.type === 'time') {
		// 		const value = event.target.value ? event.target.value : '00:00'
		// 		dateInt = Date.parse(
		// 			`${format(date, 'yyyy-MM-dd')}T${value}:00${dif}${pad(
		// 				tzo / 60
		// 			)}:${pad(tzo % 60)}`
		// 		)
		// 	} else {
		// 		const value = event.target.value ? event.target.value : '1970-01-01'
		// 		dateInt = Date.parse(
		// 			`${value}T${format(date, 'HH:mm')}:00${dif}${pad(tzo / 60)}:${pad(
		// 				tzo % 60
		// 			)}`
		// 		)
		// 	}
		// 	newValue = new Date(dateInt)
		// 	// } else if (event.target.type === 'time') {
		// 	// 	if (event.target.value) {
		// 	// 		const tzo = -new Date().getTimezoneOffset()
		// 	// 		const dif = tzo >= 0 ? '+' : '-'
		// 	// 		const pad = function(num) {
		// 	// 			const norm = Math.abs(Math.floor(num))
		// 	// 			return (norm < 10 ? '0' : '') + norm
		// 	// 		}
		// 	// 		const dateInt = Date.parse(
		// 	// 			`1970-01-01T${event.target.value}:00${dif}${pad(tzo / 60)}:${pad(
		// 	// 				tzo % 60
		// 	// 			)}`
		// 	// 		)

		// 	// 		newValue = new Date(dateInt)
		// 	// 	} else {
		// 	// 		newValue = undefined
		// 	// 	}
		// } else if (event.target.type === 'number') {
		// 	newValue = parseFloat(event.target.value)
		// } else {
		// 	newValue = event.target.value
		// }

		let numberModifier = {}
		if (targetName === 'date') {
			// после этой строки event.target становится null (?)
			const newNumber = await pickups.getNewManifestNumber(
				newValue.date,
				manifest.consigneeTerminal.code
			)
			if (newNumber) numberModifier = { number: newNumber }
		} else if (targetName === 'consigneeTerminal.uid') {
			let terminal = terminals.find(
				(terminal) => terminal.uid === newValue.consigneeTerminal.uid
			)
			if (!terminal) terminal = { uid: '' }
			const newNumber = await pickups.getNewManifestNumber(
				manifest.transferToCarrierDateFact,
				terminal && terminal.code
			)
			// if (newNumber)
			numberModifier = { number: newNumber }
			newValue = { consigneeTerminal: terminal }
		} else if (targetName === 'carrierList') {
			const targetNameWithoutList = targetName.replace('List', '')
			const carrier = carriers.find(
				(carrier) => carrier.uid === newValue[targetName]
			)
			newValue = { [targetNameWithoutList]: carrier }
		} else if (
			targetName === 'departurePointList' ||
			targetName === 'receptionPointList'
		) {
			const targetNameWithoutList = targetName.replace('List', '')
			const terminal = terminals.find(
				(terminal) => terminal.uid === newValue[targetName]
			)
			newValue = { [targetNameWithoutList]: terminal }
		} else if (targetName === 'serviceInReceptionPoint') {
			if (newValue[targetName])
				newValue = { ...newValue, receptionPoint: { name: '', uid: '' } }
		} else if (targetName === 'serviceInDeparturePoint') {
			if (newValue[targetName])
				newValue = { ...newValue, departurePoint: { name: '', uid: '' } }
		}
		// if (
		// 	targetName === 'carrier' ||
		// 	targetName === 'departurePoint' ||
		// 	targetName === 'receptionPoint'
		// )
		// else {
		// 	newValue = { name: newValue }
		// }

		setManifest({
			...manifest,
			...newValue,
			...numberModifier,
		})
	}

	const onChangeСonsigneeTerminal = async (event, name, value) => {
		const newValue = set({}, name, value)

		const newNumber = await pickups.getNewManifestNumber(
			manifest.transferToCarrierDateFact,
			newValue.consigneeTerminal && newValue.consigneeTerminal.code
		)
		// if (newNumber)
		const numberModifier = { number: newNumber }

		setManifest({ ...manifest, ...newValue, ...numberModifier })
	}

	const onChangeCarrier = (event, carrier) => {
		if (isString(carrier)) {
			setManifest({ ...manifest, carrier: { name: carrier } })
		} else {
			setManifest({ ...manifest, carrier: carrier })
		}
	}

	const handleCarrierSelectionTypeChange = (event, newType) => {
		if (!newType) return
		setCarrierSelectionType(newType)
	}

	const handleDeparturePointTypeChange = (event, newType) => {
		if (!newType) return
		setDeparturePointSelectionType(newType)
	}

	const handleReceptionPointTypeChange = (event, newType) => {
		if (!newType) return
		setReceptionPointSelectionType(newType)
	}
	// const onCarrierChange = selectedCarrier => {
	// 	const carrier = {
	// 		uid: selectedCarrier.value,
	// 		name: selectedCarrier.label
	// 	}

	// 	setManifest({
	// 		...manifest,
	// 		carrier
	// 	})
	// }

	if (!manifest) return null
	if (terminals.length === 0) return null

	const headerRows = [
		[
			{
				label: 'номер',
				id: 'number',
			},
			{
				label: 'накладная',
				id: 'invoiceNumber',
			},
			{
				label: 'сумма',
				id: 'sum',
			},
			{
				label: 'мест',
				id: 'totalPlaces',
			},
			{
				label: 'вес, кг',
				id: 'totalWeight',
			},
			{
				label: 'объём, м3',
				id: 'totalVolume',
			},
			{
				label: 'след. терминал',
				id: 'nextTerminal',
			},
			{
				label: 'спецусловия',
				id: 'specialConditions',
			},
		],
	]

	const arrayRepresentation = manifest.orders.map((order) => {
		return {
			number: order.number,
			invoiceNumber: order.invoiceNumber,
			sum: order.sum,
			totalPlaces: order.totalPlaces,
			totalWeight: order.totalWeight,
			totalVolume: order.totalVolume,
			nextTerminal:
				order.nextTerminals &&
				order.nextTerminals.length > 0 &&
				order.nextTerminals[0].code,
			specialConditions: <SpecialConditions conditions={order} />,
		}
	})

	return (
		<Dialog
			open={open}
			onClose={close}
			onBackdropClick={close}
			onEscapeKeyDown={close}
			aria-labelledby='form-dialog-title'
			fullScreen={true}
		>
			<DialogTitle id='form-dialog-title'>
				{`Создание манифеста ${manifest.number}`}{' '}
			</DialogTitle>

			<DialogContent>
				{' '}
				{/* <DialogContentText>
				{`Выбранные заказы:`}
			</DialogContentText> */}
				{/* <CompactTable
					caption={'Заказы'}
					columns={columns}
					table={manifest.orders}
				/> */}
				<SortableTable
					caption='Заказы'
					headerRows={headerRows}
					array={arrayRepresentation}
					// handleCheckboxClick={handleCheckboxClick}
					// handleRowClick={handleRowClick}
					totalsIds={['totalPlaces', 'totalWeight', 'totalVolume']}
				/>
				<div className={classes.marginBottom}></div>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextField
							autoFocus
							// margin='dense'
							label='Дата передачи'
							type='date'
							name='transferToCarrierDateFact'
							InputLabelProps={{
								shrink: true,
							}}
							// defaultValue={format(new Date(), 'yyyy-MM-dd')}
							// fullWidth
							value={
								manifest.transferToCarrierDateFact &&
								format(manifest.transferToCarrierDateFact, 'yyyy-MM-dd')
							}
							onChange={(event) => onChangeManifestField(event)}
						/>

						<TextField
							// margin='dense'
							label='Время'
							type='time'
							name='transferToCarrierDateFact'
							InputLabelProps={{
								shrink: true,
							}}
							// defaultValue={format(new Date(), 'HH:mm')}
							// fullWidth
							value={
								manifest.transferToCarrierDateFact &&
								format(manifest.transferToCarrierDateFact, 'HH:mm')
							}
							onChange={(event) => onChangeManifestField(event)}
						/>

						{/* <FormControl
							className={classes.formControl}
							error={fieldHasErrors('consigneeTerminal.uid')}
						>
							<InputLabel htmlFor='consignee-terminal'>
								Терминал грузополучателя
							</InputLabel>
							<NativeSelect
								value={
									manifest.consigneeTerminal && manifest.consigneeTerminal.uid
								}
								onChange={event => onChangeManifestField(event)}
								input={
									<Input name='consigneeTerminal.uid' id='consignee-terminal' />
								}
							>
								<option value={''}>{''}</option>
								{terminals
									.filter(terminal => terminal.isPartnersTerminal) // здесь показываем только терминалы партнеров
									.map(terminal => (
										<option key={terminal.uid} value={terminal.uid}>
											{terminal.name}
										</option>
									))}
							</NativeSelect>
							{fieldHasErrors('consigneeTerminal.uid') && (
								<FormHelperText>не заполнено</FormHelperText>
							)}
						</FormControl> */}
						<FormControl
							className={classes.formControl}
							error={fieldHasErrors('consigneeTerminal.uid')}
						>
							<Autocomplete
								// freeSolo
								// id='free-solo-2-demo'
								disableClearable
								style={{ width: 300 }}
								options={
									terminals.filter((terminal) => terminal.isPartnersTerminal) // здесь показываем только терминалы партнеров
								}
								getOptionLabel={(terminal) => terminal.name}
								value={manifest.consigneeTerminal}
								onChange={(event, value) =>
									onChangeСonsigneeTerminal(event, 'consigneeTerminal', value)
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Терминал грузополучателя'
										// margin='normal'
										// variant='outlined'
										fullWidth
										// name='consigneeTerminal.uid'
										// InputProps={{
										// 	...params.InputProps,
										// 	// name: 'consigneeTerminal.uid',
										// 	type: 'search',
										// }}
									/>
								)}
							/>
							{fieldHasErrors('consigneeTerminal.uid') && (
								<FormHelperText>не заполнено</FormHelperText>
							)}
						</FormControl>

						<SpecialConditions conditions={manifest} />
					</Grid>

					<Grid item xs={4}>
						<FormControl
							className={classes.formControl}
							error={fieldHasErrors('totalPlaces')}
						>
							<TextField
								// margin='dense'
								label='мест'
								type='number'
								name='totalPlaces'
								fullWidth
								value={manifest.totalPlaces}
								onChange={(event) => onChangeManifestField(event)}
								inputProps={{ min: 0, step: 1 }}
							/>
							{fieldHasErrors('totalPlaces') && (
								<FormHelperText>не заполнено</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormControl
							className={classes.formControl}
							error={fieldHasErrors('totalWeight')}
						>
							<TextField
								// margin='dense'
								label='вес, кг'
								type='number'
								name='totalWeight'
								fullWidth
								value={manifest.totalWeight}
								onChange={(event) => onChangeManifestField(event)}
								inputProps={{ min: 0, step: 0.01 }}
							/>
							{fieldHasErrors('totalWeight') && (
								<FormHelperText>не заполнено</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormControl
							className={classes.formControl}
							error={fieldHasErrors('totalVolume')}
						>
							<TextField
								// margin='dense'
								label='объём, м3'
								type='number'
								name='totalVolume'
								fullWidth
								value={manifest.totalVolume}
								onChange={(event) => onChangeManifestField(event)}
								inputProps={{
									min: 0,
									step: 0.001,
									pattern: '[0-9]+([.,][0-9]+)?',
								}}
							/>
							{fieldHasErrors('totalVolume') && (
								<FormHelperText>не заполнено</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<TextField
							// margin='dense'
							label='Описание содержимого'
							type='text'
							name='contentDescription'
							fullWidth
							value={manifest.contentDescription}
							onChange={(event) => onChangeManifestField(event)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							// margin='dense'
							label='Описание упаковки'
							type='text'
							name='packingDescription'
							fullWidth
							value={manifest.packingDescription}
							onChange={(event) => onChangeManifestField(event)}
						/>
					</Grid>
				</Grid>
				{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker autoOk
                        ampm={false}
                        disableFuture
                        value={
                            manifest.dateTime
                        }
                        onChange={
                            (event) => onChangeManifestField(event)
                        }
                        label="Дата/время"/>
								</MuiPickersUtilsProvider> */}
				{/* <Box display='flex'>
					<Box flexGrow={1}>
						{carrierSelectionType === 'list' ? (
							<>
								<FormControl fullWidth className={classes.formControl}>
									<InputLabel htmlFor='carrier'>Перевозчик</InputLabel>
									<NativeSelect
										value={manifest.carrier.uid}
										onChange={event => onChangeManifestField(event)}
										input={<Input name='carrierList' id='carrier' />}
									>
										<option value='' />
										{carriers.map(carrier => (
											<option key={carrier.uid} value={carrier.uid}>
												{carrier.name}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</>
						) : (
							<>
								<TextField
									fullWidth
									// margin='dense'
									label='Перевозчик'
									type='text'
									name='carrier.name'
									value={manifest.carrier.name}
									onChange={event => onChangeManifestField(event)}
								/>
							</>
						)}
					</Box>
					<Box>
						<ToggleButtonGroup
							size='small'
							value={carrierSelectionType}
							exclusive
							onChange={handleCarrierSelectionTypeChange}
						>
							<ToggleButton value='list'>справочник</ToggleButton>
							<ToggleButton value='text'>текст</ToggleButton>{' '}
						</ToggleButtonGroup>
					</Box>
				</Box> */}
				<Box display='flex'>
					<Box flexGrow={1}>
						<Autocomplete
							freeSolo
							disableClearable
							// style={{ width: 500 }}
							options={carriers}
							getOptionLabel={(carrier) => carrier.name}
							value={manifest.carrier}
							onChange={(event, value) => onChangeCarrier(event, value)}
							renderInput={(params) => (
								<TextField {...params} label='Перевозчик' fullWidth />
							)}
						/>
					</Box>
					<Box>
						{manifest.carrier.name && !manifest.carrier.uid && (
							<TextFieldsIcon color='error' />
						)}
					</Box>
				</Box>
				<Box display='flex'>
					<Box flexGrow={1}>
						{departurePointSelectionType === 'list' ? (
							<>
								<FormControl fullWidth className={classes.formControl}>
									<InputLabel htmlFor='departure-point'>
										место убытия
									</InputLabel>
									<NativeSelect
										value={manifest.departurePoint.uid}
										onChange={(event) => onChangeManifestField(event)}
										input={
											<Input
												name='departurePointList'
												id='departure-point'
												disabled={manifest.serviceInDeparturePoint}
											/>
										}
									>
										<option value='' />
										{terminals
											.filter(
												(terminal) =>
													!terminal.isPartnersTerminal &&
													terminal.ownerUid === manifest.carrier.uid
											)
											.map((terminal) => (
												<option key={terminal.uid} value={terminal.uid}>
													{terminal.name}
												</option>
											))}
									</NativeSelect>
								</FormControl>
							</>
						) : (
							<>
								<TextField
									// margin='dense'
									label='Место убытия'
									type='text'
									name='departurePoint.name'
									fullWidth
									value={manifest.departurePoint.name}
									onChange={(event) => onChangeManifestField(event)}
									disabled={manifest.serviceInDeparturePoint}
								/>
							</>
						)}
					</Box>
					<Box>
						<ToggleButtonGroup
							size='small'
							value={departurePointSelectionType}
							exclusive
							onChange={handleDeparturePointTypeChange}
						>
							<ToggleButton
								value='list'
								disabled={manifest.serviceInDeparturePoint}
							>
								справочник
							</ToggleButton>
							<ToggleButton
								value='text'
								disabled={manifest.serviceInDeparturePoint}
							>
								текст
							</ToggleButton>{' '}
						</ToggleButtonGroup>
					</Box>
				</Box>
				<Box display='flex'>
					<Box flexGrow={1}>
						{receptionPointSelectionType === 'list' ? (
							<>
								<FormControl fullWidth className={classes.formControl}>
									<InputLabel htmlFor='reception-point'>
										место прибытия
									</InputLabel>
									<NativeSelect
										value={manifest.receptionPoint.uid}
										onChange={(event) => onChangeManifestField(event)}
										input={
											<Input
												name='receptionPointList'
												id='reception-point'
												disabled={manifest.serviceInReceptionPoint}
											/>
										}
									>
										<option value='' />
										{terminals
											.filter(
												(terminal) =>
													!terminal.isPartnersTerminal &&
													terminal.ownerUid === manifest.carrier.uid
											)
											.map((terminal) => (
												<option value={terminal.uid}>{terminal.name}</option>
											))}
									</NativeSelect>
								</FormControl>
							</>
						) : (
							<>
								<TextField
									// margin='dense'
									label='Место прибытия'
									type='text'
									name='receptionPoint.name'
									fullWidth
									value={manifest.receptionPoint.name}
									onChange={(event) => onChangeManifestField(event)}
									disabled={manifest.serviceInReceptionPoint}
								/>
							</>
						)}
					</Box>
					<Box>
						<ToggleButtonGroup
							size='small'
							value={receptionPointSelectionType}
							exclusive
							onChange={handleReceptionPointTypeChange}
						>
							<ToggleButton
								value='list'
								disabled={manifest.serviceInReceptionPoint}
							>
								справочник
							</ToggleButton>
							<ToggleButton
								value='text'
								disabled={manifest.serviceInReceptionPoint}
							>
								текст
							</ToggleButton>{' '}
						</ToggleButtonGroup>
					</Box>
				</Box>
				{/* <Select
					classes={classes}
					// styles={selectStyles}
					inputId='react-select-single'
					TextFieldProps={{
						label: 'Перевозчик',
						InputLabelProps: {
							htmlFor: 'react-select-single',
							shrink: true
						}
					}}
					placeholder='Выберите перевозчика'
					options={carriers.map(carrier => ({
						value: carrier.uid,
						label: carrier.name
					}))}
					// components={components}
					value={{ value: manifest.carrier.uid, label: manifest.carrier.name }}
					onChange={onCarrierChange}
				/> */}
				<Grid container>
					<Grid item xs={6}>
						<FormControlLabel
							control={
								<Checkbox
									name='serviceInDeparturePoint'
									checked={manifest.serviceInDeparturePoint}
									onChange={(event) => onChangeManifestField(event)}
									// value="checkedB"
									color='primary'
								/>
							}
							label='Перевозчик заберёт сам'
						/>
					</Grid>
					<Grid item xs={6}>
						<FormControlLabel
							control={
								<Checkbox
									name='serviceInReceptionPoint'
									checked={manifest.serviceInReceptionPoint}
									onChange={(event) => onChangeManifestField(event)}
									// value="checkedB"
									color='primary'
								/>
							}
							label='ТЭУ в месте прибытия'
						/>
					</Grid>
					<Grid item xs={6}>
						<FormControlLabel
							control={
								<Checkbox
									name='attachHAWB'
									checked={manifest.attachHAWB}
									onChange={(event) => onChangeManifestField(event)}
									// value="checkedB"
									color='primary'
								/>
							}
							label='Добавить накладные к манифесту'
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							// margin='dense'
							label='Номер документа перевозчика'
							type='text'
							name='invoiceNumber'
							fullWidth
							value={manifest.invoiceNumber}
							onChange={(event) => onChangeManifestField(event)}
						/>
					</Grid>
				</Grid>
				<TextField
					// margin='dense'
					label='Комментарий'
					type='text'
					name='comment'
					multiline
					fullWidth
					value={manifest.comment}
					onChange={(event) => onChangeManifestField(event)}
				/>
				{/* <TextField
					// margin='dense'
					label='Дата/время прибытия'
					type='datetime-local'
					name='dateOfArrivalPlan'
					value={manifest.dateOfArrivalPlan}
					// defaultValue={
					//     format(new Date(), 'YYYY-MM-DDTHH:mm')
					// }

					fullWidth
					onChange={event => onChangeManifestField(event)}
                /> */}
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextField
							// autoFocus
							// margin='dense'
							label='Дата прибытия'
							type='date'
							name='arrivalToCarrierDatePlan'
							InputLabelProps={{
								shrink: true,
							}}
							value={
								manifest.arrivalToCarrierDatePlan &&
								format(manifest.arrivalToCarrierDatePlan, 'yyyy-MM-dd')
							}
							onChange={(event) => onChangeManifestField(event)}
						/>

						<TextField
							// margin='dense'
							label='Время'
							type='time'
							name='arrivalToCarrierDatePlan'
							InputLabelProps={{
								shrink: true,
							}}
							value={
								manifest.arrivalToCarrierDatePlan &&
								format(manifest.arrivalToCarrierDatePlan, 'HH:mm')
							}
							onChange={(event) => onChangeManifestField(event)}
						/>
					</Grid>
				</Grid>
				{/* <FilePond
					labelIdle='бросайте сюда файлы или нажмите, чтобы загрузить'
					ref={ref => setPondRef(ref)}
					files={files}
					allowMultiple={true}
					maxFiles={3}
					server='/api/upload'
					oninit={() => handleInit()}
					onupdatefiles={fileItems => {
						// Set currently active file objects to this.state
						setFiles(
							fileItems.map(fileItem => {
								fileItem.setMetadata('doc', {
									type: 'manifest',
									number: manifest.number,
								})
								return {
									file: fileItem.file,
								}
							})
						)
						// setFiles(fileItems)
					}}
					onaddfilestart={file => {
						console.log(file)
					}}
				/>{' '} */}
				{/* <FormControlLabel
				control={
					<Checkbox
						// checked={this.state.checkedB}
						// onChange={this.handleChange('checkedB')}
						// value="checkedB"
						color="primary"
					/>
				}
				label="Груз получен с актом о повреждении"
			/>						 */}{' '}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseWindow} color='primary'>
					Отмена
				</Button>
				<Button
					onClick={(event) => handleCreateManifest(event)}
					variant='contained'
					color='primary'
					disabled={sumbmitIsDisabled()}
				>
					Отгрузить (МЧ+МС+МП+МГ+МЭ+МО)
				</Button>
			</DialogActions>
		</Dialog>
	)
}

CreateManifestDialog.propTypes = {}

const mapStateToProps = (state) => ({
	profile: state.profile,
})

export default connect(mapStateToProps, { setAlert })(CreateManifestDialog)
