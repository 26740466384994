function isDocMatch(doc, obj) {
	return (
		doc.type === obj.type &&
		(doc.uid ? doc.uid === obj.uid : true) &&
		(doc.tempUid ? doc.tempUid === obj.tempUid : true) &&
		(doc._id ? doc._id === obj._id : true)
	)
}

export { isDocMatch }
