import React, { useState, useEffect } from 'react'
import {
	Card,
	CardContent,
	CardActions,
	CardActionArea,
	Box,
	Grid,
	Typography,
	makeStyles,
	Checkbox,
	TextField,
	Select,
} from '@material-ui/core'
import ManifestNumber from './ManifestNumber'
import { orderBy } from 'lodash'

const useStyles = makeStyles({
	gridFullWidth: {
		width: '100%',
	},
	card: {
		flex: 1,
	},
})

export default function PickupManifestCard({
	classes: parentClasses,
	docs,
	headerRows,
	caption,
	handleClick,
	isCheckboxVisible,
	handleCheckboxClick,
	DocCardComponent,
}) {
	const classes = { ...parentClasses, ...useStyles() }

	const [filter, setFilter] = useState('')
	const [selectedSortField, setSelectedSortField] = useState('number')
	const [sortFields, setSortFields] = useState([])
	const [sortDirection, setSortDirection] = useState('desc')

	const [visibleDocs, setVisibleDocs] = useState([])

	useEffect(() => {
		setVisibleDocs(
			getVisibleDocs(docs, selectedSortField, sortDirection, filter)
		)

		const _sortFields = []
		headerRows.forEach((headerLine, headerLineIndex) => {
			return headerLine.forEach((headerCell, index) => {
				_sortFields.push(headerCell)
			})
		})

		setSortFields(_sortFields)
	}, [docs])

	const handleFilterChange = event => {
		// const _sortedCompleted = [...sortedCompleted]
		// _sortedCompleted.filter
		const _filter = event.target.value
		setFilter(_filter)
		setVisibleDocs(
			getVisibleDocs(docs, selectedSortField, sortDirection, _filter)
		)
	}

	const handleSortFieldChange = event => {
		// const _sortedCompleted = [...sortedCompleted]
		// _sortedCompleted.filter
		const _sortField = event.target.value
		setSelectedSortField(_sortField)
		setVisibleDocs(getVisibleDocs(docs, _sortField, sortDirection, filter))
	}

	const getVisibleDocs = (docs, sortField, sortDirection, filter) => {
		const sortedFiltered = orderBy(docs, sortField, sortDirection).filter(
			completedLine => {
				if (!filter) return true

				let isMatch = false
				Object.keys(completedLine).forEach(key => {
					if (isMatch) return
					if (
						String(completedLine[key])
							.toLowerCase()
							.indexOf(filter.toLowerCase()) !== -1
					)
						isMatch = true
				})
				return isMatch
			}
		)
		return sortedFiltered
	}

	return (
		<>
			<Grid container justify='space-between'>
				<Grid item>
					<h3>{caption}</h3>
				</Grid>

				<Grid item>
					<Select
						native
						value={selectedSortField}
						onChange={handleSortFieldChange}
						style={{ marginTop: '16px' }}
						inputProps={{ style: { fontSize: '14px' } }}
					>
						{sortFields.map(sortField => (
							<option value={sortField.id}>{sortField.label}</option>
						))}
					</Select>
				</Grid>

				<Grid item>
					<TextField
						// id='standard-search'
						label='фильтр'
						type='search'
						className={classes.textField}
						// margin='normal'
						value={filter}
						onChange={handleFilterChange}
					/>
				</Grid>
			</Grid>

			<Grid container spacing={1}>
				{visibleDocs.map(doc => {
					return (
						<Grid
							item
							key={doc.uid + doc._id}
							className={classes.gridFullWidth}
						>
							<Box display='flex' justifyContent='space-between'>
								{isCheckboxVisible && isCheckboxVisible(doc) && (
									<Checkbox
										checked={doc.isChecked}
										onClick={event => handleCheckboxClick(event, doc)}
									/>
								)}
								<DocCardComponent
									doc={doc}
									handleClick={handleClick}
									classes={classes}
								/>
							</Box>
						</Grid>
					)
				})}
			</Grid>
		</>
	)
}
