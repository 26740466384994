import axios from 'axios'
import { Storage } from '../services/storage'
import { format, isWithinInterval, startOfDay, endOfDay } from 'date-fns'
import { compact } from 'lodash'

import documentsFetcher from './documents'
import documents from './documents'

export default {
	async getLocal(documentsType) {
		if (documentsType) {
			return await documentsFetcher.getLocal(documentsType)
		} else {
			const { statuses, orders, manifests } = await documentsFetcher.getLocal()
			return this.getDocs(statuses, orders, manifests)
		}
	},

	getDocs(statuses, orders, manifests) {
		const docs = {
			orders: statuses
				.filter(
					docStatus =>
						docStatus.type === 'order' &&
						(docStatus.status === 'Доставка' ||
							// docStatus.status === 'Доставка в манифесте' ||
							docStatus.status === 'На складе после развала манифеста' ||
							docStatus.status === 'На складе после забора')
				)
				.map(docStatus =>
					orders.find(
						order =>
							order.type === 'order' &&
							((order.uid &&
								docStatus.documentUid &&
								order.uid === docStatus.documentUid) ||
								(order._id &&
									docStatus.documentId &&
									order._id === docStatus.documentId))
					)
				)
				.filter(order => !!order),
			manifests: compact(
				statuses
					.filter(
						docStatus =>
							docStatus.type === 'manifest' && docStatus.status === 'Доставка'
						// 	||
						// (docStatus.type === 'manifest' && docStatus.status === 'Завершен')
					)
					.map(docStatus =>
						manifests.find(
							manifest =>
								manifest.type === 'manifest' &&
								((manifest.uid &&
									docStatus.documentUid &&
									manifest.uid === docStatus.documentUid) ||
									(manifest._id &&
										docStatus.documentId &&
										manifest._id === docStatus.documentId))
							// 	&&
							// !(
							// 	docStatus.status === 'Завершен' &&
							// 	documentsFetcher.hasTransportDocs(manifest)
							// )
						)
					)
					.filter(manifest => !!manifest)
			),
		}

		return docs
	},

	async getFromServer() {
		const dataFromServer = await documentsFetcher.getFromServer()
		if (!dataFromServer) return
		if (dataFromServer && dataFromServer.redirectTo) return dataFromServer

		const { statuses, orders, manifests } = dataFromServer
		return this.getDocs(statuses, orders, manifests)
	},

	getLocalManifests() {
		return Storage.getData('deliveryManifests')
	},

	async getManifestsFromServer() {
		try {
			const currentUser = await Storage.getData('currentUser')
			const res = await axios.get('/api/deliveries/manifests', {
				params: { terminalUid: currentUser.terminalUid },
			})
			return res.data
		} catch (error) {
			if (error.response.status === 404) return
		}
	},

	updateLocalManifests(deliveryManifests) {
		return Storage.setData({ deliveryManifests })
	},

	getLocalOrders() {
		return Storage.getData('deliveryOrders')
	},

	// async getOrdersFromServer() {
	// 	try {
	// 		const currentUser = await Storage.getData('currentUser')
	// 		const res = await axios.get('/api/deliveries/orders', {
	// 			params: { terminalUid: currentUser.terminalUid }
	// 		})
	// 		return res.data
	// 	} catch (error) {
	// 		if (error.response.status === 404) return
	// 	}
	// },

	updateLocalOrders(deliveryOrders) {
		return Storage.setData({ deliveryOrders })
	},

	async takeToDelivery(order, comment) {
		const checkpoints = ['ДН', 'КД']

		const changedOrderAndStatus = await this.putOrder({
			order,
			comment,
			checkpoints,
		})
		await documentsFetcher.updateOrderLocal(changedOrderAndStatus)
		return changedOrderAndStatus.order
	},

	async tansferToGS(order, comment) {
		const checkpoints = ['ГС']

		const changedOrderAndStatus = await this.putOrder({
			order,
			comment,
			checkpoints,
		})
		await documentsFetcher.updateOrderLocal(changedOrderAndStatus)
		return changedOrderAndStatus.order
	},

	async deliverySelfPickup(order, comment) {
		const checkpoints = ['ОК']

		const changedOrderAndStatus = await this.putOrder({
			order,
			comment,
			checkpoints,
		})
		await documentsFetcher.updateOrderLocal(changedOrderAndStatus)
		return changedOrderAndStatus.order
	},

	async putOrder({ order, comment, checkpoints, trackingNotes, trackingDate }) {
		return await documentsFetcher.putOrder({
			order,
			comment,
			checkpoints,
			trackingNotes,
			trackingDate,
		})
	},

	onDialogFieldChange(event, obj) {
		return documentsFetcher.onDialogFieldChange(event, obj)
	},

	async orderDelivered(order, orderDelivery) {
		order = {
			...order,
			damageReport: orderDelivery.damageReport,
			paymentAccepted: orderDelivery.paymentAccepted,
			deliveryComment: orderDelivery.comment,
		}
		const comment =
			`${format(orderDelivery.date, 'dd.MM.yyyy HH:mm')}` +
			` водитель доставил для ${orderDelivery.recievedBy} ` +
			`${orderDelivery.places} мест` +
			(orderDelivery.comment ? ` (${orderDelivery.comment})` : '') +
			(orderDelivery.partialDelivery ? ' (частичная доставка)' : '') +
			(orderDelivery.damageReport ? ', был составлен акт о повреждении' : '') +
			(orderDelivery.paymentAccepted ? ', была принята оплата' : '')
		const trackingNotes = `${orderDelivery.recievedBy}`
		const trackingDate = orderDelivery.date

		const checkpoints = [orderDelivery.checkpoint]

		const changedOrderAndStatus = await this.putOrder({
			order,
			comment,
			checkpoints,
			trackingNotes,
			trackingDate,
		})
		await documentsFetcher.updateOrderLocal(changedOrderAndStatus)
		return changedOrderAndStatus.order
	},

	async selfPickupOrderDelivered(order, orderDelivery) {
		order = {
			...order,
			damageReport: orderDelivery.damageReport,
			paymentAccepted: orderDelivery.paymentAccepted,
			deliveryComment: orderDelivery.comment,
		}
		const comment =
			`${format(orderDelivery.date, 'dd.MM.yyyy HH:mm')}` +
			` самовывоз выдан получателю ${orderDelivery.recievedBy} ` +
			`${orderDelivery.places} мест` +
			(orderDelivery.comment ? ` (${orderDelivery.comment})` : '') +
			(orderDelivery.partialDelivery ? ' (частичная доставка)' : '') +
			(orderDelivery.damageReport ? ', был составлен акт о повреждении' : '') +
			(orderDelivery.paymentAccepted ? ', была принята оплата' : '')
		const trackingNotes = `${orderDelivery.recievedBy}`
		const trackingDate = orderDelivery.date

		const checkpoints = [orderDelivery.checkpoint]

		const changedOrderAndStatus = await this.putOrder({
			order,
			comment,
			checkpoints,
			trackingNotes,
			trackingDate,
		})
		await documentsFetcher.updateOrderLocal(changedOrderAndStatus)
		return changedOrderAndStatus.order
	},

	async orderNotDelivered(order, orderNotDelivery) {
		order = {
			...order,
			// deliveryComment: orderNotDelivery.comment,
		}

		const comment =
			`${format(orderNotDelivery.date, 'dd.MM.yyyy HH:mm')}` +
			` Причина: "${orderNotDelivery.reason}"` +
			` (${orderNotDelivery.comment})`

		const checkpoints = [orderNotDelivery.checkpoint]

		const changedOrderAndStatus = await this.putOrder({
			order,
			comment,
			checkpoints,
		})
		await documentsFetcher.updateOrderLocal(changedOrderAndStatus)
	},

	async manifestPickedUp(manifest, manifestReception) {
		manifest = {
			...manifest,
			// arrivalToCarrierDatePlan: manifestReception.date,
			// arrivalToCarrierDateFact: manifestReception.date,
			// pickupFromCarrierDatePlan: manifestReception.date,
			// pickupFromCarrierDateFact: manifestReception.date,
			pickedUpPlacesByDriver: manifestReception.totalPlaces,
			pickedUpPlacesByWarehouse: manifestReception.totalPlaces,
			damageReport: manifestReception.damageReport,
			pickupFromCarrierDateFact: manifestReception.pickupFromCarrierDateFact,
		}

		const comment =
			`${format(
				manifestReception.pickupFromCarrierDateFact,
				'dd.MM.yyyy HH:mm'
			)}` +
			` у ${manifestReception.recievedBy} забрал ` +
			`${manifestReception.totalPlaces} мест` +
			`${manifestReception.damageReport ? '. Составлен акт о повреждении' : ''}`
		const trackingNotes = ''
		const trackingDate = manifestReception.pickupFromCarrierDateFact

		const checkpoints = ['ПП', 'ММ', 'ЗМ', 'МТ', 'ОК']
		const changedManifestAndStatus = await documentsFetcher.putManifest(
			manifest,
			comment,
			checkpoints,
			trackingNotes,
			trackingDate
		)
		await documentsFetcher.updateManifestLocal(changedManifestAndStatus)
	},

	async patchFile(manifest, file) {
		return await documentsFetcher.patchFile(manifest, file)
	},
}
