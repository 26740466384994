import React, { Component } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import classNames from 'classnames'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
// import ToggleButton from '@material-ui/lab/ToggleButton'
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Grid from '@material-ui/core/Grid'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
// import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Link from '@material-ui/core/Link'

import DescriptionLine from '../components/DescriptionLine'

import deliveries from '../fetchers/deliveries'
import pickups from '../fetchers/pickups'
import OrderDeliveryDialog from './OrderDeliveryDialog'
import OrderNotDeliveryDialog from './OrderNotDeliveryDialog'
import FilesManipulation from '../components/FilesManipulation'
import Message from '../components/Message'

import { setAlert } from '../actions/alert'
import OrderNumber from '../components/OrderNumber'
import completed from '../fetchers/completed'
import DeliveryOrderButtons from '../components/DeliveryOrderButtons'

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		width: '100%',
		overflowX: 'auto',
		// paddingTop: theme.spacing.unit * 2,
		// paddingBottom: theme.spacing.unit * 2,
		padding: 20,
		// margin: 20,
		marginTop: 20,
		marginBottom: 20,
	},
	// table: {
	//   minWidth: 700
	// },
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		margin: theme.spacing(1),
	},
	fab2: { right: '159px' },
	fab3: { right: '320px' },
})

const LinkRouter = props => <Link {...props} component={RouterLink} />

class DeliveryOrder extends Component {
	state = {
		deliveryWindowIsOpened: false,
		notDeliveryWindowIsOpened: false,
		// orders: [],
		order: {},
		manifest: {},
		messageIsVisible: false,
		messageText: '',
		buttonsIsDisabled: false,
	}
	setAlert = this.props.setAlert

	async componentDidMount() {
		this.updateOrder()
	}

	updateOrder = async () => {
		let orders = await deliveries.getLocal('orders')
		let manifests = await deliveries.getLocal('manifests')
		const { orderId, manifestId } = this.props.match.params

		let order = orders.find(order => order._id === orderId)
		// если заказ не найден, то возможно, он уже был завершён, и получить его можно только с сервера
		if (!order) {
			order = await completed.getCompletedOrderFromServer(orderId)
		}

		let manifest = manifests.find(manifest => manifest._id === manifestId)
		if (!manifest) {
			manifest = await completed.getCompletedManifestFromServer(manifestId)
		}

		this.setState({ order, manifest })
	}

	// manifestId = this.props.match.params.manifestId
	// orderId = this.props.match.params.orderId

	// handleManifestDetails = (event, manifestDetails) => this.setState({ manifestDetails });

	// взять на доставку (ДН)
	handleClickTakeToDelivery = async () => {
		this.setState({ buttonsIsDisabled: true })
		const changedOrder = await deliveries.takeToDelivery(
			this.state.order,
			'Взят на доставку'
		) // назначен на доставку
		this.setState({ order: changedOrder })
		if (changedOrder.vdo)
			this.setAlert({
				messageText: 'Отправлен запрос на установку номера для ВДО',
			})
		this.setState({ buttonsIsDisabled: false })
	}

	handleClickTansferToGS = async () => {
		const changedOrder = await deliveries.tansferToGS(
			this.state.order,
			'Переведён в ГС'
		) // переведён в ГС
		this.setState({ order: changedOrder })
	}

	// handleClickDeliverySelfPickup = async () => {
	// 	const changedOrder = await deliveries.deliverySelfPickup(
	// 		this.state.order,
	// 		'Самовывоз выдан получателю'
	// 	) // переведён в ГС
	// 	this.setState({ order: changedOrder })
	// }

	handleClickOpenDeliveryWindow = () => {
		this.setState({ deliveryWindowIsOpened: true })
	}

	handleClickOpenNotDeliveryWindow = () => {
		this.setState({ notDeliveryWindowIsOpened: true })
	}

	handleClickCloseDeliveryWindow = async (
		{ order, orderDelivery },
		history
	) => {
		if (orderDelivery) {
			// если orderDelivery определено, значит заказ был доставлен (а order заполнен всегда)
			const changedOrder =
				order.checkpointCurrent === 'СВ'
					? await deliveries.selfPickupOrderDelivered(order, orderDelivery)
					: await deliveries.orderDelivered(order, orderDelivery) // забран
			// если по заказ требуется вернуть возвратные документы
			if (changedOrder.returnDocumentsRequired) {
				alert('Не забудьте забрать ВДО по этому заказу')
				// на всякий случай получаем заборы, чтобы ВДО, если он ещё не пришёл в базу, точно пришёл
				await pickups.getFromServer()
				let orders = await deliveries.getLocal('orders')
				// если в базе есть ВДО, привязанный к этому заказу, то переходим к этому ВДО
				if (orders.find(order => order._id === changedOrder.vdo._id)) {
					// переходим к получению документа ВДО
					history.push(`/pickups/order/${changedOrder.vdo._id}`)
				} else {
					// иначе переходим к списку заказов
					history.push('/deliveries')
				}
			} else {
				history.push('/deliveries')
			}
			return

			// // если окно доставки было закрыто кнопокой Отмена,
			// //    то оно возвращает сам заказ (в котором могли измниться файлы и их нужно обновить)
			// if (orderDelivery.type === "order") {
			//   this.setState({ order: orderDelivery })
			// } else {
			//   await deliveries.orderDelivered(this.state.order, orderDelivery) // забран
			//   history.push("/deliveries")
			//   return
			// }
		} else {
			// заполнен только order, значит окно доставки было просто закрыто,
			//    значит просто обновляем заказ
			this.setState({ order })
		}
		this.setState({ deliveryWindowIsOpened: false })
	}

	handleClickCloseNotDeliveryWindow = async (orderNotDelivery, history) => {
		if (orderNotDelivery) {
			await deliveries.orderNotDelivered(this.state.order, orderNotDelivery) // НЕ забран
			history.push('/deliveries')
			return
		}
		this.setState({ notDeliveryWindowIsOpened: false })
	}

	handleProcessFile = (error, file) => {
		console.log(file)
		const fileFromServer = JSON.parse(file.serverId)

		const orderFiles = [...this.state.order.files, fileFromServer]
		this.setState({
			order: { ...this.state.order, files: orderFiles },
		})
	}

	handleCickCheckboxIsTransportDoc = async (file, checked) => {
		const orderFiles = [...this.state.order.files].map(orderFile => {
			if (orderFile._id === file._id)
				return { ...file, isTransportDoc: checked }
			else return orderFile
		})

		const _order = { ...this.state.order, files: orderFiles }
		this.setState({ order: _order })
		const success = await deliveries.patchFile(this.state.order, {
			...file,
			isTransportDoc: checked,
		})

		if (success) this.setAlert({ messageText: 'обновлено на сервере' })
	}

	showMessage = text => {
		this.setState({ messageIsVisible: true, messageText: text })
	}

	handleHideMessage = () => {
		this.setState({ messageIsVisible: false, messageText: '' })
	}

	getCommentToCurrentTerminal = (order, profile) => {
		if (!profile.terminal || !order.terminals) return
		const currentTerminal = order.terminals.find(
			terminal =>
				terminal.uid === profile.terminal.uid && terminal.type === 'Доставки'
		)
		return currentTerminal && currentTerminal.comment
	}

	render() {
		console.log()
		const { classes, history, profile } = this.props
		// const order = orders.find(order => order.number === orderNumber)
		const { order } = this.state

		if (!order) return null

		const commentToCurrentTerminal = this.getCommentToCurrentTerminal(
			order,
			profile
		)

		if (!order) return null
		if (Object.keys(order).length === 0) return null

		const consignee =
			order.nextTerminals.length > 0 ? order.nextTerminals[0] : order.consignee

		return (
			<>
				<Paper className={classes.root}>
					<Breadcrumbs aria-label='Breadcrumb'>
						<LinkRouter color='inherit' to='/deliveries'>
							Доставки
						</LinkRouter>
						{this.manifest ? (
							<LinkRouter
								color='inherit'
								to={`/deliveries/manifest/${this.manifest._id}`}
							>
								{`Манифест ${this.manifest.number}`}
							</LinkRouter>
						) : null}
						<Typography color='textPrimary' variant='h5'>
							Заказ <OrderNumber number={order.number} />
							{/* <strong>{`${order.number}`}</strong> */}
						</Typography>
					</Breadcrumbs>
				</Paper>

				{/* <h1>Заказ {order.number}</h1> */}

				<Paper className={classes.root}>
					<Grid container spacing={1}>
						{/* <Grid item xs={1}>
							<Typography variant='body1'><strong>ТН:</strong></Typography>
						</Grid>
						<Grid item xs={11}>
							<Typography variant='body1'>{order.invoiceNumber}</Typography>
						</Grid>						 */}
						{/* {descriptionLine('ТН', order.invoiceNumber)} */}
						<DescriptionLine caption='ТН' value={order.invoiceNumber} />
					</Grid>
				</Paper>

				<Paper className={classes.root}>
					<Typography variant='subtitle1'>Информация о получателе</Typography>
					<Grid container spacing={1}>
						<DescriptionLine caption='Получатель' value={consignee.name} />
						<DescriptionLine caption='Адрес' value={consignee.address} />
						<DescriptionLine caption='Контакт' value={consignee.contact} />
						<DescriptionLine caption='Телефон' value={consignee.phoneNumber} />
						{commentToCurrentTerminal && (
							<DescriptionLine
								caption='Комментарий'
								value={commentToCurrentTerminal}
							/>
						)}
						{/* {descriptionLine('Получатель', order.consignee)}
						{descriptionLine('Адрес', order.receiptPointAddress)}
						{descriptionLine('Контакт', order.receiptPointContact)}
						{descriptionLine('Телефон', order.receiptPointPhoneNumber)} */}
					</Grid>
					{/* <Typography variant='body1'><strong>Получатель</strong>{order.consignee}</Typography>
					<Typography variant='body1'><strong>Адрес</strong>{order.receiptPointAddress}</Typography>
					<Typography variant='body1'><strong>Контакт</strong>{order.receiptPointContact}</Typography>
					<Typography variant='body1'><strong>Телефон</strong>{order.receiptPointPhoneNumber}</Typography> */}
				</Paper>

				<Paper className={classes.root}>
					<Typography variant='subtitle1'>Информация о грузе</Typography>
					<Grid container spacing={1}>
						<DescriptionLine caption='Мест' value={order.totalPlaces} />
						<DescriptionLine caption='Вес' value={`${order.totalWeight} кг`} />
						<DescriptionLine
							caption='Объём'
							value={`${order.totalVolume} м3`}
						/>
						<DescriptionLine
							caption='Описание'
							value={order.contentDescription}
						/>
						{/* {descriptionLine('Мест', order.totalPlaces)}
						{descriptionLine('Вес', order.totalWeight)}
						{descriptionLine('Объём', order.totalVolume)}
						{descriptionLine('Описание', order.contentDescription)} */}
					</Grid>
					{/* <Typography variant='body1'><strong>Мест</strong>{order.totalPlaces}</Typography>
					<Typography variant='body1'><strong>Вес</strong>{order.totalWeight}</Typography>
					<Typography variant='body1'><strong>Объём</strong>{order.totalVolume}</Typography>
					<Typography variant='body1'><strong>Описание</strong>{order.contentDescription}</Typography> */}
				</Paper>

				{order.returnDocumentsRequired && order.nextTerminals.length === 0 && (
					<Paper className={classes.root}>
						<Typography variant='body1' color='error'>
							Не забудьте забрать заполненные документы для отправителя
						</Typography>
					</Paper>
				)}

				<FilesManipulation
					classes={classes}
					doc={order}
					handleProcessFile={this.handleProcessFile}
					handleCickCheckboxIsTransportDoc={
						this.handleCickCheckboxIsTransportDoc
					}
				/>

				<Message
					open={this.state.messageIsVisible}
					close={this.handleHideMessage}
					classes={classes}
					messageText={this.state.messageText}
				/>

				{/* {order.checkpointCurrent === 'ГС' ||
				order.checkpointCurrent === 'ЧС' ? (
					<Button
						variant='contained'
						color='primary'
						className={classes.button}
						onClick={this.handleClickTakeToDelivery}>
						Взять на доставку (ДН+КД)
					</Button>
				) : null} */}

				{/* {order.checkpointCurrent === 'КД' ? (
					<Button
						variant='contained'
						color='primary'
						className={classes.button}
						onClick={this.handleClickOpenDeliveryWindow}>
						Доставлено
					</Button>
				) : null} */}

				<DeliveryOrderButtons
					order={order}
					classes={classes}
					handleClickOpenDeliveryWindow={this.handleClickOpenDeliveryWindow}
					handleClickOpenNotDeliveryWindow={
						this.handleClickOpenNotDeliveryWindow
					}
					handleClickTakeToDelivery={this.handleClickTakeToDelivery}
					handleClickTansferToGS={this.handleClickTansferToGS}
					buttonsIsDisabled={this.state.buttonsIsDisabled}
				/>

				<OrderDeliveryDialog
					open={this.state.deliveryWindowIsOpened}
					close={({ order, orderDelivery }) =>
						this.handleClickCloseDeliveryWindow(
							{ order, orderDelivery },
							history
						)
					}
					order={order}
					classes={classes}
					// match={this.props.match}
				/>

				<OrderNotDeliveryDialog
					open={this.state.notDeliveryWindowIsOpened}
					close={orderNotDelivery =>
						this.handleClickCloseNotDeliveryWindow(orderNotDelivery, history)
					}
					order={order}
					classes={classes}
				/>
			</>
		)
	}
}

DeliveryOrder.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
	profile: state.profile,
})

export default connect(mapStateToProps, { setAlert })(
	compose(withStyles(styles), withRouter)(DeliveryOrder)
)
