// import {APP_NAME} from '../main'
import * as _ from 'lodash'
import * as LZString from 'lz-string'
import { format } from 'date-fns'

const COMPRESSED = true
const regExDateISO8601 =
	/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[-+]\d{2}:\d{2})/
let savedInitialValues

Date.prototype.toString = function dateToString() {
	// return `${this.name}`;
	return formatLocalDateISO8601(this)
}

function formatLocalDateISO8601(date) {
	let dif, pad, tzo
	tzo = -date.getTimezoneOffset()
	dif = tzo >= 0 ? '+' : '-'
	pad = function (num) {
		const norm = Math.abs(Math.floor(num))
		return (norm < 10 ? '0' : '') + norm
	}

	return (
		date.getFullYear() +
		'-' +
		pad(date.getMonth() + 1) +
		'-' +
		pad(date.getDate()) +
		'T' +
		pad(date.getHours()) +
		':' +
		pad(date.getMinutes()) +
		':' +
		pad(date.getSeconds()) +
		dif +
		pad(tzo / 60) +
		':' +
		pad(tzo % 60)
	)
	// return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
}

let worker

function getKeynme(key) {
	// if (key === 'currentUser') return key
	return key
}

export const Storage = {
	getData(type) {
		const keyName = getKeynme(type)
		if (typeof type === 'string')
			return Promise.resolve(
				this.fromJson(
					COMPRESSED
						? LZString.decompress(localStorage.getItem(keyName))
						: localStorage.getItem(keyName)
				)
			)
		else if (Array.isArray(type)) {
			return Promise.resolve(
				_.map(type, elem => {
					return this.fromJson(localStorage.getItem(keyName))
				})
			)
		} else return Promise.resolve(null)
	},

	setData(data) {
		const promises = []
		_.forOwn(data, (value, key) => {
			const keyName = getKeynme(key)

			if (!value) {
				// localStorage.removeItem(keyName)
				localStorage.setItem(
					keyName,
					COMPRESSED
						? LZString.compress(this.toJson(savedInitialValues[keyName]))
						: this.toJson(savedInitialValues[keyName])
				)
			} else {
				if (COMPRESSED && worker)
					worker.postMessage({ [key]: this.toJson(value) })
				else localStorage.setItem(this.toJson(value))
			}
			promises.push(Promise.resolve())
		})

		return Promise.all(promises)
	},

	formatLocalDateISO8601(date) {
		let dif, pad, tzo
		tzo = -date.getTimezoneOffset()
		dif = tzo >= 0 ? '+' : '-'
		pad = function (num) {
			const norm = Math.abs(Math.floor(num))
			return (norm < 10 ? '0' : '') + norm
		}

		return (
			date.getFullYear() +
			'-' +
			pad(date.getMonth() + 1) +
			'-' +
			pad(date.getDate()) +
			'T' +
			pad(date.getHours()) +
			':' +
			pad(date.getMinutes()) +
			':' +
			pad(date.getSeconds()) +
			dif +
			pad(tzo / 60) +
			':' +
			pad(tzo % 60)
		)
		// return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
	},

	toJson(obj) {
		return JSON.stringify(obj)
	},

	fromJson(str) {
		try {
			return JSON.parse(str, (key, value) => {
				if (regExDateISO8601.test(value)) {
					return new Date(value)
				}
				return value
			})
		} catch (e) {
			console.error(e)
			return null
		}
	},

	init(initialValues, webWorker) {
		worker = webWorker
		worker.onmessage = $event => {
			if ($event && $event.data) {
				// console.log('storage from WORKER', $event.data)
				for (const key in $event.data) {
					if ($event.data.hasOwnProperty(key)) {
						localStorage.setItem(key, $event.data[key])
					}
				}
			}
		}

		savedInitialValues = initialValues
		const promises = []

		_.forOwn(initialValues, (value, key) => {
			const promise = this.getData(key).then(data => {
				if (!data) {
					return this.setData({ [key]: value })
				} else return null
			})
			promises.push(promise)
		})

		return Promise.all(promises)
	},

	hasOnlyInitialData(initialData) {
		let dataIsEmpty = true
		_.forOwn(initialData, (value, key) => {
			if (!_.isEmpty(this.getData(key))) dataIsEmpty = false
		})

		return dataIsEmpty
	},

	async clear() {
		await this.setData(savedInitialValues)
	},
}
