import React, { useState, useEffect, useRef } from 'react'

import {
	Card,
	CardContent,
	CardActions,
	CardActionArea,
	Box,
	Grid,
	Typography,
	makeStyles,
	Checkbox,
	TextField,
	Select,
	ListItem,
	ListItemText,
} from '@material-ui/core'

const ListHeader = ({ caption, type, filter, handleFilterChange }) => {
	const [selectedSortField, setSelectedSortField] = useState('number')
	const handleSortFieldChange = event => {
		// const _sortedCompleted = [...sortedCompleted]
		// _sortedCompleted.filter
		const _sortField = event.target.value
		setSelectedSortField(_sortField)
		// setVisibleDocs(getVisibleDocs(docs, _sortField, sortDirection, filter))
	}
	return (
		<Grid container justify='space-between'>
			<Grid item>
				<h3>{caption}</h3>
			</Grid>

			<Grid item>
				<Select
					native
					value={selectedSortField}
					// onChange={handleSortFieldChange}
					style={{ marginTop: '16px' }}
					inputProps={{ style: { fontSize: '14px' } }}
				>
					{/* {sortFields.map(sortField => (
						<option value={sortField.id}>{sortField.label}</option>
					))} */}
				</Select>
			</Grid>

			<Grid item>
				<TextField
					label='фильтр'
					type='search'
					// className={classes.textField}
					value={filter}
					onChange={event => handleFilterChange(event, type)}
				/>
			</Grid>
		</Grid>
	)
}

export default ListHeader
