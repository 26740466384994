import React from 'react'
import axios from 'axios'

import {
	BrowserRouter as Router,
	// Route,
	// Link,
	// Redirect,
} from 'react-router-dom'
// import { CookiesProvider } from 'react-cookie'

import { Provider } from 'react-redux'
import store from './store'

import Dashboard from './components/Dashboard'

import { Storage } from './services/storage'

import './services/decimal-rounding'

function App() {
	async function initStorage() {
		const worker = new Worker('webWorker.js')
		await Storage.init(
			{
				currentUser: {},
				profile: {},
				statuses: [],
				orders: [],
				manifests: [],
				bills: [],
			},
			worker
		)
	}

	initStorage()

	axios.defaults.transformResponse = function (data) {
		if (data === 'Unauthorized') return null
		return Storage.fromJson(data)
	}

	axios.defaults.transformRequest = function (data) {
		return Storage.toJson(data)
	}

	axios.defaults.headers.put['Content-Type'] = 'application/json'
	axios.defaults.headers.post['Content-Type'] = 'application/json'
	axios.defaults.headers.patch['Content-Type'] = 'application/json'

	// Add a response interceptor
	axios.interceptors.response.use(
		function (response) {
			// Do something with response data
			return response
		},
		function (error) {
			// Do something with response error
			// return Promise.reject(error)
			if (
				error.response &&
				error.response.status === 401 &&
				!/set-user/.test(window.location.pathname)
			) {
				error.redirectTo = '/login'
			}
			return Promise.reject(error)
		}
	)

	return (
		// <CookiesProvider>
		<Provider store={store}>
			<Router>
				<Dashboard />
			</Router>
		</Provider>
		// </CookiesProvider>
	)
}

export default App
