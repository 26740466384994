import React, { useState } from 'react'
// import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
// import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
// import Link from '@material-ui/core/Link'
import Message from '../components/Message'

import { makeStyles } from '@material-ui/core/styles'
// import PropTypes from 'prop-types'
// import { withStyles } from '@material-ui/core/styles'

import users from '../fetchers/users'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

function SetUser({
  match: {
    params: { userId }
  },
  history
}) {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [openSnackbar, setSnackbarVisibility] = useState(false)

  const handleSubmitForm = async event => {
    event.preventDefault()
    const success = await updateUser(userId, name, password)
    if (success) history.push(`/profile`)
  }

  const handleCloseSnackbar = () => setSnackbarVisibility(false)

  const updateUser = async (userId, name, password) => {
    const updatedUser = await users.updateUser({ _id: userId, name, password })
    if (updatedUser.status === 409) {
      setSnackbarVisibility(true)
      return
    }
    const user = await users.authenticate({ name, password })
    await users.updateCurrent(user)
    return true
  }
  return (
    <>
      <Container component='main' maxWidth='xs'>
        <div className={classes.paper}>
          <Typography component='h1' variant='h5'>
            Задайте имя пользователя и пароль
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmitForm}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              label='Имя пользователя'
              name='name'
              autoFocus
              value={name}
              onChange={event => setName(event.target.value)}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Пароль'
              type='password'
              autoComplete='current-password'
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
            {/* <FormControlLabel
						control={<Checkbox value="remember" color="primary" />}
						label="Remember me"
					/> */}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              Войти
            </Button>
          </form>
        </div>

        <Message
          open={openSnackbar}
          close={handleCloseSnackbar}
          classes={classes}
          messageText={`Пользователь и именем ${name} уже заведён. Установите этому пользователю другое имя`}
        />
      </Container>
    </>
  )
}

export default withRouter(SetUser)
