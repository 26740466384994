import React from 'react'
import {
  Card,
  CardContent,
  CardActionArea,
  Box,
  Typography,
  makeStyles
} from '@material-ui/core'
import ManifestNumber from './ManifestNumber'
import OrderNumber from './OrderNumber'

const useStyles = makeStyles({
  gridFullWidth: {
    width: '100%'
  },
  card: {
    flex: 1
  }
})

export default function PickupManifestOrderCard({
  classes: parentClasses,
  doc: order,
  handleClick
}) {
  const classes = { ...parentClasses, ...useStyles() }

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={event => handleClick(event, order)}>
        <CardContent>
          <Typography variant='body1'>
            <OrderNumber number={order.number} />
            {`${order.invoiceNumber ? ' (' + order.invoiceNumber + ')' : ''}`}
          </Typography>
          <Typography
            className={classes.title}
            color='textSecondary'
            variant='body2'
            component='span'
            gutterBottom
          >
            {order.receiptPointAddress}
          </Typography>

          {/* <Typography variant='body2'> */}
          <Box display='flex' justifyContent='space-between'>
            <span>{`мест: ${order.totalPlaces}, вес: ${order.totalWeight}, объём: ${order.totalVolume} `}</span>
            <span>{order.specialConditions}</span>
          </Box>
          {/* </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
