import React, { Component } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
// import classNames from 'classnames'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
// import ToggleButton from '@material-ui/lab/ToggleButton'
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Grid from '@material-ui/core/Grid'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
// import Button from '@material-ui/core/Button'
// import Fab from '@material-ui/core/Fab'
import Link from '@material-ui/core/Link'

import DescriptionLine from '../components/DescriptionLine'

import deliveries from '../fetchers/deliveries'
import completed from '../fetchers/completed'
// import OrderDeliveryDialog from './OrderDeliveryDialog'
// import OrderNotDeliveryDialog from './OrderNotDeliveryDialog'
import FilesManipulation from '../components/FilesManipulation'
import Message from '../components/Message'

import { setAlert } from '../actions/alert'

const styles = (theme) => ({
	root: {
		...theme.mixins.gutters(),
		width: '100%',
		overflowX: 'auto',
		// paddingTop: theme.spacing.unit * 2,
		// paddingBottom: theme.spacing.unit * 2,
		padding: 20,
		// margin: 20,
		marginTop: 20,
		marginBottom: 20,
	},
	table: {
		minWidth: 700,
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		margin: theme.spacing(1),
	},
	fab2: { right: '159px' },
})

const LinkRouter = (props) => <Link {...props} component={RouterLink} />

class DeliveryOrder extends Component {
	state = {
		deliveryWindowIsOpened: false,
		notDeliveryWindowIsOpened: false,
		// orders: [],
		order: {},
		messageIsVisible: false,
		messageText: '',
	}
	setAlert = this.props.setAlert

	manifestNumber = this.props.match.params.manifestNumber

	// взять на доставку
	handleClickTakeToDelivery = async () => {
		const changedOrder = await deliveries.takeToDelivery(
			this.state.order,
			'Взят на доставку'
		) // назначен на доставку
		this.setState({ order: changedOrder })
	}

	handleClickOpenDeliveryWindow = () => {
		this.setState({ deliveryWindowIsOpened: true })
	}

	handleClickOpenNotDeliveryWindow = () => {
		this.setState({ notDeliveryWindowIsOpened: true })
	}

	handleClickCloseDeliveryWindow = async (orderDelivery, history) => {
		if (orderDelivery) {
			await deliveries.orderDelivered(this.state.order, orderDelivery) // забран
			history.push('/deliveries')
			return
		}
		this.setState({ deliveryWindowIsOpened: false })
	}

	handleClickCloseNotDeliveryWindow = async (orderNotDelivery, history) => {
		if (orderNotDelivery) {
			await deliveries.orderNotDelivered(this.state.order, orderNotDelivery) // НЕ забран
			history.push('/deliveries')
			return
		}
		this.setState({ notDeliveryWindowIsOpened: false })
	}

	async componentDidMount() {
		// let orders = await deliveries.getLocal('orders')
		// const { orderId } = this.props.match.params
		// const order = orders.find(order => order._id === orderId)
		// this.setState({ order })

		const { orderId } = this.props.match.params
		const order = await completed.getCompletedOrderFromServer(orderId)
		this.setState({ order })
	}

	handleProcessFile = (error, file) => {
		console.log(file)
		const fileFromServer = JSON.parse(file.serverId)

		const orderFiles = [...this.state.order.files, fileFromServer]
		this.setState({
			order: { ...this.state.order, files: orderFiles },
		})
	}

	handleCickCheckboxIsTransportDoc = async (file, checked) => {
		const orderFiles = [...this.state.order.files].map((orderFile) => {
			if (orderFile._id === file._id)
				return { ...file, isTransportDoc: checked }
			else return orderFile
		})

		const _order = { ...this.state.order, files: orderFiles }
		this.setState({ order: _order })
		const success = await deliveries.patchFile(this.state.order, {
			...file,
			isTransportDoc: checked,
		})

		if (success) this.setAlert({ messageText: 'обновлено на сервере' })
	}

	render() {
		console.log()
		const { classes } = this.props
		const { order } = this.state

		if (!order) return null
		if (Object.keys(order).length === 0) return null

		const consignee =
			order.nextTerminals.length > 0 ? order.nextTerminals[0] : order.consignee

		return (
			<>
				<Paper className={classes.root}>
					<Breadcrumbs aria-label='Breadcrumb'>
						<LinkRouter color='inherit' to='/completed'>
							История
						</LinkRouter>
						{this.manifestNumber ? (
							<LinkRouter
								color='inherit'
								to={`/deliveries/manifest/${this.manifestNumber}`}
							>
								{`Манифест ${this.manifestNumber}`}
							</LinkRouter>
						) : null}
						<Typography color='textPrimary' variant='h5'>
							Заказ <strong>{`${order.number}`}</strong>
						</Typography>
					</Breadcrumbs>
				</Paper>

				{/* <h1>Заказ {order.number}</h1> */}

				<Paper className={classes.root}>
					<Grid container spacing={1}>
						{/* <Grid item xs={1}>
							<Typography variant='body1'><strong>ТН:</strong></Typography>
						</Grid>
						<Grid item xs={11}>
							<Typography variant='body1'>{order.invoiceNumber}</Typography>
						</Grid>						 */}
						{/* {descriptionLine('ТН', order.invoiceNumber)} */}
						<DescriptionLine caption='ТН' value={order.invoiceNumber} />
					</Grid>
				</Paper>

				<Paper className={classes.root}>
					<Typography variant='subtitle1'>Информация о получателе</Typography>
					<Grid container spacing={1}>
						<DescriptionLine caption='Получатель' value={consignee.name} />
						<DescriptionLine caption='Адрес' value={consignee.address} />
						<DescriptionLine caption='Контакт' value={consignee.contact} />
						<DescriptionLine caption='Телефон' value={consignee.phoneNumber} />
						{/* {descriptionLine('Получатель', order.consignee)}
						{descriptionLine('Адрес', order.receiptPointAddress)}
						{descriptionLine('Контакт', order.receiptPointContact)}
						{descriptionLine('Телефон', order.receiptPointPhoneNumber)} */}
					</Grid>
					{/* <Typography variant='body1'><strong>Получатель</strong>{order.consignee}</Typography>
					<Typography variant='body1'><strong>Адрес</strong>{order.receiptPointAddress}</Typography>
					<Typography variant='body1'><strong>Контакт</strong>{order.receiptPointContact}</Typography>
					<Typography variant='body1'><strong>Телефон</strong>{order.receiptPointPhoneNumber}</Typography> */}
				</Paper>

				<Paper className={classes.root}>
					<Typography variant='subtitle1'>Информация о грузе</Typography>
					<Grid container spacing={1}>
						<DescriptionLine caption='Мест' value={order.totalPlaces} />
						<DescriptionLine caption='Вес' value={order.totalWeight} />
						<DescriptionLine caption='Объём' value={order.totalVolume} />
						<DescriptionLine
							caption='Описание'
							value={order.contentDescription}
						/>
						{/* {descriptionLine('Мест', order.totalPlaces)}
						{descriptionLine('Вес', order.totalWeight)}
						{descriptionLine('Объём', order.totalVolume)}
						{descriptionLine('Описание', order.contentDescription)} */}
					</Grid>
					{/* <Typography variant='body1'><strong>Мест</strong>{order.totalPlaces}</Typography>
					<Typography variant='body1'><strong>Вес</strong>{order.totalWeight}</Typography>
					<Typography variant='body1'><strong>Объём</strong>{order.totalVolume}</Typography>
					<Typography variant='body1'><strong>Описание</strong>{order.contentDescription}</Typography> */}
				</Paper>

				<FilesManipulation
					classes={classes}
					doc={order}
					handleProcessFile={this.handleProcessFile}
					handleCickCheckboxIsTransportDoc={
						this.handleCickCheckboxIsTransportDoc
					}
				/>

				<Message
					open={this.state.messageIsVisible}
					close={this.handleHideMessage}
					classes={classes}
					messageText={this.state.messageText}
				/>
			</>
		)
	}
}

DeliveryOrder.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default connect(undefined, { setAlert })(
	compose(withStyles(styles), withRouter)(DeliveryOrder)
)
