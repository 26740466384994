import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  muted: {
    color: 'rgba(0, 0, 0, 0.5)'
  },
  spaceAfter: {
    '&:after': {
      content: '" "'
    },
    wordSpacing: '-0.1em',
    letterSpacing: '-0.01em'
    // color: 'gray'
  }
})

export default function OrderNumber({ number }) {
  const classes = useStyles()

  const year = number.substring(0, 4)
  const month = number.substring(4, 6)
  const day = number.substring(6, 8)
  const indexOfSlash = number.indexOf('/')
  const lastSymbol = indexOfSlash > -1 ? indexOfSlash - 1 : number.length
  const numberInDay = number.substring(8, lastSymbol - 3)
  const postfix = number.substring(lastSymbol - 3, lastSymbol)
  const subNumber = indexOfSlash > -1 ? number.substring(indexOfSlash) : ''

  return (
    <span>
      {/* {number} */}
      <span className={classes.spaceAfter}>{year}</span>
      <span className={classes.spaceAfter}>{month}</span>
      <span className={classes.spaceAfter}>{day}</span>
      <strong className={classes.spaceAfter}>{numberInDay}</strong>
      <i>{postfix}</i>
      <strong>{subNumber}</strong>
    </span>
  )
}
