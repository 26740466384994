import React, { useState, useEffect, useCallback } from 'react'
import compose from 'recompose/compose'
// import format from 'date-fns/format'
// import { orderBy as _orderBy } from 'lodash'

// import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

// Import React FilePond
// import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// import { useTheme } from '@material-ui/core/styles'

import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'

import { withRouter } from 'react-router-dom'

import completedFetcher from '../fetchers/completed'
import billing from '../fetchers/billing'
import CreateBillDialog from './CreateBillDialog'
import CompletedTable from './CompletedTable'
import Message from '../components/Message'

const styles = theme => ({
	root: {
		width: '100%',
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
	pointerRow: {
		cursor: 'pointer',
	},
	tableTitle: {
		paddingLeft: 20,
		paddingTop: 20,
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	h1: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
})

function Completed(props) {
	const { classes, history } = props

	const [completed, setCompleted] = useState([])

	const [selectedDocs, setSelectedDocs] = useState([])
	// const [selectedCount, setSelectedCount] = useState(0)
	// const [rowCount, setRowCount] = useState(0)

	const [createBillWindowIsOpened, setBillWindowVisibility] = useState(false)
	// const [billFiles, setBillFiles] = useState([]);
	// const [billComments, setBillComments] = useState('')
	const [createdBill, setCreatedBill] = useState(false)
	const [openSnackbar, setOpenSnackbar] = useState(false)

	// let pond

	// const theme = useTheme()
	// const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'));
	// const fullScreenDialog = true
	const fetchData = async () => {
		let docs
		// docs = await completedFetcher.getLocal()
		// setCompleted(docs.orders.concat(docs.manifests))

		docs = await completedFetcher.getFromServer()
		if (docs.redirectTo) return history.push(docs.redirectTo)
		if (docs) setCompleted(docs.orders.concat(docs.manifests))
	}

	useEffect(() => {
		console.log('UseEffect')
		fetchData()
	}, [false])

	const placeCheckedDocsToSelected = checkedDocs => {
		// const _selectedDocs = checkedDocs
		// 	.filter(checkedDoc => checkedDoc.isChecked)
		// 	.map(checkedDoc =>
		// 		completed.find(
		// 			completedLine =>
		// 				checkedDoc.type === completedLine.type &&
		// 				checkedDoc.uid === completedLine.uid
		// 		)
		// 	)
		// setSelectedDocs(_selectedDocs)
		setSelectedDocs(checkedDocs)
	}

	const closeBillWindow = async bill => {
		if (bill) {
			const newBill = await billing.createBill(bill)
			setCreatedBill(newBill)
			setOpenSnackbar(true)
			setSelectedDocs([])
			fetchData()
		}
		setBillWindowVisibility(false)
	}
	const handleClickToBill = () => {
		setBillWindowVisibility(true)

		// const checkedTasks = completed.filter(completedTask => completedTask.isChecked)
		// console.log()
		// console.log()
		// billing.createBill (checkedTasks)
	}

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setOpenSnackbar(false)
	}

	// const handleInit = () => {
	// 	console.log('FilePond instance has initialised', pond)
	// }

	return (
		<>
			<h1 className={classes.h1}>История работ</h1>

			<CompletedTable
				array={completed}
				classes={classes}
				setSelectedDocs={placeCheckedDocsToSelected}
			/>

			{/* <Button
				variant='contained'
				color='primary'
				className={classes.button}
				onClick={handleClickToBill}>
				Выставить
			</Button> */}

			{selectedDocs.length > 0 ? (
				<Fab
					variant='extended'
					aria-label='delete'
					color='primary'
					className={classes.fab}
					onClick={handleClickToBill}
				>
					{/* <AddIcon className={classes.extendedIcon} /> */}
					Выставить счёт
				</Fab>
			) : null}

			<CreateBillDialog
				open={createBillWindowIsOpened}
				close={closeBillWindow}
				completed={selectedDocs}
			/>

			<Message
				open={openSnackbar}
				close={handleCloseSnackbar}
				classes={classes}
				messageText={`Создан новый счёт ${createdBill && createdBill.number}`}
				buttonText='Перейти'
				buttonAction={() => history.push('/billing')}
			/>
		</>
	)
}

Completed.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default compose(withStyles(styles), withRouter)(Completed)
