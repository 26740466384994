import axios from 'axios'
import { Storage } from '../services/storage'
import { maxBy } from 'lodash'
import { format, isWithinInterval, startOfDay, endOfDay } from 'date-fns'

import documentsFetcher from './documents'

export default {
	async getFromServer() {
		const dataFromServer = await documentsFetcher.getFromServer()
		if (!dataFromServer) return
		if (dataFromServer && dataFromServer.redirectTo) return dataFromServer

		const { statuses, orders, manifests } = dataFromServer
		return this.getDocs(statuses, orders, manifests)
	},

	updateLocal(documentsType, documents) {
		documentsFetcher.updateLocal(documentsType, documents)
	},

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	},

	getLocalStatuses() {
		return Storage.getData(`statuses`)
	},

	async getLocal(documentsType) {
		if (documentsType) {
			return await documentsFetcher.getLocal(documentsType)
		} else {
			const { statuses, orders, manifests } = await documentsFetcher.getLocal()
			return this.getDocs(statuses, orders, manifests)
		}
	},

	getDocs(statuses, orders, manifests) {
		const docs = {
			orders: statuses
				.filter(
					docStatus =>
						docStatus.type === 'order' &&
						(docStatus.status === 'Забор' || docStatus.status === 'Забор в МЛ')
				)
				.map(docStatus =>
					orders.find(
						order =>
							order.type === 'order' &&
							((order.uid &&
								docStatus.documentUid &&
								order.uid === docStatus.documentUid) ||
								(order._id &&
									docStatus.documentId &&
									order._id === docStatus.documentId))
					)
				)
				.filter(order => !!order),
			manifests: statuses
				.filter(
					docStatus =>
						docStatus.type === 'manifest' && docStatus.status === 'Забор'
				)
				.map(docStatus =>
					manifests.find(
						manifest =>
							manifest.type === 'manifest' &&
							((manifest.uid &&
								docStatus.documentUid &&
								manifest.uid === docStatus.documentUid) ||
								(manifest._id &&
									docStatus.documentId &&
									manifest._id === docStatus.documentId))
					)
				)
				.filter(manifest => !!manifest),
		}

		return docs
	},

	// async updateUser (user) {
	// 	const headers = {
	// 		'Content-Type': 'application/json'
	// 	}
	// 	const res = await axios.put('/api/user', Storage.toJson(user), {headers})
	// 	return res.data
	// }

	async getNewManifestNumber(date, terminalDestitationCode) {
		if (!date || !terminalDestitationCode) return ''

		const manifests = await Storage.getData('manifests')
		// const currentUser = await Storage.getData('currentUser')
		const profile = await Storage.getData('profile')
		const terminalSourceCode = profile.terminal.code

		// манифесты на дату с тем же постфиксом в номере 20191015001->ACSKJA<-
		const postfixOfNumber = `${this.stringWithoutNumbers(terminalSourceCode) +
			this.stringWithoutNumbers(terminalDestitationCode)}`
		const manifestsOnDate = manifests.filter(
			manifest =>
				isWithinInterval(manifest.date, {
					start: startOfDay(date),
					end: endOfDay(date),
				}) && manifest.number.includes(postfixOfNumber)
		)

		if (manifestsOnDate.length === 0)
			return this.formNumber(
				date,
				'001',
				terminalSourceCode,
				terminalDestitationCode
			)

		const maximalNumberManifest = maxBy(manifestsOnDate, 'number')
		const maxNumber = maximalNumberManifest.number.substring(8, 11)
		if (!maxNumber) {
			return this.formNumber(
				date,
				'001',
				terminalSourceCode,
				terminalDestitationCode
			)
		}
		let maxNumberInt = parseInt(maxNumber, 10)
		maxNumberInt++
		const newNumberStr = ('' + maxNumberInt).padStart(3, '0')
		return this.formNumber(
			date,
			newNumberStr,
			terminalSourceCode,
			terminalDestitationCode
		)
	},

	formNumber(date, number, terminalSourceCode, terminalDestitationCode) {
		return `${format(date, 'yyyyMMdd')}${number}${this.stringWithoutNumbers(
			terminalSourceCode
		)}${this.stringWithoutNumbers(terminalDestitationCode)}`
	},

	stringWithoutNumbers(string) {
		return string.replace(/[0-9]/g, '').trim()
	},

	async getTerminalsFromServer() {
		try {
			// const currentUser = await Storage.getData('currentUser')
			const res = await axios.get('/api/terminals')
			return res.data
		} catch (error) {
			if (error.response && error.response.status === 404) return
		}
	},

	async getCarriersFromServer() {
		try {
			const currentUser = await Storage.getData('currentUser')
			const res = await axios.get('/api/carriers', {
				params: { terminalUid: currentUser.terminalUid },
			})
			return res.data
		} catch (error) {
			if (error.response && error.response.status === 404) return
		}
	},

	async shipManifest(manifest, comment, setAlert) {
		const profile = await Storage.getData('profile')
		manifest = {
			...manifest,
			date: manifest.transferToCarrierDateFact,
			transferToCarrierDatePlan: manifest.transferToCarrierDateFact,
			// transferToCarrierDateFact: manifest.transferToCarrierDateFact,
			arrivalDatePlan: manifest.arrivalDateFact,
			consignorTerminal: profile.terminal,
			noteForLetter: manifest.comment,
		}

		// await documentsFetcher.updateManifestLocal({ manifest })

		const trackingNotes = ''
		const trackingDate = manifest.transferToCarrierDateFact
		const checkpoints = ['МЧ', 'МС', 'МП', 'МГ', 'МЭ', 'МО']
		const changedManifestAndStatus = await documentsFetcher.putManifest(
			manifest,
			comment,
			checkpoints,
			trackingNotes,
			trackingDate
		)
		if (changedManifestAndStatus && !changedManifestAndStatus.error) {
			await documentsFetcher.updateManifestLocal(changedManifestAndStatus)
			return true
		} else {
			if (!changedManifestAndStatus) {
				setAlert({
					messageText:
						'Манифест не создан. Попробуйте повторить операцию позднее',
				})
			} else if (changedManifestAndStatus.error) {
				setAlert({
					messageText: changedManifestAndStatus.error.message,
				})
			}
		}
	},

	async takeToWork(order, comment) {
		const checkpoints = ['ЗП']

		const changedOrderAndStatus = await this.putOrder({
			order,
			comment,
			checkpoints,
		})
		this.updateOrderLocal(changedOrderAndStatus)
		return changedOrderAndStatus.order
	},

	async updateOrderLocal({ order, status }) {
		documentsFetcher.updateOrderLocal({ order, status })
	},

	async putOrder({ order, comment, checkpoints, trackingNotes, trackingDate }) {
		return await documentsFetcher.putOrder({
			order,
			comment,
			checkpoints,
			trackingNotes,
			trackingDate,
		})
	},

	onDialogFieldChange(event, obj) {
		return documentsFetcher.onDialogFieldChange(event, obj)
	},

	async pickedUp(order, orderReception) {
		order = {
			...order,
			pickedUpPlacesByDriver: orderReception.places,
			pickedUpPlacesByWarehouse: orderReception.places,
			totalPlaces: orderReception.places,
			totalWeight: orderReception.weight,
			totalVolume: 0,
			pickupComment: orderReception.comment,
			invoiceNumber: orderReception.invoiceNumber,
		}
		order.packageDescription = [
			{
				description: order.contentDescription,
				places: orderReception.places,
				totalWeight: orderReception.weight,
				type: 'Факт',
			},
		]
		const comment =
			`${format(orderReception.date, 'dd.MM.yyyy HH:mm')}` +
			` у ${orderReception.recievedBy} водитель забрал ` +
			`${orderReception.places} мест, вес ${orderReception.weight} кг.`
		const checkpoints = ['ГЗ', 'ТО', 'ГС']
		const trackingNotes = orderReception.recievedBy
		const trackingDate = orderReception.date

		const changedOrderAndStatus = await this.putOrder({
			order,
			comment,
			checkpoints,
			trackingNotes,
			trackingDate,
		})
		documentsFetcher.updateOrderLocal(changedOrderAndStatus)
	},

	async patchFile(manifest, file) {
		return await documentsFetcher.patchFile(manifest, file)
	},

	async orderNotPickedUp(order, orderNotReception) {
		const comment =
			`${format(orderNotReception.date, 'dd.MM.yyyy HH:mm')}` +
			` Причина: "${orderNotReception.reason}"` +
			` ${orderNotReception.comment && '(' + orderNotReception.comment + ')'}`
		const checkpoints = ['НВ']

		const changedOrderAndStatus = await this.putOrder({
			order,
			comment,
			checkpoints,
		})
		documentsFetcher.updateOrderLocal(changedOrderAndStatus)
	},
}
