import axios from 'axios'
import { Storage } from '../services/storage'

export default {
	async getFromServer() {
		try {
			// const currentUser = await Storage.getData('currentUser')
			const res = await axios.get('/api/profile')
			return res.data
		} catch (error) {
			if (error.response.status === 404) return
			if (error.response.status === 401) return { redirectTo: error.redirectTo }
		}
	},

	updateLocal(profile) {
		return Storage.setData({ profile })
	},

	getLocal() {
		return Storage.getData('profile')
	},

	async updateOnServer(profile) {
		const res = await axios.put('/api/profile', profile)
		const _profile = res.data
		await this.updateLocal(_profile)
		return _profile
	},
}
