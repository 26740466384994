import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGOUT_SUCCESS,
} from '../actions/types'

const initialState = {
	isAuthenticated: null,
	loading: true,
	currentUser: null,
}

export default function (state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		case REGISTER_SUCCESS:
			const currentUser = payload.currentUser
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				currentUser,
			}

		case REGISTER_FAIL:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				currentUser: null,
			}

		case LOGOUT_SUCCESS:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				currentUser: null,
			}

		default:
			return state
	}
}
