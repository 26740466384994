import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

import { amber, green } from '@material-ui/core/colors'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { removeAlert } from '../actions/alert'

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
}

const useStyles1 = makeStyles(theme => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}))

function Messages({ messages, removeAlert, classes: parentClasses }) {
	const handleCloseSnackbar = alert => {
		removeAlert(alert)
	}

	const classes = { ...parentClasses, ...useStyles1() }

	if (!messages) return null

	return messages.map(alert => {
		const {
			open,
			close,
			// classes,
			messageText,
			buttonText,
			buttonAction,
			variant,
		} = alert

		const Icon = variantIcon[variant]

		let buttonWithAction
		if (buttonText && buttonAction) {
			buttonWithAction = (
				<Button
					key='undo'
					color='secondary'
					size='small'
					onClick={buttonAction}>
					{buttonText}
				</Button>
			)
		}

		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={open}
				autoHideDuration={3000}
				onClose={() => handleCloseSnackbar(alert)}
				ContentProps={{
					'aria-describedby': 'alert-id',
				}}
				message={<span id='alert-id'>{messageText}</span>}
				action={[
					buttonWithAction,
					<IconButton
						key='close'
						aria-label='Close'
						color='inherit'
						className={classes.close}
						onClick={() => handleCloseSnackbar(alert)}>
						<CloseIcon />
					</IconButton>,
				]}
			/>
		)
	})
}

Messages.propTypes = {}

const mapStateToProps = state => ({
	messages: state.alert.messages,
})

export default connect(
	mapStateToProps,
	{ removeAlert }
)(Messages)
