import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
// import { orderBy } from 'lodash'
// import classNames from 'classnames/bind'
import compose from 'recompose/compose'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import SortableTable from '../components/SortableTable'

const styles = theme => ({
	// root: {
	// 	width: '100%',
	// 	overflowX: 'auto',
	// },
	// table: {
	// 	minWidth: 700,
	// },
	// pointerRow: {
	// 	cursor: 'pointer',
	// },
	// tableTitle: {
	// 	paddingLeft: 20,
	// 	paddingTop: 20,
	// },
	// compactTableCell: {
	// 	padding: '4px 20px 4px 20px',
	// },
	// visuallyHidden: {
	// 	border: 0,
	// 	clip: 'rect(0 0 0 0)',
	// 	height: 1,
	// 	margin: -1,
	// 	overflow: 'hidden',
	// 	padding: 0,
	// 	position: 'absolute',
	// 	top: 20,
	// 	width: 1,
	// },
	// borderBottomNone: {
	// 	borderBottom: 0,
	// },
	// lowBottomCell: {
	// 	paddingBottom: 0,
	// },
	// lowTopCell: {
	// 	paddingTop: 0,
	// },
	// stickyHeader: {
	// 	// backgroundColor: '#fff',
	// 	backgroundColor: theme.palette.background.default,
	// 	position: 'sticky',
	// 	[theme.breakpoints.up('xs')]: {
	// 		top: '32px',
	// 	},
	// 	[theme.breakpoints.up('sm')]: {
	// 		top: '40px',
	// 	},
	// },
	// stickyHeader_line2: {
	// 	// backgroundColor: '#fff',
	// 	backgroundColor: theme.palette.background.default,
	// 	position: 'sticky',
	// 	[theme.breakpoints.up('xs')]: {
	// 		top: '58px',
	// 	},
	// 	[theme.breakpoints.up('sm')]: {
	// 		top: '66px',
	// 	},
	// },
	// zeroPadding: { padding: 0 },
})

function CompletedTable({ array, classes, history, setSelectedDocs }) {
	// const [filter, setFilter] = useState('')
	// const [sortСolumn, setSortСolumn] = useState('number')
	// const [sortDirection, setSortDirection] = useState('desc')
	const [arrayRepresentation, setArrayRepresentation] = useState([])
	// const [visibleArrayRepresentation, setVisibleArrayRepresentation] = useState(
	// 	[]
	// )
	const documentTypeRepresentation = {
		order: 'Заказ',
		manifest: 'Манифест',
	}

	useEffect(() => {
		const _arrayRepresentation = array.map((element, index) => {
			if (!element) console.log(element, index)

			return {
				checkpointDate:
					element.checkpointDate &&
					format(element.checkpointDate, 'dd.MM.yyyy HH:mm'),
				type: element.type,
				// uid: element.uid,
				_id: element._id,
				typeRepresentation: documentTypeRepresentation[element.type],
				number: element.number,
				uid: element.uid,
				invoiceNumber: element.invoiceNumber,
				consigneeName: element.consignee.name,
				consigneeAddress: element.consignee.address,
			}
		})

		setArrayRepresentation(_arrayRepresentation)
		// setVisibleArrayRepresentation(
		// 	getArrayRepresentation(
		// 		_arrayRepresentation,
		// 		sortСolumn,
		// 		sortDirection,
		// 		filter
		// 	)
		// )
	}, [array])

	const headerRows = [
		[
			{
				label: '',
				padding: 'checkbox',
				isCheckbox: true,
			},
			{
				id: 'checkpointDate',
				label: 'Завершение',
			},
			{
				id: 'typeRepresentation',
				label: 'Тип',
			},
			{
				id: 'number',
				label: 'Номер',
			},
			{
				id: 'invoiceNumber',
				label: 'Накладная',
			},
		],
		[
			{
				id: 'consigneeName',
				label: 'Получатель',
				colSpan: '2',
			},
			{
				id: 'consigneeAddress',
				label: 'Адрес',
				colSpan: '3',
			},
		],
	]

	// const headFirstLineClass = classNames(
	// 	classes.borderBottomNone,
	// 	classes.lowBottomCell,
	// 	classes.stickyHeader
	// )

	// const headLastLineClass = classNames(
	// 	classes.stickyHeader_line2,
	// 	classes.lowTopCell
	// )

	// const bodyFirstLineClass = classNames(
	// 	classes.borderBottomNone,
	// 	classes.lowBottomCell
	// )

	// const bodyLastLineClass = classNames(classes.lowTopCell)

	// const handleRequestSort = columnName => {
	// 	const isDesc = sortСolumn === columnName && sortDirection === 'desc'
	// 	const newDirection = isDesc ? 'asc' : 'desc'
	// 	// const sortedOrders = orderBy(array, columnName, newDirection)
	// 	setSortDirection(newDirection)
	// 	setSortСolumn(columnName)

	// 	setVisibleArrayRepresentation(
	// 		getArrayRepresentation(
	// 			arrayRepresentation,
	// 			columnName,
	// 			newDirection,
	// 			filter
	// 		)
	// 	)
	// }

	// const getArrayRepresentation = (array, sortСolumn, direction, filter) => {
	// 	const sortedFiltered = orderBy(array, sortСolumn, direction).filter(
	// 		completedLine => {
	// 			if (!filter) return true

	// 			let isMatch = false
	// 			Object.keys(completedLine).forEach(key => {
	// 				if (isMatch) return
	// 				if (
	// 					String(completedLine[key])
	// 						.toLowerCase()
	// 						.indexOf(filter.toLowerCase()) !== -1
	// 				)
	// 					isMatch = true
	// 			})
	// 			return isMatch
	// 		}
	// 	)
	// 	return sortedFiltered
	// }

	const handleCheckboxClick = (event, task) => {
		const newArray = [...arrayRepresentation]
		const checkedCompleted = newArray.find(completedLine => {
			return completedLine._id === task._id && completedLine.type === task.type
		})
		checkedCompleted.isChecked = !checkedCompleted.isChecked
		setArrayRepresentation(newArray)

		setSelectedDocs(
			array.filter(element =>
				newArray
					.filter(element => element.isChecked)
					.find(
						newArrayElement =>
							newArrayElement._id === element._id &&
							newArrayElement.number === element.number &&
							newArrayElement.type === element.type
					)
			)

			// array.filter(
			// 	element => element._id === task._id && element.type === task.type
			// )
		)
	}

	const handleRowClick = (event, doc) => {
		history.push(`/completed/${doc.type}/${doc._id}`)
	}

	return (
		<>
			<SortableTable
				caption='Документы'
				headerRows={headerRows}
				array={arrayRepresentation}
				handleCheckboxClick={handleCheckboxClick}
				classes={classes}
				handleRowClick={handleRowClick}
			/>

			{/* <TextField
				id='standard-search'
				label='фильтр'
				type='search'
				className={classes.textField}
				margin='normal'
				value={filter}
				onChange={handleFilterChange}
			/>
			<Table className={classes.table} size='small'>
				<TableHead>
					{headerRows.map((headerLine, headerLineIndex) => (
						<TableRow key={headerLineIndex}>
							{headerLine.map((headerCell, index) => (
								<TableCell
									key={index}
									className={
										headerLineIndex == 0
											? headFirstLineClass
											: headLastLineClass
									}
									colSpan={headerCell.colSpan}
								>
									{headerCell.isCheckbox ? (
										<Checkbox
											padding='checkbox'
											className={classes.zeroPadding}
										/>
									) : (
										<TableSortLabel
											active={sortСolumn === headerCell.id}
											direction={sortDirection}
											onClick={() => handleRequestSort(headerCell.id)}
										>
											{headerCell.label}
											{orderBy === headerCell.id ? (
												<span className={classes.visuallyHidden}>
													{sortDirection === 'desc'
														? 'sorted descending'
														: 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableHead>

				<TableBody>
					{visibleArrayRepresentation.map((completedLine, index) => {
						return (
							<React.Fragment key={index}>
								<TableRow
									hover
									className={classes.pointerRow}
									key={index}
								>
									<TableCell
										padding='checkbox'
										onClick={event =>
											handleCheckboxClick(
												event,
												completedLine.uid,
												completedLine.type
											)
										}
										className={bodyFirstLineClass}
									>
										<Checkbox
											checked={completedLine.isSelected}
											className='checkboxColumn'
										/>
									</TableCell>
									<TableCell className={classNames(bodyFirstLineClass)}>
										{completedLine.checkpointDate}
									</TableCell>
									<TableCell className={classNames(bodyFirstLineClass)}>
										{completedLine.typeRepresentation}
									</TableCell>
									<TableCell className={classNames(bodyFirstLineClass)}>
										{completedLine.number}
									</TableCell>
									<TableCell className={classNames(bodyFirstLineClass)}>
										{completedLine.invoiceNumber}
									</TableCell>
								</TableRow>
								<TableRow
									hover
									className={classes.pointerRow}
									key={`${index}-2`}
								>
									<TableCell colSpan='2' className={bodyLastLineClass}>
										{completedLine.name}
									</TableCell>
									<TableCell colSpan='3' className={bodyLastLineClass}>
										{completedLine.address}
									</TableCell>
								</TableRow>
							</React.Fragment>
						)
					})}
				</TableBody>
			</Table> */}
		</>
	)
}

CompletedTable.propTypes = {}

export default compose(withStyles(styles), withRouter)(CompletedTable)
