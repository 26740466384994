import React from 'react'
import Fab from '@material-ui/core/Fab'

import classNames from 'classnames'

function DeliverOrderButton({ classes, handleClickOpenDeliveryWindow }) {
	return (
		<Fab
			variant='extended'
			aria-label='delete'
			color='primary'
			className={classes.fab}
			onClick={handleClickOpenDeliveryWindow}
		>
			{/* <SaveIcon className={classes.extendedIcon} /> */}
			Доставлено
		</Fab>
	)
}

function NotDeliveryOrderButton({ classes, handleClickOpenNotDeliveryWindow }) {
	return (
		<Fab
			variant='extended'
			aria-label='delete'
			color='default'
			className={classNames(classes.fab, classes.fab2)}
			onClick={handleClickOpenNotDeliveryWindow}
		>
			{/* <SaveIcon className={classes.extendedIcon} /> */}
			Не доставлено
		</Fab>
	)
}

function GetToDeliveryOrderButton({
	classes,
	handleClickTakeToDelivery,
	buttonsIsDisabled,
}) {
	return (
		<Fab
			variant='extended'
			aria-label='delete'
			color='primary'
			className={classes.fab}
			onClick={handleClickTakeToDelivery}
			disabled={buttonsIsDisabled}
		>
			{/* <SaveIcon className={classes.extendedIcon} /> */}
			Взять на доставку (ДН+КД)
		</Fab>
	)
}

function DeliverySelfPickupOrderButton({
	classes,
	handleClickOpenDeliveryWindow,
	buttonsIsDisabled,
}) {
	return (
		<Fab
			variant='extended'
			aria-label='delete'
			color='primary'
			className={classes.fab}
			onClick={handleClickOpenDeliveryWindow}
			disabled={buttonsIsDisabled}
		>
			{/* <SaveIcon className={classes.extendedIcon} /> */}
			Выдать самовывоз (ОК)
		</Fab>
	)
}

function TansferToGSDeliveryOrderButton({ classes, handleClickTansferToGS }) {
	return (
		<Fab
			variant='extended'
			aria-label='delete'
			color='default'
			className={classNames(classes.fab, classes.fab3)}
			onClick={handleClickTansferToGS}
		>
			в ГС
		</Fab>
	)
}

function DeliveryOrderButtons({
	order,
	classes,
	handleClickOpenDeliveryWindow,
	handleClickOpenNotDeliveryWindow,
	handleClickTakeToDelivery,
	handleClickTansferToGS,
	buttonsIsDisabled,
}) {
	const buttonsArray = []
	if (
		(order.nextTerminals.length === 0 && order.checkpointCurrent === 'КД') ||
		['НД', 'ПГ', 'ДП', 'ПД'].indexOf(order.checkpointCurrent) !== -1
	) {
		buttonsArray.push(
			<DeliverOrderButton
				classes={classes}
				handleClickOpenDeliveryWindow={handleClickOpenDeliveryWindow}
				handleClickOpenNotDeliveryWindow={handleClickOpenNotDeliveryWindow}
				key={buttonsArray.length}
			/>
		)
		buttonsArray.push(
			<NotDeliveryOrderButton
				classes={classes}
				handleClickOpenNotDeliveryWindow={handleClickOpenNotDeliveryWindow}
				key={buttonsArray.length}
			/>
		)
	}

	if (
		order.nextTerminals.length === 0 &&
		(order.checkpointCurrent === 'ГС' || order.checkpointCurrent === 'ЧС')
	) {
		buttonsArray.push(
			<GetToDeliveryOrderButton
				classes={classes}
				handleClickTakeToDelivery={handleClickTakeToDelivery}
				key={buttonsArray.length}
				buttonsIsDisabled={buttonsIsDisabled}
			/>
		)
	}

	if (order.checkpointCurrent === 'НД') {
		buttonsArray.push(
			<TansferToGSDeliveryOrderButton
				classes={classes}
				handleClickTansferToGS={handleClickTansferToGS}
				key={buttonsArray.length}
			/>
		)
	}

	if (order.checkpointCurrent === 'СВ') {
		buttonsArray.push(
			<DeliverySelfPickupOrderButton
				classes={classes}
				handleClickOpenDeliveryWindow={handleClickOpenDeliveryWindow}
				key={buttonsArray.length}
			/>
		)
	}

	return buttonsArray
}

export default DeliveryOrderButtons
