import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import AcUnitIcon from '@material-ui/icons/AcUnit' // снежинка
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter' // штанга
import WarningIcon from '@material-ui/icons/Warning'
// import ReceiptIcon from '@material-ui/icons/Receipt'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import MailOutlineIcon from '@material-ui/icons/MailOutline' // конверт
// import HeightIcon from '@material-ui/icons/Height'

import { red, lightBlue, yellow } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
	root: {
		'& > span': {
			margin: theme.spacing(2)
		}
	},
	oversized: {
		color: red[500]
	},
	snowFlake: {
		color: lightBlue[200]
	},

	warning: {
		color: yellow[500]
	}
}))

function SpecialConditions({ conditions }) {
	const classes = useStyles()

	return (
		<span>
			{/* {conditions.cargoIsOversized && <HeightIcon className={classes.oversized}/>} */}
			{conditions.cargoIsOversized && (
				<AspectRatioIcon fontSize='small' className={classes.oversized} />
			)}
			{conditions.cargoIsHeavyweight && (
				<FitnessCenterIcon fontSize='small' color='action' />
			)}
			{conditions.cargoIsLowTemperature && (
				<AcUnitIcon fontSize='small' className={classes.snowFlake} />
			)}
			{conditions.cargoIsDangerous && (
				<WarningIcon fontSize='small' className={classes.warning} />
			)}
			{conditions.returnDocumentsRequired && (
				<MailOutlineIcon fontSize='small' color='action' />
			)}
		</span>
	)
}

SpecialConditions.propTypes = {
	conditions: PropTypes.object.isRequired
}

export default SpecialConditions
