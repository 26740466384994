import axios from 'axios'
import { Storage } from '../services/storage'

export default {
	getUser(query) {
		return axios
			.get('/api/user', { params: query })
			.then((res) => res.data)
			.catch((error) => {
				if (error.response.status === 404) return
			})
	},

	updateCurrent(newUser) {
		return Storage.getData('currentUser').then((currentUser) => {
			return Storage.setData({ currentUser: newUser })
			// .then(() => {
			// 	if (currentUser && (currentUser._id !== newUser._id)) {
			// 		return Storage.setData({desadvs: []})
			// 	}
			// })
		})
	},

	getCurrent() {
		return Storage.getData('currentUser')
	},

	async updateUser(user) {
		const headers = {
			'Content-Type': 'application/json',
		}
		try {
			const res = await axios.put('/updateUser', user, { headers })
			return res.data
		} catch (error) {
			return {
				status: error.response && error.response.status,
				message: error.message,
			}
		}
	},

	async authenticate(params) {
		try {
			const res = await axios.post('/auth', params)
			return res.data.user
		} catch (error) {
			if (error.response && error.response.status === 401) {
				return { error: true, status: error.response.status }
			}
		}
	},

	async clearCurrentUser() {
		await Storage.setData({ currentUser: undefined })
	},

	async logout() {
		// await axios.get('/api/logout')
		// await Storage.clear()
		axios.get('/api/logout')
		Storage.clear()
	},
}
