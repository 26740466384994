import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import format from 'date-fns/format'
import { orderBy } from 'lodash'
import classNames from 'classnames/bind'
import { sumBy } from 'lodash'
// import compose from 'recompose/compose'

// import { withRouter } from 'react-router-dom'

// import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import { TableFooter } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	pointerRow: {
		cursor: 'pointer',
	},
	tableTitle: {
		paddingLeft: 20,
		paddingTop: 20,
	},
	compactTableCell: {
		padding: '4px 20px 4px 20px',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	borderBottomNone: {
		borderBottom: 0,
	},
	lowBottomCell: {
		paddingBottom: 0,
	},
	narrowPaddingLeft: {
		paddingLeft: 7,
	},
	narrowPaddingRight: {
		paddingRight: 7,
	},
	lowTopCell: {
		paddingTop: 0,
	},
	stickyHeader: {
		// backgroundColor: '#fff',
		backgroundColor: theme.palette.background.default,
		position: 'sticky',
		[theme.breakpoints.up('xs')]: {
			top: '32px',
		},
		[theme.breakpoints.up('sm')]: {
			top: '40px',
		},
	},
	stickyHeader_line2: {
		// backgroundColor: '#fff',
		backgroundColor: theme.palette.background.default,
		position: 'sticky',
		[theme.breakpoints.up('xs')]: {
			top: '58px',
		},
		[theme.breakpoints.up('sm')]: {
			top: '66px',
		},
	},
	zeroPadding: { padding: 0 },
	headerBackground: {
		backgroundColor: theme.palette.common.white,
	},
}))

function SortableTable({
	caption,
	headerRows,
	array,
	totalsIds,
	handleCheckboxClick,
	handleRowClick,
	classes: parentClasses,
}) {
	const [filter, setFilter] = useState('')
	const [sortСolumn, setSortСolumn] = useState('number')
	const [sortDirection, setSortDirection] = useState('desc')
	// const [arrayRepresentation, setArrayRepresentation] = useState([])
	const [visibleArrayRepresentation, setVisibleArrayRepresentation] = useState(
		[]
	)

	const classes = { ...parentClasses, ...useStyles() }

	useEffect(() => {
		setVisibleArrayRepresentation(
			getArrayRepresentation(array, sortСolumn, sortDirection, filter)
		)
	}, [array, filter, sortDirection, sortСolumn])

	const headFirstLineClass = classNames(
		classes.borderBottomNone,
		classes.lowBottomCell,
		classes.stickyHeader,
		classes.narrowPaddingLeft,
		classes.narrowPaddingRight
	)

	const headLastLineClass = classNames(
		classes.stickyHeader_line2,
		classes.lowTopCell,
		classes.narrowPaddingLeft,
		classes.narrowPaddingRight
	)

	const bodyFirstLineClass = classNames(
		classes.borderBottomNone,
		classes.lowBottomCell,
		classes.narrowPaddingLeft,
		classes.narrowPaddingRight
	)

	const bodyLastLineClass = classNames(
		classes.lowTopCell,
		classes.narrowPaddingLeft,
		classes.narrowPaddingRight
	)

	const handleRequestSort = columnName => {
		const isDesc = sortСolumn === columnName && sortDirection === 'desc'
		const newDirection = isDesc ? 'asc' : 'desc'
		// const sortedOrders = orderBy(array, columnName, newDirection)
		setSortDirection(newDirection)
		setSortСolumn(columnName)

		setVisibleArrayRepresentation(
			getArrayRepresentation(array, columnName, newDirection, filter)
		)
	}

	const getArrayRepresentation = (array, sortСolumn, direction, filter) => {
		const sortedFiltered = orderBy(array, sortСolumn, direction).filter(
			completedLine => {
				if (!filter) return true

				let isMatch = false
				Object.keys(completedLine).forEach(key => {
					if (isMatch) return
					if (
						String(completedLine[key])
							.toLowerCase()
							.indexOf(filter.toLowerCase()) !== -1
					)
						isMatch = true
				})
				return isMatch
			}
		)
		return sortedFiltered
	}

	const handleFilterChange = event => {
		// const _sortedCompleted = [...sortedCompleted]
		// _sortedCompleted.filter
		const _filter = event.target.value
		setFilter(_filter)
		setVisibleArrayRepresentation(
			getArrayRepresentation(array, sortСolumn, sortDirection, _filter)
		)
	}

	const isCheckbox = (arrayElement, headerCell) => {
		return (
			headerCell.isCheckbox &&
			((headerCell.isCheckboxVisible &&
				headerCell.isCheckboxVisible(arrayElement)) ||
				!headerCell.isCheckboxVisible)
		)
	}

	return (
		<>
			<Grid container justify='space-between'>
				<Grid item>
					<h3>{caption}</h3>
				</Grid>
				<Grid item>
					<TextField
						// id='standard-search'
						label='фильтр'
						type='search'
						className={classes.textField}
						// margin='normal'
						value={filter}
						onChange={handleFilterChange}
					/>
				</Grid>
			</Grid>

			{/* <Table className={classes.table} size='small'> */}
			<Table size='small'>
				<TableHead>
					{headerRows.map((headerLine, headerLineIndex) => (
						<TableRow key={headerLineIndex}>
							{headerLine.map((headerCell, index) => (
								<TableCell
									key={index}
									className={classNames(
										headerLineIndex === 0
											? headFirstLineClass
											: headLastLineClass,
										classes.headerBackground
									)}
									colSpan={headerCell.colSpan}
								>
									{/* {headerCell.children} */}
									{headerCell.isCheckbox ? (
										<Checkbox
											padding='checkbox'
											className={classes.zeroPadding}
										/>
									) : (
										<TableSortLabel
											active={sortСolumn === headerCell.id}
											direction={sortDirection}
											onClick={() => handleRequestSort(headerCell.id)}
										>
											<strong>{headerCell.label}</strong>
											{orderBy === headerCell.id ? (
												<span className={classes.visuallyHidden}>
													{sortDirection === 'desc'
														? 'sorted descending'
														: 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableHead>

				<TableBody>
					{visibleArrayRepresentation.map(arrayElement =>
						headerRows.map((headerLine, headerLineIndex) => (
							<TableRow
								key={headerLineIndex}
								hover
								className={classes.pointerRow}
							>
								{headerLine.map((headerCell, index) => (
									<TableCell
										key={index}
										padding={headerCell.isCheckbox ? 'checkbox' : 'default'}
										className={
											headerLineIndex === 0 && headerRows.length > 1
												? bodyFirstLineClass
												: bodyLastLineClass
										}
										colSpan={headerCell.colSpan}
										onClick={event =>
											isCheckbox(arrayElement, headerCell)
												? handleCheckboxClick &&
												  handleCheckboxClick(event, arrayElement)
												: handleRowClick && handleRowClick(event, arrayElement)
										}
									>
										{isCheckbox(arrayElement, headerCell) ? (
											<Checkbox
												checked={arrayElement.isChecked}
												className='checkboxColumn'
											/>
										) : (
											arrayElement[headerCell.id]
										)}
									</TableCell>
								))}
							</TableRow>
						))
					)}

					{/* {visibleArrayRepresentation.map((arrayElement, index) => {
						return (
							<React.Fragment key={index}>
								<TableRow
									hover
									className={classes.pointerRow}
									// onClick={() => history.push(`/deliveries/${manifest.number}`)}
									key={index}
								>
									<TableCell
										padding='checkbox'
										onClick={event =>
											handleCheckboxClick(
												event,
												arrayElement.uid,
												arrayElement.type
											)
										}
										className={bodyFirstLineClass}
									>
										<Checkbox
											checked={arrayElement.isSelected}
											className='checkboxColumn'
										/>
									</TableCell>
									<TableCell className={classNames(bodyFirstLineClass)}>
										{arrayElement.checkpointDate}
									</TableCell>
									<TableCell className={classNames(bodyFirstLineClass)}>
										{arrayElement.typeRepresentation}
									</TableCell>
									<TableCell className={classNames(bodyFirstLineClass)}>
										{arrayElement.number}
									</TableCell>
									<TableCell className={classNames(bodyFirstLineClass)}>
										{arrayElement.invoiceNumber}
									</TableCell>
								</TableRow>
								<TableRow
									hover
									className={classes.pointerRow}
									// onClick={() => history.push(`/deliveries/${manifest.number}`)}
									key={`${index}-2`}
								>
									<TableCell colSpan='2' className={bodyLastLineClass}>
										{arrayElement.name}
									</TableCell>
									<TableCell colSpan='3' className={bodyLastLineClass}>
										{arrayElement.address}
									</TableCell>
								</TableRow>
							</React.Fragment>
						)
					})} */}
				</TableBody>

				{totalsIds && (
					<TableFooter>
						{headerRows.map((headerLine, headerLineIndex) => (
							<TableRow key={headerLineIndex}>
								{headerLine.map((headerCell, index) => (
									<TableCell
										key={index}
										className={classNames(
											headerLineIndex === 0
												? headFirstLineClass
												: headLastLineClass,
											classes.headerBackground
										)}
										colSpan={headerCell.colSpan}
									>
										{headerCell.id &&
											totalsIds.indexOf(headerCell.id) !== -1 &&
											Math.round10(
												sumBy(visibleArrayRepresentation, headerCell.id),
												-2
											)}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableFooter>
				)}
			</Table>
		</>
	)
}

SortableTable.propTypes = {
	array: PropTypes.array.isRequired,
	headerRows: PropTypes.array.isRequired,
}

export default SortableTable
