import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import pickups from '../fetchers/pickups'

function OrderReceptionDialog({ open, close, classes, order }) {
	const [orderReception, setOrderReception] = useState({ date: new Date() })
	const [formInPending, setFormInPending] = useState(false)

	useEffect(() => {
		if (!open) return

		setOrderReception({
			date: new Date(),
			invoiceNumber: order.invoiceNumber,
			recievedBy: '',
			places: order.totalPlaces,
			weight: order.totalWeight,
			comment: '',
		})
	}, [open])

	const handleReceivedButtonClick = () => {
		setFormInPending(true)
		close(orderReception)
		setFormInPending(false)
	}

	const onDialogFieldChange = event => {
		const newObj = pickups.onDialogFieldChange(event, orderReception)
		setOrderReception({ ...orderReception, ...newObj })
	}

	const sumbmitIsDisabled = () => {
		return formInPending
	}

	return (
		<Dialog
			open={open}
			onClose={() => close()}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title'>{`Забор заказа ${order.number}`}</DialogTitle>
			<DialogContent>
				{/* <DialogContentText>
				To subscribe to this website, please enter your email address here. We will send
				updates occasionally.
			</DialogContentText> */}
				<TextField
					autoFocus
					// margin='dense'
					label='Дата забора'
					type='date'
					name='date'
					value={format(orderReception.date, 'yyyy-MM-dd')}
					onChange={event => onDialogFieldChange(event)}
				/>
				<TextField
					// autoFocus
					// margin='dense'
					label='Время'
					type='time'
					name='date'
					value={format(orderReception.date, 'HH:mm')}
					onChange={event => onDialogFieldChange(event)}
				/>
				<TextField
					label='Номер накладной'
					type='text'
					name='invoiceNumber'
					fullWidth
					value={orderReception.invoiceNumber}
					onChange={event => onDialogFieldChange(event)}
				/>
				<TextField
					autoComplete='off'
					// autoFocus
					// margin='dense'
					label='Груз забран у (ФИО, должность)'
					type='text'
					name='recievedBy'
					fullWidth
					value={orderReception.recievedBy}
					onChange={event => onDialogFieldChange(event)}
				/>
				<TextField
					// margin='dense'
					label='Количество мест'
					type='number'
					name='places'
					fullWidth
					value={orderReception.places}
					onChange={event => onDialogFieldChange(event)}
				/>
				<TextField
					// margin='dense'
					label='Ориентировочный вес, кг.'
					type='number'
					name='weight'
					fullWidth
					value={orderReception.weight}
					onChange={event => onDialogFieldChange(event)}
				/>
				<TextField
					// autoFocus
					// margin='dense'
					label='Комментарий'
					type='text'
					name='comment'
					fullWidth
					value={orderReception.comment}
					onChange={event => onDialogFieldChange(event)}
				/>

				{/* <FormControlLabel
				control={
					<Checkbox
						// checked={this.state.checkedB}
						// onChange={this.handleChange('checkedB')}
						// value="checkedB"
						color="primary"
					/>
				}
				label="Груз получен с актом о повреждении"
			/>						 */}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => close()} color='primary'>
					Отмена
				</Button>
				<Button
					onClick={handleReceivedButtonClick}
					variant='contained'
					color='primary'
					disabled={sumbmitIsDisabled()}
				>
					Получил (ГЗ+ТО+ГС)
				</Button>
			</DialogActions>
		</Dialog>
	)
}

OrderReceptionDialog.propTypes = {}

export default OrderReceptionDialog
