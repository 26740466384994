import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import pickups from '../fetchers/pickups'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@material-ui/core'

function OrderNotReceptionDialog({ open, close, classes, order }) {
  const [orderNotReception, setOrderNotReception] = useState({
    date: new Date()
  })
  const reasons = [
    'Большая загруженность маршрута',
    'Отправитель отменил заказ',
    'Несоответствие заявленныхданных реальным'
  ]

  useEffect(() => {
    if (!open) return

    setOrderNotReception({
      date: new Date(),
      reason: '',
      comment: ''
    })
  }, [open])

  const handleReceivedButtonClick = () => {
    close(orderNotReception)
  }

  const onDialogFieldChange = event => {
    const newObj = pickups.onDialogFieldChange(event, orderNotReception)
    setOrderNotReception({ ...orderNotReception, ...newObj })
  }

  const fieldHasErrors = name => {
    if (name === 'reason') {
      if (!orderNotReception[name]) return true
    }
  }

  const sumbmitIsDisabled = () => {
    return ['reason'].some(name => fieldHasErrors(name))
  }

  return (
    <Dialog
      open={open}
      onClose={() => close()}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>{`Не забран заказ ${order.number}`}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
				To subscribe to this website, please enter your email address here. We will send
				updates occasionally.
			</DialogContentText> */}

        <TextField
          autoFocus
          // margin='dense'
          label='Дата'
          type='date'
          name='date'
          value={format(orderNotReception.date, 'yyyy-MM-dd')}
          onChange={event => onDialogFieldChange(event)}
        />

        <TextField
          // autoFocus
          // margin='dense'
          label='Время'
          type='time'
          name='date'
          value={format(orderNotReception.date, 'HH:mm')}
          onChange={event => onDialogFieldChange(event)}
        />

        <FormControl
          component='fieldset'
          className={classes.formControl}
          error={fieldHasErrors('reason')}
        >
          <FormLabel component='legend'>Причина</FormLabel>
          {fieldHasErrors('reason') && (
            <FormHelperText>не заполнена</FormHelperText>
          )}
          <RadioGroup
            aria-label='checkpoint'
            name='reason'
            className={classes.group}
            value={orderNotReception.reason}
            onChange={event => onDialogFieldChange(event)}
          >
            {reasons.map((reason, index) => (
              <FormControlLabel
                key={reason}
                value={reason}
                control={<Radio />}
                label={reason}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <TextField
          autoFocus
          // margin='dense'
          id='name'
          label='Комментарии'
          type='text'
          name='comment'
          fullWidth
          value={orderNotReception.comment}
          onChange={event => onDialogFieldChange(event)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()} color='primary'>
          Отмена
        </Button>
        <Button
          onClick={handleReceivedButtonClick}
          variant='contained'
          color='secondary'
          disabled={sumbmitIsDisabled()}
        >
          Не забран (НВ)
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OrderNotReceptionDialog.propTypes = {}

export default OrderNotReceptionDialog
