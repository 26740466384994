import React from 'react'

import { pick as _pick, orderBy as _orderBy } from 'lodash'
import format from 'date-fns/format'
import SpecialConditions from '../SpecialConditions'

function getPickupOrderRepresentation(order, classes) {
	const pickupDate = order.pickupDate
		? format(order.pickupDate, 'dd.MM.yyyy HH:mm')
		: ''

	const orderRepresentation = {
		pickupDate,
		number: order.number,
		type: 'order',
		_id: order._id,
		invoiceNumber: order.invoiceNumber,
		totalPlaces: order.totalPlaces,
		totalWeight: order.totalWeight,
		totalVolume: order.totalVolume,
		specialConditions: <SpecialConditions conditions={order} />,
		checkpointCurrent: order.checkpointCurrent,
		consignor: order.consignor.name,
		consignorAddress: order.consignor.address,
		nextTerminalName:
			order.nextTerminals &&
			order.nextTerminals.length > 0 &&
			order.nextTerminals[0].code,
		orderField: 1,
	}

	orderRepresentation.filter = valuesInOneLine(orderRepresentation, [
		'pickupDate',
		'number',
		'invoiceNumber',
		'checkpointCurrent',
		'consignor',
		'consignorAddress',
		'nextTerminalName',
	])

	return orderRepresentation
}

function getPickupManifestsRepresentation(manifest, classes) {
	const transferToCarrierDate = format(manifest.date, 'dd.MM.yyyy HH:mm')
	const arrivalToCarrierDate = manifest.arrivalToCarrierDateFact
		? format(manifest.arrivalToCarrierDateFact, 'dd.MM.yyyy HH:mm')
		: manifest.arrivalToCarrierDatePlan &&
		  format(manifest.arrivalToCarrierDatePlan, 'dd.MM.yyyy HH:mm')
	const arrivalToCarrierDateClass =
		!manifest.arrivalToCarrierDateFact && manifest.arrivalToCarrierDatePlan
			? classes.muted
			: null

	const manifestRepresentation = {
		transferToCarrierDate,
		arrivalToCarrierDate: (
			<span className={arrivalToCarrierDateClass}>{arrivalToCarrierDate}</span>
		),
		number: manifest.number,
		type: 'manifest',
		_id: manifest._id,
		invoiceNumber: manifest.invoiceNumber,
		totalPlaces: manifest.totalPlaces,
		totalWeight: manifest.totalWeight,
		totalVolume: manifest.totalVolume,
		consignorTerminalCode: manifest.consignorTerminal.code,
		consigneeTerminalCode: manifest.consigneeTerminal.code,
		specialConditions: <SpecialConditions conditions={manifest} />,
		checkpointCurrent: manifest.checkpointCurrent,
		orderField: 3,
	}

	manifestRepresentation.filter = valuesInOneLine(manifestRepresentation, [
		'transferToCarrierDate',
		'arrivalToCarrierDate',
		'number',
		'invoiceNumber',
		'checkpointCurrent',
		'consignorTerminalCode',
		'consigneeTerminalCode',
	])

	return manifestRepresentation
}

function getDeliveryOrderRepresentation(order, classes) {
	const deliveryDate = order.deliveryDate && (
		<>
			<div>{format(order.deliveryDate, 'dd.MM.yyyy')}</div>
			<div>
				{format(order.deliveryTimeFrom, 'HH:mm')}&nbsp;-&nbsp;
				{format(order.deliveryTimeTo, 'HH:mm')}
			</div>
		</>
	)

	const deliveryDateRepresentation =
		order.deliveryDate &&
		`
	${format(order.deliveryDate, 'dd.MM.yyyy')} ${format(
			order.deliveryTimeFrom,
			'HH:mm'
		)} ${format(order.deliveryTimeTo, 'HH:mm')}`

	const deliveryDateOneLine = order.deliveryDate && (
		<>
			{format(order.deliveryDate, 'dd.MM.yyyy')}&nbsp;
			{format(order.deliveryTimeFrom, 'HH:mm')}&nbsp;-&nbsp;
			{format(order.deliveryTimeTo, 'HH:mm')}
		</>
	)

	const consigneeName =
		order.nextTerminals.length > 0
			? order.nextTerminals[0].code
			: order.consignee.name

	const consigneeAddress =
		order.nextTerminals.length > 0
			? order.nextTerminals[0].address
			: order.consignee.address

	const orderRepresentation = {
		type: 'order',

		isChecked: false,
		deliveryDate,
		deliveryDateRepresentation,
		deliveryDateOneLine,
		uid: order.uid,
		_id: order._id,
		number: order.number,
		invoiceNumber: order.invoiceNumber,
		totalPlaces: order.totalPlaces,
		totalWeight: order.totalWeight,
		totalVolume: order.totalVolume,
		cargoIsDangerous: order.cargoIsDangerous,
		cargoIsHeavyweight: order.cargoIsHeavyweight,
		cargoIsLowTemperature: order.cargoIsLowTemperature,
		cargoIsOversized: order.cargoIsOversized,
		returnDocumentsRequired: order.returnDocumentsRequired,
		checkpointCurrent: order.checkpointCurrent,
		consigneeName,
		consigneeAddress,
		nextIsTerminal: order.nextTerminals.length > 0,
		specialConditions: <SpecialConditions conditions={order} />,
		orderField: 1,
	}

	orderRepresentation.filter = valuesInOneLine(orderRepresentation, [
		'deliveryDateRepresentation',
		'number',
		'invoiceNumber',
		'checkpointCurrent',
		'consigneeName',
		'consigneeAddress',
	])

	return orderRepresentation
}

function getDeliveryManifestsRepresentation(manifest, classes) {
	const arrivalToCarrierDate = manifest.arrivalToCarrierDateFact ? (
		<span>
			{manifest.arrivalToCarrierDateFact &&
				format(manifest.arrivalToCarrierDateFact, 'dd.MM.yyyy HH:mm')}
		</span>
	) : (
		<span className={classes.muted}>
			{manifest.arrivalToCarrierDatePlan &&
				format(manifest.arrivalToCarrierDatePlan, 'dd.MM.yyyy HH:mm')}
		</span>
	)
	const arrivalToCarrierDateRepresentation = manifest.arrivalToCarrierDateFact
		? manifest.arrivalToCarrierDateFact &&
		  format(manifest.arrivalToCarrierDateFact, 'dd.MM.yyyy HH:mm')
		: manifest.arrivalToCarrierDatePlan &&
		  format(manifest.arrivalToCarrierDatePlan, 'dd.MM.yyyy HH:mm')

	const manifestRepresentation = {
		type: 'manifest',

		arrivalToCarrierDate,
		arrivalToCarrierDateRepresentation,
		uid: manifest.uid,
		_id: manifest._id,
		number: manifest.number,
		invoiceNumber: manifest.invoiceNumber,
		totalPlaces: manifest.totalPlaces,
		totalWeight: manifest.totalWeight,
		totalVolume: manifest.totalVolume,
		checkpointCurrent: manifest.checkpointCurrent,
		consignorTerminalCode: manifest.consignorTerminal.code,
		consigneeTerminalCode: manifest.consigneeTerminal.code,
		specialConditions: <SpecialConditions conditions={manifest} />,
		orderField: 3,
	}

	manifestRepresentation.filter = valuesInOneLine(manifestRepresentation, [
		'arrivalToCarrierDateRepresentation',
		'number',
		'invoiceNumber',
		'checkpointCurrent',
		'consignorTerminalCode',
		'consigneeTerminalCode',
	])

	return manifestRepresentation
}

function valuesInOneLine(obj, keyArray) {
	const searchFiledObj = _pick(obj, keyArray)

	const searchFieldArray = []

	for (const key in searchFiledObj) {
		if (searchFiledObj.hasOwnProperty(key)) {
			searchFieldArray.push(searchFiledObj[key])
		}
	}
	return searchFieldArray.join(' ').toLowerCase()
}

function filterDocs(doc, filter) {
	if (!filter) return true

	return doc.filter.indexOf(filter.toLowerCase()) !== -1
}

function sortDocs(docs, field, direction) {
	return _orderBy(docs, field, direction)
}

export {
	getPickupOrderRepresentation,
	getPickupManifestsRepresentation,
	getDeliveryOrderRepresentation,
	getDeliveryManifestsRepresentation,
	filterDocs,
	sortDocs,
}
