import { SET_ALERT, REMOVE_ALERT } from './types'

export const setAlert = alert => dispatch => {
	dispatch({
		type: SET_ALERT,
		payload: alert,
	})
}

export const removeAlert = alert => dispatch => {
	dispatch({
		type: REMOVE_ALERT,
		payload: alert.id,
	})
}
