import React, { Component } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
// import ToggleButton from '@material-ui/lab/ToggleButton'
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Grid from '@material-ui/core/Grid'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
// import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
// import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'

import DescriptionLine from '../components/DescriptionLine'

import pickups from '../fetchers/pickups'
import OrderReceptionDialog from './OrderReceptionDialog'
import FilesManipulation from '../components/FilesManipulation'

import { setAlert } from '../actions/alert'
import OrderNotReceptionDialog from './OrderNotReceptionDialog'
import OrderNumber from '../components/OrderNumber'

import documentsFetcher from '../fetchers/documents'

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		width: '100%',
		overflowX: 'auto',
		// paddingTop: theme.spacing.unit * 2,
		// paddingBottom: theme.spacing.unit * 2,
		padding: 20,
		// margin: 20,
		marginTop: 20,
		marginBottom: 20,
	},
	// table: {
	//   minWidth: 700
	// },
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	fab2: { right: '159px' },
})

const LinkRouter = props => <Link {...props} component={RouterLink} />

class PickupOrder extends Component {
	state = {
		receptionWindowIsOpened: false,
		notReceptionWindowIsOpened: false,
		order: {},
		manifest: {},
	}

	setAlert = this.props.setAlert

	manifestUid = this.props.match.params.manifestUid

	async componentDidMount() {
		await documentsFetcher.getFromServer()
		let orders = await pickups.getLocal('orders')
		let manifests = await pickups.getLocal('manifests')
		const { orderId, manifestId } = this.props.match.params
		const order = orders.find(order => order._id === orderId)
		const manifest = manifests.find(manifest => manifest._id === manifestId)
		this.setState({ order, manifest })
	}

	handleClickOpenReceptionWindow = () => {
		this.setState({ receptionWindowIsOpened: true })
	}

	handleCloseReceptionWindow = async (orderReception, history) => {
		if (orderReception) {
			await pickups.pickedUp(this.state.order, orderReception) // забран
			history.push('/pickups')
			return
		}
		this.setState({ receptionWindowIsOpened: false })
	}

	handleClickOpenNotReceptionWindow = () => {
		this.setState({ notReceptionWindowIsOpened: true })
	}

	handleCloseNotReceptionWindow = async (orderNotReception, history) => {
		if (orderNotReception) {
			await pickups.orderNotPickedUp(this.state.order, orderNotReception) // не забран
			history.push('/pickups')
			return
		}
		this.setState({ notReceptionWindowIsOpened: false })
	}

	handleClickTakeToWork = async () => {
		const changedOrder = await pickups.takeToWork(
			this.state.order,
			'Взят в работу'
		) // передан на маршрут
		this.setState({ order: changedOrder })
	}

	handleProcessFile = (error, file) => {
		console.log(file)
		const fileFromServer = JSON.parse(file.serverId)

		const orderFiles = [...this.state.order.files, fileFromServer]
		this.setState({
			order: { ...this.state.order, files: orderFiles },
		})
	}

	handleCickCheckboxIsTransportDoc = async (file, checked) => {
		const orderFiles = [...this.state.order.files].map(orderFile => {
			if (orderFile._id === file._id)
				return { ...file, isTransportDoc: checked }
			else return orderFile
		})

		const _order = { ...this.state.order, files: orderFiles }
		this.setState({ order: _order })
		const success = await pickups.patchFile(this.state.order, {
			...file,
			isTransportDoc: checked,
		})

		if (success) this.setAlert({ messageText: 'обновлено на сервере' })
	}

	getCommentToTerminal = (order, profile, terminalType) => {
		if (!profile.terminal || !order || !order.terminals) return
		const currentTerminal = order.terminals.find(
			terminal =>
				terminal.uid === profile.terminal.uid && terminal.type === terminalType
		)
		return currentTerminal && currentTerminal.comment
	}

	render() {
		const { classes, history, profile } = this.props
		// const { orderNumber } = this.props.match.params
		const { order } = this.state
		const commentToPickupTerminal = this.getCommentToTerminal(
			order,
			profile,
			'Забора'
		)
		const commentToDeliveryTerminal = this.getCommentToTerminal(
			order,
			profile,
			'Доставки'
		)
		if (Object.keys(order).length === 0) return null

		return (
			<>
				<Paper className={classes.root}>
					<Breadcrumbs aria-label='Breadcrumb'>
						<LinkRouter color='inherit' to='/pickups'>
							Заборы
						</LinkRouter>
						{this.manifest ? (
							<LinkRouter
								color='inherit'
								to={`/pickups/manifest/${this.manifest._id}`}
							>
								{`Манифест ${this.manifest.number}`}
							</LinkRouter>
						) : null}
						<Typography color='textPrimary' variant='h5'>
							Заказ {/* <strong> */}
							<OrderNumber number={order.number} />
							{/* </strong> */}
						</Typography>
					</Breadcrumbs>
				</Paper>

				{/* <h1>Заказ {orderNumber}</h1> */}

				<Paper className={classes.root}>
					<Grid container spacing={1}>
						{/* <Grid item xs={1}>
							<Typography variant='body1'><strong>ТН:</strong></Typography>
						</Grid>
						<Grid item xs={11}>
							<Typography variant='body1'>{order.invoiceNumber}</Typography>
						</Grid>						 */}
						{/* {descriptionLine('ТН', order.invoiceNumber)} */}
						<DescriptionLine caption='ТН' value={order.invoiceNumber} />
					</Grid>
				</Paper>

				<Paper className={classes.root}>
					<Typography variant='subtitle1'>Информация об отправителе</Typography>
					<Grid container spacing={1}>
						<DescriptionLine
							caption='Отправитель'
							value={order.consignor.name}
						/>
						<DescriptionLine caption='Адрес' value={order.consignor.address} />
						<DescriptionLine
							caption='Контакт'
							value={order.consignor.contact}
						/>
						<DescriptionLine
							caption='Телефон'
							value={order.consignor.phoneNumber}
						/>
						{commentToPickupTerminal && (
							<DescriptionLine
								caption='Комментарий'
								value={commentToPickupTerminal}
							/>
						)}
					</Grid>
				</Paper>

				{order.nextTerminals.length > 0 ? (
					<Paper className={classes.root}>
						<Typography variant='subtitle1'>Терминал получения</Typography>
						<Grid container spacing={1}>
							<DescriptionLine
								caption='Терминал'
								value={order.nextTerminals[0].name}
							/>
							<DescriptionLine
								caption='Адрес'
								value={order.nextTerminals[0].address}
							/>
							<DescriptionLine
								caption='Контакт'
								value={order.nextTerminals[0].contact}
							/>
							<DescriptionLine
								caption='Телефон'
								value={order.nextTerminals[0].phoneNumber}
							/>
							<DescriptionLine
								caption='Email'
								value={order.nextTerminals[0].email}
							/>
						</Grid>
					</Paper>
				) : (
					<Paper className={classes.root}>
						<Typography variant='subtitle1'>Информация о получателе</Typography>
						<Grid container spacing={1}>
							<DescriptionLine
								caption='Получатель'
								value={order.consignee.name}
							/>
							<DescriptionLine
								caption='Адрес'
								value={order.consignee.address}
							/>
							<DescriptionLine
								caption='Контакт'
								value={order.consignee.contact}
							/>
							<DescriptionLine
								caption='Телефон'
								value={order.consignee.phoneNumber}
							/>
							{commentToDeliveryTerminal && (
								<DescriptionLine
									caption='Комментарий'
									value={commentToDeliveryTerminal}
								/>
							)}
							{/* {descriptionLine('Получатель', order.consignee)}
						{descriptionLine('Адрес', order.receiptPointAddress)}
						{descriptionLine('Контакт', order.receiptPointContact)}
						{descriptionLine('Телефон', order.receiptPointPhoneNumber)} */}
						</Grid>
						{/* <Typography variant='body1'><strong>Получатель</strong>{order.consignee}</Typography>
					<Typography variant='body1'><strong>Адрес</strong>{order.receiptPointAddress}</Typography>
					<Typography variant='body1'><strong>Контакт</strong>{order.receiptPointContact}</Typography>
					<Typography variant='body1'><strong>Телефон</strong>{order.receiptPointPhoneNumber}</Typography> */}
					</Paper>
				)}

				<Paper className={classes.root}>
					<Typography variant='subtitle1'>Информация о грузе</Typography>
					<Grid container spacing={1}>
						<DescriptionLine caption='Мест' value={`${order.totalPlaces}`} />
						<DescriptionLine caption='Вес' value={`${order.totalWeight} кг`} />
						<DescriptionLine
							caption='Объём'
							value={`${order.totalVolume} м3`}
						/>
						<DescriptionLine
							caption='Описание'
							value={order.contentDescription}
						/>
						{/* {descriptionLine('Мест', order.totalPlaces)}
						{descriptionLine('Вес', order.totalWeight)}
						{descriptionLine('Объём', order.totalVolume)}
						{descriptionLine('Описание', order.contentDescription)} */}
					</Grid>
					{/* <Typography variant='body1'><strong>Мест</strong>{order.totalPlaces}</Typography>
					<Typography variant='body1'><strong>Вес</strong>{order.totalWeight}</Typography>
					<Typography variant='body1'><strong>Объём</strong>{order.totalVolume}</Typography>
					<Typography variant='body1'><strong>Описание</strong>{order.contentDescription}</Typography> */}
				</Paper>

				<FilesManipulation
					classes={classes}
					doc={order}
					handleProcessFile={this.handleProcessFile}
					handleCickCheckboxIsTransportDoc={
						this.handleCickCheckboxIsTransportDoc
					}
				/>

				{/* {order.checkpointCurrent === 'ЗС' && (
					<Button
						variant='contained'
						color='primary'
						className={classes.button}
						onClick={this.handleClickTakeToWork}>
						Взять в работу (ЗП)
					</Button>
				)} */}

				{order.checkpointCurrent === 'ЗС' && (
					<Fab
						variant='extended'
						aria-label='delete'
						color='primary'
						className={classes.fab}
						onClick={this.handleClickTakeToWork}
					>
						{/* <SaveIcon className={classes.extendedIcon} /> */}
						Взять в работу (ЗП)
					</Fab>
				)}

				{/* {(order.checkpointCurrent === 'ЗП' ||
					order.checkpointCurrent === 'СП') && (
					<Button
						variant='contained'
						color='primary'
						className={classes.button}
						onClick={this.handleClickOpenReceptionWindow}>
						Забран
					</Button>
				)} */}

				{(order.checkpointCurrent === 'ЗП' ||
					order.checkpointCurrent === 'СП') && (
					<>
						<Fab
							variant='extended'
							aria-label='delete'
							color='primary'
							className={classes.fab}
							onClick={this.handleClickOpenReceptionWindow}
						>
							{/* <SaveIcon className={classes.extendedIcon} /> */}
							Забран
						</Fab>

						<Fab
							variant='extended'
							aria-label='delete'
							color='default'
							className={classNames(classes.fab, classes.fab2)}
							onClick={this.handleClickOpenNotReceptionWindow}
						>
							{/* <SaveIcon className={classes.extendedIcon} /> */}
							Не забран
						</Fab>
					</>
				)}

				<OrderReceptionDialog
					open={this.state.receptionWindowIsOpened}
					close={orderReception =>
						this.handleCloseReceptionWindow(orderReception, history)
					}
					classes={classes}
					order={order}
				/>

				<OrderNotReceptionDialog
					open={this.state.notReceptionWindowIsOpened}
					close={orderNotReception =>
						this.handleCloseNotReceptionWindow(orderNotReception, history)
					}
					classes={classes}
					order={order}
				/>
			</>
		)
	}
}

PickupOrder.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
	profile: state.profile,
})

export default connect(mapStateToProps, { setAlert })(
	compose(withStyles(styles), withRouter)(PickupOrder)
)
