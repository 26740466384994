import React, { useState, useEffect, useRef } from 'react'
import {
	Card,
	CardContent,
	CardActions,
	CardActionArea,
	Box,
	Grid,
	Typography,
	makeStyles,
	Checkbox,
	TextField,
	Select,
	ListItem,
	ListItemText,
	List,
} from '@material-ui/core'
import ManifestNumber from './ManifestNumber'
import { orderBy } from 'lodash'

// import { VariableSizeList as List } from 'react-window'
// import AutoSizer from 'react-virtualized-auto-sizer'
// import {
// 	// List,
// 	CellMeasurer,
// 	CellMeasurerCache,
// 	InfiniteLoader,
// 	// AutoSizer,
// } from 'react-virtualized'
import { Virtuoso } from 'react-virtuoso'
import PickupOrderCard from './PickupOrderCard'
import PickupManifestCard from './PickupManifestCard'
import ListHeader from './ListHeader'

const useStyles = makeStyles({
	gridFullWidth: {
		width: '100%',
	},
	card: {
		flex: 1,
	},
})

export default function SortableCardList({
	classes: parentClasses,
	docs,
	headerRows,
	caption,
	isCheckboxVisible,
	handleCheckboxClick,
	DocCardComponent,
}) {
	const classes = { ...parentClasses, ...useStyles() }

	const [filter, setFilter] = useState('')
	const [selectedSortField, setSelectedSortField] = useState('number')
	const [sortFields, setSortFields] = useState([])
	const [sortDirection, setSortDirection] = useState('desc')

	const [visibleDocs, setVisibleDocs] = useState([])

	// const cellMeasurerCache = useRef(null)

	// useEffect(() => {
	// 	cellMeasurerCache.current = new CellMeasurerCache({
	// 		fixedWidth: true,
	// 		defaultHeight: 100,
	// 	})
	// })

	// const rowRenderer = ({ index, parent, key, style }) => {
	// 	return (
	// 		<CellMeasurer
	// 			key={key}
	// 			cache={cellMeasurerCache.current}
	// 			parent={parent}
	// 			columnIndex={0}
	// 			rowIndex={index}
	// 		>
	// 			<Item data={visibleDocs[index]} style={style} />
	// 		</CellMeasurer>
	// 	)
	// }

	// const Item = ({ data, style }) => (
	// 	<Grid
	// 		item
	// 		key={data.uid + data._id}
	// 		className={classes.gridFullWidth}
	// 		style={style}
	// 	>
	// 		<Box display='flex' justifyContent='space-between'>
	// 			{isCheckboxVisible && isCheckboxVisible(data) && (
	// 				<Checkbox
	// 					checked={data.isChecked}
	// 					onClick={event => handleCheckboxClick(event, data)}
	// 				/>
	// 			)}
	// 			<DocCardComponent doc={data} classes={classes} />
	// 		</Box>
	// 	</Grid>
	// )

	useEffect(() => {
		setVisibleDocs(
			getVisibleDocs(docs, selectedSortField, sortDirection, filter)
		)

		const _sortFields = []
		// headerRows.forEach((headerLine, headerLineIndex) => {
		// 	return headerLine.forEach((headerCell, index) => {
		// 		_sortFields.push(headerCell)
		// 	})
		// })

		setSortFields(_sortFields)
	}, [docs])

	const handleFilterChange = event => {
		// const _sortedCompleted = [...sortedCompleted]
		// _sortedCompleted.filter
		const _filter = event.target.value
		setFilter(_filter)
		setVisibleDocs(
			getVisibleDocs(docs, selectedSortField, sortDirection, _filter)
		)
	}

	const handleSortFieldChange = event => {
		// const _sortedCompleted = [...sortedCompleted]
		// _sortedCompleted.filter
		const _sortField = event.target.value
		setSelectedSortField(_sortField)
		setVisibleDocs(getVisibleDocs(docs, _sortField, sortDirection, filter))
	}

	const getVisibleDocs = (docs, sortField, sortDirection, filter) => {
		const sortedFiltered = orderBy(
			docs,
			['orderField', sortField],
			['asc', sortDirection]
		).filter(completedLine => {
			if (!filter) return true
			if (
				completedLine.type === 'orderListHeader' ||
				completedLine.type === 'manifestListHeader'
			)
				return true

			let isMatch = false
			Object.keys(completedLine).forEach(key => {
				if (isMatch) return
				if (
					String(completedLine[key])
						.toLowerCase()
						.indexOf(filter.toLowerCase()) !== -1
				)
					isMatch = true
			})
			return isMatch
		})
		return sortedFiltered
	}

	// function renderRow(props) {
	// 	const { index, style } = props
	// 	const doc = visibleDocs[index]
	// 	return (
	// 		// <ListItem button style={style} key={index}>
	// 		// 	<ListItemText primary={`Item ${index + 1}`} />
	// 		// </ListItem>
	// 		<Box display='flex' justifyContent='space-between'>
	// 			{isCheckboxVisible && isCheckboxVisible(doc) && (
	// 				<Checkbox
	// 					checked={doc.isChecked}
	// 					onClick={event => handleCheckboxClick(event, doc)}
	// 				/>
	// 			)}
	// 			<DocCardComponent style={style} doc={doc} classes={classes} />
	// 		</Box>
	// 	)
	// }

	const ItemVirtuozo = index => {
		const doc = visibleDocs[index]
		if (!doc) return null

		return (
			<Box display='flex' justifyContent='space-between'>
				{(doc.type === 'order' || doc.type === 'manifest') &&
					isCheckboxVisible &&
					isCheckboxVisible(doc) && (
						<>
							<Checkbox
								checked={doc.isChecked}
								onClick={event => handleCheckboxClick(event, doc)}
							/>
						</>
					)}

				{doc.component}
			</Box>
		)
	}

	// const ListHeader = ({ caption }) => {
	// 	return (
	// 		<Grid container justify='space-between'>
	// 			<Grid item>
	// 				<h3>{caption}</h3>
	// 			</Grid>

	// 			<Grid item>
	// 				<Select
	// 					native
	// 					value={selectedSortField}
	// 					onChange={handleSortFieldChange}
	// 					style={{ marginTop: '16px' }}
	// 					inputProps={{ style: { fontSize: '14px' } }}
	// 				>
	// 					{sortFields.map(sortField => (
	// 						<option value={sortField.id}>{sortField.label}</option>
	// 					))}
	// 				</Select>
	// 			</Grid>

	// 			<Grid item>
	// 				<TextField
	// 					// id='standard-search'
	// 					label='фильтр'
	// 					type='search'
	// 					className={classes.textField}
	// 					// margin='normal'
	// 					value={filter}
	// 					onChange={handleFilterChange}
	// 				/>
	// 			</Grid>
	// 		</Grid>
	// 	)
	// }

	return (
		<>
			{/* <Grid container justify='space-between'>
				<Grid item>
					<h3>{caption}</h3>
				</Grid>

				<Grid item>
					<Select
						native
						value={selectedSortField}
						onChange={handleSortFieldChange}
						style={{ marginTop: '16px' }}
						inputProps={{ style: { fontSize: '14px' } }}
					>
						{sortFields.map(sortField => (
							<option value={sortField.id}>{sortField.label}</option>
						))}
					</Select>
				</Grid>

				<Grid item>
					<TextField
						// id='standard-search'
						label='фильтр'
						type='search'
						className={classes.textField}
						// margin='normal'
						value={filter}
						onChange={handleFilterChange}
					/>
				</Grid>
			</Grid> */}
			{/* <Grid container spacing={1}> */}
			<List style={{ height: 'calc(100% - 64px)' }}>
				<Virtuoso
					totalCount={visibleDocs.length}
					overscan={20}
					item={ItemVirtuozo}
					style={{ height: '100%', width: '100%' }}
				/>
			</List>
			{/* <div style={{ width: '100%', height: 400 }}>
				<AutoSizer>
					{({ width, height }) => (
						<List
							width={width}
							height={height}
							estimatedItemSize={100}
							itemSize={() => root.current.getBoundingClientRect().height}
							itemCount={visibleDocs.length}
						>
							{renderRow}
						</List>
					)}
				</AutoSizer>
			</div> */}
			{/* {visibleDocs.map(doc => {
					return (
						<Grid
							item
							key={doc.uid + doc._id}
							className={classes.gridFullWidth}
						>
							<Box display='flex' justifyContent='space-between'>
								{isCheckboxVisible && isCheckboxVisible(doc) && (
									<Checkbox
										checked={doc.isChecked}
										onClick={event => handleCheckboxClick(event, doc)}
									/>
								)}
								<DocCardComponent
									doc={doc}
									handleClick={handleClick}
									classes={classes}
								/>
							</Box>
						</Grid>
					)
				})} */}
			{/* </Grid> */}
		</>
	)
}
