import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import format from 'date-fns/format'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

// Import React FilePond
// import { FilePond } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// import Toolbar from '@material-ui/core/Toolbar'
// import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
// import TextField from '@material-ui/core/TextField'
// import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
// import Fab from '@material-ui/core/Fab'

import DescriptionLine from '../components/DescriptionLine'

import deliveries from '../fetchers/deliveries'
// import ManifestReceptionDialog from './ManifestReceptionDialog'
// import { Checkbox } from '@material-ui/core'
import Message from '../components/Message'
import FilesManipulation from '../components/FilesManipulation'

import { setAlert } from '../actions/alert'
import SortableTable from '../components/SortableTable'
import SortableCardList from '../components/SortableCardList'
import PickupManifestOrderCard from '../components/PickupManifestOrderCard'
import ManifestNumber from '../components/ManifestNumber'

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		width: '100%',
		overflowX: 'auto',
		// paddingTop: theme.spacing.unit * 2,
		// paddingBottom: theme.spacing.unit * 2,
		padding: 20,
		// margin: 20,
		marginTop: 20,
		marginBottom: 20,
	},
	// table: {
	// 	minWidth: 700,
	// },
	pointerRow: {
		cursor: 'pointer',
	},
	muted: {
		color: 'rgba(0, 0, 0, 0.5)',
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	hideOnXsDown: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	hideOnSmUp: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
})

class DeliveryManifets extends Component {
	state = {
		manifestDetails: 'brief',
		receptionWindowIsOpened: false,
		manifest: undefined,
		files: [],
		messageIsVisible: false,
		messageText: '',
	}
	setAlert = this.props.setAlert

	ordersHeaderRows = [
		[
			{ label: 'Номер', id: 'number' },
			{ label: 'Накладная', id: 'invoiceNumber' },
			{ label: 'Место назначения', id: 'receiptPointAddress' },
			{ label: 'Мест', id: 'totalPlaces' },
			{ label: 'Вес', id: 'totalWeight' },
			{ label: 'Объём', id: 'totalVolume' },
		],
	]

	async componentDidMount() {
		let manifests = await deliveries.getLocal('manifests')

		// manifests = await pickups.getOrders()
		// this.setState({manifests})

		// await pickups.updateLocal(manifests)

		const { manifestId } = this.props.match.params
		const manifest = manifests.find(manifest => manifest._id === manifestId)
		this.setState({ manifest })
	}

	handleInit() {
		console.log('FilePond instance has initialised', this.pondRef)
	}

	handleManifestDetails = (event, manifestDetails) =>
		this.setState({ manifestDetails })

	handleCickCheckboxIsTransportDoc = async (file, checked) => {
		const manifestFiles = [...this.state.manifest.files].map(manifestFile => {
			if (manifestFile._id === file._id)
				return { ...file, isTransportDoc: checked }
			else return manifestFile
		})

		const _manifest = { ...this.state.manifest, files: manifestFiles }
		this.setState({ manifest: _manifest })
		const success = await deliveries.patchFile(this.state.manifest, {
			...file,
			isTransportDoc: checked,
		})

		if (success) this.setAlert({ messageText: 'обновлено на сервере' })
	}

	showMessage = text => {
		this.setState({ messageIsVisible: true, messageText: text })
	}

	handleHideMessage = () => {
		this.setState({ messageIsVisible: false, messageText: '' })
	}

	handleProcessFile = (error, file) => {
		console.log(file)
		const fileFromServer = JSON.parse(file.serverId)

		const manifestFiles = [...this.state.manifest.files, fileFromServer]
		this.setState({
			manifest: { ...this.state.manifest, files: manifestFiles },
		})
	}

	handleOrderClick = (event, order) => {
		this.props.history.push(
			`/deliveries/manifest/${this.state.manifest._id}/${order._id}`
		)
	}

	render() {
		const { classes, history } = this.props
		const { manifest, manifestDetails } = this.state

		if (!manifest) return null

		return (
			<>
				<Paper className={classes.root}>
					<Breadcrumbs aria-label='Breadcrumb'>
						<Link color='inherit' to='/deliveries'>
							Доставки
						</Link>
						<Typography color='textPrimary' variant='h5'>
							Манифест <ManifestNumber number={manifest.number} />
						</Typography>
					</Breadcrumbs>
				</Paper>

				<Paper className={classes.root}>
					<ToggleButtonGroup
						size='small'
						value={manifestDetails}
						exclusive
						onChange={this.handleManifestDetails}
					>
						<ToggleButton value='brief'>кратко</ToggleButton>
						<ToggleButton value='details'>подробно</ToggleButton>
					</ToggleButtonGroup>

					{manifestDetails === 'brief' ? (
						<>
							{/* <Paper className={classes.root}> */}
							<Table className={classes.table} size='small'>
								<TableHead>
									<TableRow>
										<TableCell>Дата прибытия</TableCell>
										<TableCell>Номер</TableCell>
										<TableCell>MAWB</TableCell>
										<TableCell>Мест</TableCell>
										<TableCell>Вес</TableCell>
										<TableCell>Объём</TableCell>
										<TableCell>Откуда</TableCell>
										<TableCell>Куда</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									<TableRow>
										<TableCell component='th' scope='row'>
											{manifest.arrivalToCarrierDateFact ? (
												<span>
													{manifest.arrivalToCarrierDateFact &&
														format(
															manifest.arrivalToCarrierDateFact,
															'dd.MM.yyyy HH:mm'
														)}
												</span>
											) : (
												<span className={classes.muted}>
													{manifest.arrivalToCarrierDatePlan &&
														format(
															manifest.arrivalToCarrierDatePlan,
															'dd.MM.yyyy HH:mm'
														)}
												</span>
											)}
										</TableCell>
										<TableCell>{manifest.number}</TableCell>
										<TableCell>{manifest.invoiceNumber}</TableCell>
										<TableCell>{manifest.totalPlaces}</TableCell>
										<TableCell>{manifest.totalWeight}</TableCell>
										<TableCell>{manifest.totalVolume}</TableCell>
										<TableCell>{manifest.departureCity}</TableCell>
										<TableCell>{manifest.arrivalCity}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
							{/* </Paper> */}
						</>
					) : (
						<>
							<Paper className={classes.root}>
								<Typography variant='subtitle1'>
									Информация о сторонах
								</Typography>
								<Grid container spacing={1}>
									<DescriptionLine
										caption='Отправитель'
										value={`${manifest.consignorTerminal.code} (${manifest.consignor.name})`}
									/>
									<DescriptionLine
										caption='Место отправления'
										value={manifest.consignorTerminal.address}
									/>
									<DescriptionLine
										caption='Получатель'
										value={`${manifest.consigneeTerminal.code} (${manifest.consignee.name})`}
									/>
									<DescriptionLine
										caption='Место получения'
										value={manifest.consigneeTerminal.address}
									/>
								</Grid>
								{/* <Typography variant='body1'><strong>Отправитель:</strong>{manifest.shipper}</Typography>
										<Typography variant='body1'><strong>Место отправления:</strong>{manifest.shipperAddress}</Typography>
										<Typography variant='body1'><strong>Получатель:</strong>{manifest.consignee}</Typography>
										<Typography variant='body1'><strong>Место получения:</strong>{manifest.consigneeAddress}</Typography> */}
							</Paper>

							<Paper className={classes.root}>
								<Typography variant='subtitle1'>
									Информация о перевозчике
								</Typography>
								<Grid container spacing={1}>
									<DescriptionLine
										caption='Перевозчик'
										value={manifest.carrier.name}
									/>
									<DescriptionLine
										caption='Способ транспортировки'
										value={manifest.transportationType}
									/>
									<DescriptionLine
										caption='MAWB'
										value={manifest.invoiceNumber}
									/>
									<DescriptionLine
										caption='Рейс'
										value={manifest.flightNumber}
									/>
									<DescriptionLine caption='Водитель' value={manifest.driver} />
									<DescriptionLine
										caption='Траспортное средство'
										value={manifest.vehicle}
									/>
									<DescriptionLine
										caption='Терминал-трансфер'
										value={manifest.ТерминалТрансфер}
									/>
								</Grid>
								{/* <Typography variant='body1'><strong>Перевозчик:</strong>{manifest.carrier}</Typography>
										<Typography variant='body1'><strong>Способ транспортировки:</strong>{manifest.transportationType}</Typography>
										<Typography variant='body1'><strong>MAWB:</strong>{manifest.invoiceNumber	}</Typography>
										<Typography variant='body1'><strong>Рейс:</strong>{manifest.flightNumber}</Typography>
										<Typography variant='body1'><strong>Водитель:</strong>{manifest.driver}</Typography>
										<Typography variant='body1'><strong>Траспортное средство:</strong>{manifest.vehicle}</Typography>
										<Typography variant='body1'><strong>Терминал-трансфер:</strong>{manifest.ТерминалТрансфер}</Typography> */}
							</Paper>

							<Paper className={classes.root}>
								<Typography variant='subtitle1'>Места консолидации</Typography>
								<Grid container spacing={1}>
									<DescriptionLine
										caption='Мест'
										value={manifest.totalPlaces}
									/>
									<DescriptionLine caption='Вес' value={manifest.totalWeight} />
									<DescriptionLine
										caption='Объём'
										value={manifest.totalVolume}
									/>
									<DescriptionLine
										caption='Описание упаковки'
										value={manifest.packingDescription}
									/>
									<DescriptionLine
										caption='Описание содержимого'
										value={manifest.contentDescription}
									/>
								</Grid>

								{/* <Table className={classes.table} size='small'>
									<TableHead>
										<TableRow>
											<TableCell>Мест</TableCell>
											<TableCell>Вес</TableCell>
											<TableCell>Объём</TableCell>
											<TableCell>Описание упаковки</TableCell>
											<TableCell>Описание содержимого</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{manifest.packageDescription &&
											manifest.packageDescription.map(
												(packageDescriptionLine, index) => (
													<TableRow key={index}>
														<TableCell>
															{packageDescriptionLine.places}
														</TableCell>
														<TableCell>
															{packageDescriptionLine.weight}
														</TableCell>
														<TableCell>
															{packageDescriptionLine.volume}
														</TableCell>
														<TableCell>
															{packageDescriptionLine.packingDescription}
														</TableCell>
														<TableCell>
															{packageDescriptionLine.contentDescription}
														</TableCell>
													</TableRow>
												)
											)}
									</TableBody>
								</Table> */}
							</Paper>

							<Paper className={classes.root}>
								<Typography variant='subtitle1'>Сроки перевозки</Typography>
								<Grid container spacing={1}>
									<DescriptionLine
										caption='Передано перевозчику'
										value={
											manifest.transferToCarrierDateFact ? (
												<span>
													{manifest.transferToCarrierDateFact &&
														format(
															manifest.transferToCarrierDateFact,
															'dd.MM.yyyy HH:mm'
														)}
												</span>
											) : (
												<span className={classes.muted}>
													{manifest.transferToCarrierDatePlan &&
														format(
															manifest.transferToCarrierDatePlan,
															'dd.MM.yyyy HH:mm'
														)}
												</span>
											)
										}
									/>
									<DescriptionLine
										caption='Прибытие'
										value={
											manifest.arrivalToCarrierDateFact ? (
												<span>
													{manifest.arrivalToCarrierDateFact &&
														format(
															manifest.arrivalToCarrierDateFact,
															'dd.MM.yyyy HH:mm'
														)}
												</span>
											) : (
												<span className={classes.muted}>
													{manifest.arrivalToCarrierDatePlan &&
														format(
															manifest.arrivalToCarrierDatePlan,
															'dd.MM.yyyy HH:mm'
														)}
												</span>
											)
										}
									/>
									<DescriptionLine
										caption='Забор от перевозчика'
										value={
											manifest.pickupFromCarrierDateFact ? (
												<span>
													{manifest.pickupFromCarrierDateFact &&
														format(
															manifest.pickupFromCarrierDateFact,
															'dd.MM.yyyy HH:mm'
														)}
												</span>
											) : (
												<span className={classes.muted}>
													{manifest.pickupFromCarrierDatePlan &&
														format(
															manifest.pickupFromCarrierDatePlan,
															'dd.MM.yyyy HH:mm'
														)}
												</span>
											)
										}
									/>
								</Grid>
								{/* <Typography variant='body1'><strong>Дата передачи перевозчику:</strong>{manifest.transferDateToCarrierFact}</Typography>
										<Typography variant='body1'><strong>Дата прибытия План:</strong>{manifest.arrivalDateAtCarrierWarehousePlan}</Typography>
										<Typography variant='body1'><strong>Дата забора от перевозчика План:</strong>{manifest.recievingDateFromCarrierPlan}</Typography> */}
							</Paper>
						</>
					)}
				</Paper>

				{manifest.noteForLetter ? (
					<Paper className={classes.root}>
						<Typography variant='subtitle2'>Примечание для письма:</Typography>
						<Typography variant='body1'>{manifest.noteForLetter}</Typography>
					</Paper>
				) : null}

				<Paper className={classes.root}>
					<div className={classes.hideOnXsDown}>
						<SortableTable
							caption={'Заказы'}
							headerRows={this.ordersHeaderRows}
							array={manifest.orders}
							handleRowClick={this.handleOrderClick}
						/>
					</div>
					<div className={classes.hideOnSmUp}>
						<SortableCardList
							caption={'Заказы'}
							classes={classes}
							headerRows={this.ordersHeaderRows}
							docs={manifest.orders}
							handleClick={this.handleOrderClick}
							DocCardComponent={PickupManifestOrderCard}
						/>
					</div>

					{/* <Typography color='inherit' variant='subtitle1'>
            Заказы
          </Typography>

          <Table className={classes.table} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Номер</TableCell>
                <TableCell>Номер накладной</TableCell>
                <TableCell>Место назначения</TableCell>
                <TableCell>Мест</TableCell>
                <TableCell>Вес</TableCell>
                <TableCell>Объём</TableCell>
              </TableRow>
            </TableHead>

            {manifest.orders ? (
              <TableBody>
                {manifest.orders.map((order, index) => (
                  <TableRow
                    hover
                    className={classes.pointerRow}
                    onClick={() =>
                      history.push(
                        `/deliveries/manifest/${manifest._id}/${order._id}`
                      )
                    }
                    key={index}
                  >
                    <TableCell>{order.number}</TableCell>
                    <TableCell>{order.invoiceNumber}</TableCell>
                    <TableCell>{order.receiptPointAddress}</TableCell>
                    <TableCell>{order.totalPlaces}</TableCell>
                    <TableCell>{order.totalWeight}</TableCell>
                    <TableCell>{order.totalVolume}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table> */}
				</Paper>

				<FilesManipulation
					doc={manifest}
					handleProcessFile={this.handleProcessFile}
					classes={classes}
					handleCickCheckboxIsTransportDoc={
						this.handleCickCheckboxIsTransportDoc
					}
				/>

				<Message
					open={this.state.messageIsVisible}
					close={this.handleHideMessage}
					classes={classes}
					messageText={this.state.messageText}
				/>
			</>
		)
	}
}

DeliveryManifets.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default connect(undefined, { setAlert })(
	compose(withStyles(styles), withRouter)(DeliveryManifets)
)
