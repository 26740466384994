import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

// Import React FilePond
// import { FilePond } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import FilesManipulation from '../components/FilesManipulation'
import Message from '../components/Message'

import deliveries from '../fetchers/deliveries'
import { setAlert } from '../actions/alert'
import { DialogContentText } from '@material-ui/core'

function OrderDeliveryDialog({
	order: orderFromParent,
	open,
	close,
	setAlert,
	classes,
	// match: {
	//   params: { orderNumber }
	// }
}) {
	const [orderDelivery, setOrderDelivery] = useState({ date: new Date() })
	const [order, setOrder] = useState({})
	const [messageIsVisible, setMessageVisibility] = useState(false)
	const [messageText, setMessageText] = useState('')
	const [formInPending, setFormInPending] = useState(false)
	const [dialogTitle, setDialogTitle] = useState('')
	const [damageReportTitle, setDamageReportTitle] = useState('')
	const [deliveredButtonTitle, setDeliveredButtonTitle] = useState('')

	// const [pond, setPond] = useState(undefined)
	// const [files, setFiles] = useState([])

	useEffect(() => {
		async function fetchData() {
			if (!open) return

			// const { orderNumber } = this.props.match.params
			// let orders = await deliveries.getLocal("orders")
			// const _order = orders.find(order => order.number === orderNumber)

			setOrder(orderFromParent)

			setOrderDelivery({
				date: new Date(),
				recievedBy: '',
				places: orderFromParent.totalPlaces,
				damageReport: false,
				paymentAccepted: false,
				partialDelivery: false,
				comment: '',
				checkpoint: 'ОК',
				// weight: order.totalWeight
			})

			setDialogTitle(
				(orderFromParent.checkpointCurrent === 'СВ'
					? 'Выдача самовывоза'
					: 'Доставка заказа') +
					' ' +
					orderFromParent.number
			)
			setDamageReportTitle(
				orderFromParent.checkpointCurrent === 'СВ'
					? 'Груз выдан с актом о повреждении'
					: 'Груз доставлен с актом о повреждении'
			)

			setDeliveredButtonTitle(
				orderFromParent.checkpointCurrent === 'СВ' ? 'Выдан' : 'Доставлен'
			)
		}
		fetchData()
	}, [open, orderFromParent])

	const onDialogFieldChange = event => {
		const newObj = deliveries.onDialogFieldChange(event, orderDelivery)
		const targetName = event.target.name
		if (targetName === 'partialDelivery') {
			newObj.checkpoint = newObj.partialDelivery ? 'ЧД' : 'ОК'
		}

		setOrderDelivery({ ...orderDelivery, ...newObj })
		// console.log(files);
	}

	const handleProcessFile = (error, file) => {
		console.log(file)
		const fileFromServer = JSON.parse(file.serverId)

		const orderFiles = [...order.files, fileFromServer]
		setOrder({ ...order, files: orderFiles })
	}

	const handleCickCheckboxIsTransportDoc = async (file, checked) => {
		const orderFiles = [...order.files].map(orderFile => {
			if (orderFile._id === file._id)
				return { ...file, isTransportDoc: checked }
			else return orderFile
		})

		const _order = { ...order, files: orderFiles }
		setOrder(_order)
		const success = await deliveries.patchFile(order, {
			...file,
			isTransportDoc: checked,
		})

		if (success) setAlert({ messageText: 'обновлено на сервере' })
	}

	const handleHideMessage = () => {
		setMessageVisibility(false)
		setMessageText('')
	}

	const handleDeliveredButtonClick = async ({ order, orderDelivery }) => {
		setFormInPending(true)
		await close({ order, orderDelivery })
		setFormInPending(false)
	}

	const sumbmitIsDisabled = () => {
		return formInPending
	}

	return (
		<Dialog
			open={open}
			onClose={() => close({ order })}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title'>{dialogTitle}</DialogTitle>
			<DialogContent>
				{/* <DialogContentText>
				To subscribe to this website, please enter your email address here. We will send
				updates occasionally.
			</DialogContentText> */}
				<TextField
					autoFocus
					// margin='dense'
					id='name'
					label='Дата'
					type='date'
					name='date'
					value={format(orderDelivery.date, 'yyyy-MM-dd')}
					onChange={event => onDialogFieldChange(event)}
				/>
				<TextField
					autoFocus
					// margin='dense'
					id='name'
					label='Время'
					type='time'
					name='date'
					value={format(orderDelivery.date, 'HH:mm')}
					onChange={event => onDialogFieldChange(event)}
				/>
				<TextField
					autoComplete='off'
					autoFocus
					// margin='dense'
					id='name'
					label='Получил ФИО'
					type='text'
					name='recievedBy'
					fullWidth
					value={orderDelivery.recievedBy}
					onChange={event => onDialogFieldChange(event)}
				/>
				<TextField
					// margin='dense'
					label='Количество мест (куда записывать в 1С?)'
					type='number'
					name='places'
					fullWidth
					value={orderDelivery.places}
					onChange={event => onDialogFieldChange(event)}
				/>
				<FormControlLabel
					control={
						<Checkbox
							// checked={this.state.checkedB}
							// onChange={this.handleChange('checkedB')}
							// value="checkedB"
							color='primary'
							name='damageReport'
							checked={orderDelivery.damageReport}
							onChange={event => onDialogFieldChange(event)}
						/>
					}
					label={damageReportTitle}
				/>
				<FormControlLabel
					control={
						<Checkbox
							// checked={this.state.checkedB}
							// onChange={this.handleChange('checkedB')}
							// value="checkedB"
							color='primary'
							name='paymentAccepted'
							checked={orderDelivery.paymentAccepted}
							onChange={event => onDialogFieldChange(event)}
						/>
					}
					label='Оплата принята (в 1С не уйдёт)'
				/>
				{order.checkpointCurrent !== 'СВ' && (
					<FormControlLabel
						control={
							<Checkbox
								color='primary'
								name='partialDelivery'
								checked={orderDelivery.partialDelivery}
								onChange={event => onDialogFieldChange(event)}
							/>
						}
						label='Частичная доставка'
					/>
				)}
				<TextField
					autoFocus
					// margin='dense'
					id='name'
					label='Комментарии'
					type='text'
					name='comment'
					fullWidth
					value={orderDelivery.comment}
					onChange={event => onDialogFieldChange(event)}
				/>
				{order.returnDocumentsRequired && (
					<DialogContentText color='error'>
						Не забудьте забрать заполненные документы для отправителя
					</DialogContentText>
				)}
				{/* <FilePond
					labelIdle='бросайте сюда файлы или нажмите, чтобы загрузить'
					ref={ref => setPond(ref)}
					files={files}
					allowMultiple={true}
					maxFiles={3}
					server='/api/upload'
					// oninit={() => this.handleInit()}
					onupdatefiles={fileItems => {
						// Set currently active file objects to this.state
						setFiles(fileItems.map(fileItem => fileItem.file))
					}}
					onprocessfile={(error, file) => {
						console.log(file)
					}}
					onprocessfiles={() => {
						console.log('FILES UPLOADED', files)
					}}
				/> */}
				<FilesManipulation
					classes={classes}
					doc={order}
					handleProcessFile={handleProcessFile}
					handleCickCheckboxIsTransportDoc={handleCickCheckboxIsTransportDoc}
				/>
				<Message
					open={messageIsVisible}
					close={handleHideMessage}
					classes={classes}
					messageText={messageText}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => close({ order })} color='primary'>
					Отмена
				</Button>
				<Button
					onClick={() => handleDeliveredButtonClick({ order, orderDelivery })}
					variant='contained'
					color='primary'
					disabled={sumbmitIsDisabled()}
				>
					{deliveredButtonTitle} ({orderDelivery.checkpoint})
				</Button>
			</DialogActions>
		</Dialog>
	)
}

OrderDeliveryDialog.propTypes = {}

export default connect(null, { setAlert })(OrderDeliveryDialog)
