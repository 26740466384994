import users from '../fetchers/users'
import { Storage } from '../services/storage'

import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGOUT_SUCCESS,
	SET_ALERT,
} from './types'

export const register = ({ name, password, history }) => async (dispatch) => {
	const currentUser = await users.authenticate({ name, password })
	if (currentUser.error) {
		await Storage.setData({ currentUser: null })
		dispatch({ type: REGISTER_FAIL })
		dispatch({
			type: SET_ALERT,
			payload: { messageText: 'неправильные имя пользователя или пароль' },
		})
	} else {
		await Storage.setData({ currentUser })
		dispatch({ type: REGISTER_SUCCESS, payload: { currentUser } })
		history.push('/profile')
	}
}

export const setCurrentUser = () => async (dispatch) => {
	const currentUser = await Storage.getData('currentUser')
	if (!currentUser || !currentUser.representation) {
		dispatch({ type: REGISTER_FAIL })
	} else dispatch({ type: REGISTER_SUCCESS, payload: { currentUser } })
}

export const logout = () => async (dispatch) => {
	dispatch({ type: LOGOUT_SUCCESS })
}
