import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import compose from "recompose/compose"
import format from "date-fns/format"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { withStyles } from "@material-ui/core/styles"

import billing from "../fetchers/billing"
import documents from "../fetchers/documents"

const styles = {
  root: {
    width: "100%",
    overflowX: "auto"
  },
  // table: {
  // 	minWidth: 700,
  // },
  pointerRow: {
    cursor: "pointer"
  },
  tableTitle: {
    paddingLeft: 20,
    paddingTop: 20
  },
  compactTableCell: {
    padding: "4px 20px 4px 20px"
  }
}

function CreateBillDialog({ open, close, completed, classes }) {
  const fullScreenDialog = true

  // const [docs, changeTasks] = useState([])
  // const [comments, setComments] = useState('')
  const [bill, setBill] = useState({
    number: "",
    date: new Date(),
    docs: [],
    comments: ""
  })
  async function fetchData() {
    const number = await billing.getNewBillNumber()
    const newBill = { ...bill, number, docs: completed }
    setBill(newBill)
  }

  useEffect(() => {
    if (!open) return

    fetchData()
  }, [open])

  const onDialogFieldChange = event => {
    const newObj = documents.onDialogFieldChange(event, bill)
    setBill({ ...bill, ...newObj })
  }

  const onSumChange = (event, index) => {
    const newBill = { ...bill }
    newBill.docs[index].sum = parseFloat(event.target.value)
    setBill(newBill)
  }

  const handleCloseBillWindow = bill => {
    close(bill)
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleCloseBillWindow()}
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreenDialog}
    >
      <DialogTitle id="form-dialog-title">{`Выставление счёта`}</DialogTitle>{" "}
      <DialogContent>
        {/* <DialogContentText>
				{`Выбранные документы: ${selected.join(', ')}`}
			</DialogContentText> */}

        <TextField
          autoComplete="off"
          autoFocus
          // margin='dense'
          label="Номер счета"
          type="text"
          name="number"
          InputLabelProps={{
            shrink: true
          }}
          value={bill.number}
          onChange={event => onDialogFieldChange(event)}
        />

        <TextField
          autoFocus
          // margin='dense'
          label="Дата выставления"
          type="date"
          name="date"
          InputLabelProps={{
            shrink: true
          }}
          value={format(bill.date, "yyyy-MM-dd")}
          onChange={event => onDialogFieldChange(event)}
        />
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.compactTableCell}>
                Тип документа
              </TableCell>
              <TableCell className={classes.compactTableCell}>Номер</TableCell>
              <TableCell className={classes.compactTableCell}>
                Накладная
              </TableCell>
              <TableCell className={classes.compactTableCell}>
                Получатель
              </TableCell>
              {/* <TableCell>Адрес</TableCell> */}
              <TableCell className={classes.compactTableCell}>Сумма</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {bill.docs
              .map((task, index) => {
                if (!task.sum) task.sum = 0
                return task
              })
              .map((task, index) => {
                return (
                  <TableRow
                    hover
                    className={classes.pointerRow}
                    // onClick={() => history.push(`/deliveries/${manifest.number}`)}
                    key={index}
                  >
                    <TableCell className={classes.compactTableCell}>
                      {task.type === "order" ? "Заказ" : null}
                      {task.type === "manifest" ? "Манифест" : null}
                    </TableCell>
                    <TableCell className={classes.compactTableCell}>
                      {task.number}
                    </TableCell>
                    <TableCell className={classes.compactTableCell}>
                      {task.invoiceNumber}
                    </TableCell>
                    <TableCell className={classes.compactTableCell}>
                      {task.consignee.name}
                    </TableCell>
                    {/* <TableCell>{task.consigneeAddress}</TableCell> */}
                    <TableCell className={classes.compactTableCell}>
                      <TextField
                        // margin='dense'
                        type="number"
                        value={task.sum}
                        onChange={event => onSumChange(event, index)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>

        <TextField
          // margin='dense'
          label="Комментарий"
          type="text"
          name="comments"
          fullWidth
          value={bill.comments}
          onChange={event => onDialogFieldChange(event)}
        />

        {/* <FilePond
				labelIdle='бросайте сюда файлы или нажмите, чтобы загрузить'
				ref={ref => (pond = ref)}
				files={billFiles}
				allowMultiple={true}
				maxFiles={3}
				server='/api'
				oninit={() => handleInit()}
				onupdatefiles={fileItems => {
					// Set currently active file objects to this.state
					this.setState({
						files: fileItems.map(fileItem => fileItem.file)
					})
				}}
			/> */}

        {/* <FormControlLabel
				control={
					<Checkbox
						// checked={this.state.checkedB}
						// onChange={this.handleChange('checkedB')}
						// value="checkedB"
						color="primary"
					/>
				}
				label="Груз получен с актом о повреждении"
			/>						 */}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseBillWindow()} color="primary">
          Отмена
        </Button>
        <Button
          onClick={() => handleCloseBillWindow(bill)}
          variant="contained"
          color="primary"
        >
          Выставить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateBillDialog.propTypes = {
  open: PropTypes.bool.isRequired
}

export default compose(withStyles(styles))(CreateBillDialog)
