import React from 'react'
import {
	Card,
	CardContent,
	CardActionArea,
	Box,
	Typography,
	makeStyles,
} from '@material-ui/core'
import ManifestNumber from './ManifestNumber'

const useStyles = makeStyles(theme => ({
	gridFullWidth: {
		width: '100%',
	},
	card: {
		flex: 1,
	},
	hideOnMdDown: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	hideOnLgUp: {
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
}))

export default function DeliveryManifestCard({
	classes: parentClasses,
	doc: manifest,
	handleClick,
}) {
	const classes = { ...parentClasses, ...useStyles() }
	return (
		<Card className={classes.card}>
			<CardActionArea onClick={event => handleClick(event, manifest)}>
				<CardContent>
					<div className={classes.hideOnLgUp}>
						<Typography variant='body1'>
							<ManifestNumber number={manifest.number} />
							{`${
								manifest.invoiceNumber
									? ' (' + manifest.invoiceNumber + ')'
									: ''
							}`}{' '}
							<small>прибытие {manifest.arrivalToCarrierDate}</small>
						</Typography>

						<Box display='flex' justifyContent='space-between'>
							<span>{`мест: ${manifest.totalPlaces}, вес: ${manifest.totalWeight}, объём: ${manifest.totalVolume} `}</span>
							<span>{manifest.specialConditions}</span>
							<span>{`${manifest.consignorTerminalCode} -> ${manifest.consigneeTerminalCode}`}</span>
							<span>{`статус: ${manifest.checkpointCurrent}`}</span>
						</Box>
					</div>

					<div className={classes.hideOnMdDown}>
						<Box display='flex' justifyContent='space-between'>
							<Typography variant='body1'>
								<ManifestNumber number={manifest.number} />
								{`${
									manifest.invoiceNumber
										? ' (' + manifest.invoiceNumber + ')'
										: ''
								}`}{' '}
								<small>прибытие {manifest.arrivalToCarrierDate}</small>
							</Typography>

							<span>{`мест: ${manifest.totalPlaces}, вес: ${manifest.totalWeight}, объём: ${manifest.totalVolume} `}</span>
							<span>{manifest.specialConditions}</span>
							<span>{`${manifest.consignorTerminalCode} -> ${manifest.consigneeTerminalCode}`}</span>
							<span>{`статус: ${manifest.checkpointCurrent}`}</span>
						</Box>
					</div>
				</CardContent>
			</CardActionArea>
		</Card>
	)
}
