import React from 'react'
import {
	Card,
	CardContent,
	CardActionArea,
	Box,
	Typography,
	makeStyles,
	Grid,
} from '@material-ui/core'
import OrderNumber from './OrderNumber'

const useStyles = makeStyles(theme => ({
	gridFullWidth: {
		width: '100%',
	},
	card: {
		flex: 1,
	},

	hideOnMdDown: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	hideOnLgUp: {
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
}))

export default function DeliveryOrderCard({
	classes: parentClasses,
	doc: order,
	handleClick,
	style,
}) {
	const classes = { ...parentClasses, ...useStyles() }

	return (
		<Card className={classes.card}>
			<CardActionArea onClick={event => handleClick(event, order)}>
				<CardContent>
					<div className={classes.hideOnLgUp}>
						<Typography variant='body1'>
							<OrderNumber number={order.number} />
							{` ${order.invoiceNumber ? '(' + order.invoiceNumber + ')' : ''}`}
							<small> доставка {order.deliveryDateOneLine}</small>
						</Typography>
						<Typography
							variant='body1'
							component='span'
						>{`${order.consigneeName} `}</Typography>
						<Typography
							className={classes.title}
							color='textSecondary'
							variant='body2'
							component='span'
							gutterBottom
						>
							{order.consigneeAddress}
						</Typography>

						<Box display='flex' justifyContent='space-between'>
							<span>{`мест: ${order.totalPlaces}, вес: ${order.totalWeight}, объём: ${order.totalVolume} `}</span>
							<span>{order.specialConditions}</span>
							<span>{`статус: ${order.checkpointCurrent}`}</span>
						</Box>
					</div>

					<div className={classes.hideOnMdDown}>
						<Box display='flex' justifyContent='space-between'>
							<Typography variant='body1'>
								<OrderNumber number={order.number} />
								{` ${
									order.invoiceNumber ? '(' + order.invoiceNumber + ')' : ''
								}`}
								<small> доставка {order.deliveryDateOneLine}</small>
							</Typography>{' '}
							<span>{`мест: ${order.totalPlaces}, вес: ${order.totalWeight}, объём: ${order.totalVolume} `}</span>
							<span>{order.specialConditions}</span>
							<span>{`статус: ${order.checkpointCurrent}`}</span>
						</Box>
						<Typography
							variant='body1'
							component='span'
						>{`${order.consigneeName} `}</Typography>
						<Typography
							className={classes.title}
							color='textSecondary'
							variant='body2'
							component='span'
							gutterBottom
						>
							{order.consigneeAddress}
						</Typography>
					</div>
				</CardContent>
			</CardActionArea>
		</Card>
	)
}
