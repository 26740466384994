import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// import CompactTable from './CompactTable'
import SortableTable from '../components/SortableTable'

import PropTypes from 'prop-types'

function BillDialog({ open, close, bill }) {
	const [pond, setPond] = useState(undefined)

	if (!bill) return null

	const columns = [
		[
			{ label: 'тип', id: 'type' },
			{ label: 'номер', id: 'number' },
			{ label: 'номер накладной', id: 'invoiceNumber' },
			{ label: 'сумма', id: 'sum' },
		],
	]

	const arrayRepresentation = bill.docs.map(doc => {
		if (doc.type === 'order') doc.type = 'Заказ'
		else if (doc.type === 'manifest') doc.type = 'Манифест'
		return doc
	})

	return (
		<Dialog
			open={open}
			// onClose={this.handleClickCloseReceptionWindow}
			aria-labelledby='form-dialog-title'
			onBackdropClick={() => close()}
			onEscapeKeyDown={() => close()}
		>
			<DialogTitle id='form-dialog-title'>{`Выставленный счёт ${
				bill.number
			} от ${format(bill.date, 'dd.MM.yyyy HH:mm')}`}</DialogTitle>

			{/* <CompactTable
				columns={columns}
				table={bill.docs.map(doc => {
					if (doc.type === 'order') doc.type = 'Заказ'
					else if (doc.type === 'manifest') doc.type = 'Манифест'
					return doc
				})}
			/> */}

			<SortableTable
				caption='Документы'
				headerRows={columns}
				array={arrayRepresentation}
				// handleCheckboxClick={handleCheckboxClick}
				// classes={classes}
				// handleRowClick={handleRowClick}
			/>

			<DialogContentText>{bill.comment}</DialogContentText>

			<DialogContent>
				<FilePond
					labelIdle='бросайте сюда файлы или нажмите, чтобы загрузить'
					ref={ref => setPond(ref)}
					files={bill.files}
					allowMultiple={true}
					maxFiles={3}
					server='/api'
					// oninit={() => this.handleInit()}
					onupdatefiles={fileItems => {
						// Set currently active file objects to this.state
						this.setState({
							files: fileItems.map(fileItem => fileItem.file),
						})
					}}
				/>

				{/* <FormControlLabel
					control={
						<Checkbox
							// checked={this.state.checkedB}
							// onChange={this.handleChange('checkedB')}
							// value="checkedB"
							color="primary"
						/>
					}
					label="Груз получен с актом о повреждении"
				/>						 */}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => close()} color='primary'>
					Закрыть
				</Button>
				{/* <Button
					onClick={this.handleCloseManifestWindow}
					variant='contained'
					color='primary'
				>
					Отрузить
				</Button> */}
			</DialogActions>
		</Dialog>
	)
}

BillDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	bill: PropTypes.object,
}

export default BillDialog
