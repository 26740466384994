import React, { Component, useState, useEffect } from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames/bind'
import { get, set } from 'lodash'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import Fab from '@material-ui/core/Fab'
import SaveIcon from '@material-ui/icons/Save'

import profiles from '../fetchers/profiles'
import documents from '../fetchers/documents'

const styles = theme => ({
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	transparent: {
		opacity: 0.7,
	},
})

function Profile({ classes, history }) {
	const [profileData, setProfileData] = useState({})
	const [saveButtonIsVisible, setSaveButtonVisibility] = useState(false)

	const profileSections = [
		[
			{ key: 'name', caption: 'Краткое наименование' },
			{ key: 'fullName', caption: 'Полное наименование' },
			{ key: 'INN', caption: 'ИНН' },
			{ key: 'legalAddress', caption: 'Юридический адрес' },
			{ key: 'phoneNumber', caption: 'Телефон' },
		],
		[
			{ key: 'bankAccountNumber', caption: 'Расчетный счет' },
			{ key: 'bankName', caption: 'Банк' },
			{ key: 'correspondentAccountNumber', caption: 'Корреспондентский счет' },
			{ key: 'bankBic', caption: 'БИК' },
		],
		[
			{ key: 'terminal.address', caption: 'Адрес терминала' },
			{ key: 'terminal.contact', caption: 'Контакт' },
			{ key: 'terminal.email', caption: 'Email' },
			{ key: 'terminal.mark', caption: 'Маркировка' },
		],
	]

	useEffect(() => {
		async function fetchData() {
			let profile = await profiles.getLocal()
			setProfileData(profile)
			// debugger
			profile = await profiles.getFromServer()
			if (!profile) return history.push('/login')
			if (profile.redirectTo) return history.push(profile.redirectTo)
			setProfileData(profile)

			profiles.updateLocal(profile)
		}
		fetchData()
	}, [])

	const onDialogFieldChange = event => {
		const newObj = documents.onDialogFieldChange(event, profileData)
		// setProfileData({ ...profileData, ...newObj })
		const _profileData = { ...profileData }
		for (const key in newObj) {
			set(_profileData, key, newObj[key])
		}
		setProfileData(_profileData)

		setSaveButtonVisibility(true)
	}

	const handleSaveProfile = async () => {
		await profiles.updateOnServer(profileData)
		history.push('/profile')
	}

	return (
		<>
			<h1>Профиль</h1>

			{profileSections.map((profileSection, sectionIndex) => {
				return (
					<Paper
						style={{ padding: 20, marginTop: 20, mairginBottom: 20 }}
						key={sectionIndex}>
						{profileSection.map((profileLine, lineIndex) => {
							const profileLineData = get(profileData, profileLine.key)

							return (
								<Grid
									container
									spacing={2}
									key={'' + sectionIndex + '-' + lineIndex}>
									{/* <DescriptionLine
										caption={profileLine.caption}
										value={profileData && profileData[profileLine.key]}
									/> */}
									<TextField
										fullWidth
										// InputLabelProps={{ shrink: !!profileLineData }}
										label={profileLine.caption}
										className={classes.textField}
										name={profileLine.key}
										value={profileLineData ? profileLineData : ''}
										onChange={onDialogFieldChange}
										margin='dense'
									/>
								</Grid>
							)
						})}
					</Paper>
				)
			})}

			{/* {saveButtonIsVisible ? (
				<Fab
					variant='extended'
					aria-label='delete'
					color='primary'
					className={classNames(classes.fab, classes.transparent)}
					onClick={handleSaveProfile}>
					<SaveIcon className={classes.extendedIcon} />
					Сохранить
				</Fab>
			) : null} */}
		</>
	)
}

export default compose(
	withStyles(styles),
	withRouter
)(Profile)
