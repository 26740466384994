import React from 'react'

function WaitingForNumber({ doc }) {
	return doc.checkpointCurrent ? (
		<small>ожидаем номер</small>
	) : (
		<small>пока без номера</small>
	)
}

export default WaitingForNumber
