import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import format from 'date-fns/format'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import deliveries from '../fetchers/deliveries'

function OrderNotDeliveryDialog({ open, close, order, classes }) {
	const [orderNotDelivery, setOrderNotDelivery] = useState({
		date: new Date(),
	})

	useEffect(() => {
		if (!open) return

		setOrderNotDelivery({
			date: new Date(),
			checkpoint: checkpoints[0],
			reason: reasons[checkpoints[0]],
			comment: '',
		})
	}, [open])

	const checkpoints = ['НД', 'ПГ', 'ДП', 'ПД']
	const reasons = {
		НД: 'Не доставлен',
		ПГ: 'Проблемный груз',
		ДП: 'Доставка c повреждениями',
		ПД: 'Доставка по предварительной догворенности',
	}

	const onDialogFieldChange = event => {
		let newObj = deliveries.onDialogFieldChange(event, orderNotDelivery)
		if (event.target.name === 'checkpoint')
			newObj = { ...newObj, reason: reasons[event.target.value] }
		setOrderNotDelivery({ ...orderNotDelivery, ...newObj })
	}

	return (
		<Dialog
			open={open}
			onClose={() => close()}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title'>{`Не доставлен заказ ${
				order.number
			}`}</DialogTitle>
			<DialogContent>
				{/* <DialogContentText>
				To subscribe to this website, please enter your email address here. We will send
				updates occasionally.
			</DialogContentText> */}

				<TextField
					autoFocus
					// margin='dense'
					id='name'
					label='Дата'
					type='date'
					name='date'
					value={format(orderNotDelivery.date, 'yyyy-MM-dd')}
					onChange={event => onDialogFieldChange(event)}
				/>

				<TextField
					autoFocus
					// margin='dense'
					id='name'
					label='Время'
					type='time'
					name='date'
					value={format(orderNotDelivery.date, 'HH:mm')}
					onChange={event => onDialogFieldChange(event)}
				/>

				<FormControl component='fieldset' className={classes.formControl}>
					<FormLabel component='legend'>Причина</FormLabel>
					<RadioGroup
						aria-label='checkpoint'
						name='checkpoint'
						className={classes.group}
						value={orderNotDelivery.checkpoint}
						onChange={event => onDialogFieldChange(event)}
					>
						{checkpoints.map(checkpoint => (
							<FormControlLabel
								key={checkpoint}
								value={checkpoint}
								control={<Radio />}
								label={reasons[checkpoint]}
							/>
						))}
					</RadioGroup>
				</FormControl>

				<TextField
					autoFocus
					// margin='dense'
					id='name'
					label='Комментарии'
					type='text'
					name='comment'
					fullWidth
					value={orderNotDelivery.comment}
					onChange={event => onDialogFieldChange(event)}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={() => close()} color='primary'>
					Отмена
				</Button>
				<Button
					onClick={() => close(orderNotDelivery)}
					variant='contained'
					color='secondary'
				>
					Не доставлен ({orderNotDelivery.checkpoint})
				</Button>
			</DialogActions>
		</Dialog>
	)
}

OrderNotDeliveryDialog.propTypes = {}

export default OrderNotDeliveryDialog
