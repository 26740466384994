import { Storage } from '../services/storage'

import { SET_PROFILE, CLEAR_PROFILE } from './types'
import profiles from '../fetchers/profiles'

export const getProfile = () => async (dispatch) => {
	const profile = await profiles.getFromServer()
	if (profile.redirectTo) {
		await Storage.setData({ profile: null })
		dispatch({
			type: CLEAR_PROFILE,
			payload: profile,
		})
	} else {
		await Storage.setData({ profile })
		dispatch({
			type: SET_PROFILE,
			payload: profile,
		})
	}
}

export const clearProfile = () => async (dispatch) => {
	dispatch({
		type: CLEAR_PROFILE,
		payload: { profile: null },
	})
}
